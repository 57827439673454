import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";

function StakingWarningModal(props) {
  const { message, shown, setShown, setConfirmed } = props;

  const confirmStake = () => {
    setShown(false);
    setConfirmed(true);
  };

  return (
    <Modal show={shown} onHide={() => setShown(false)} centered>
      <Modal.Header closeButton className="border-0 pb-0 pt-2">
        {/* <Modal.Title>DBD Stake to Earn</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="pb-3">
          <FontAwesomeIcon icon={faInfoCircle} size="2x" color="#3A3DBB" />
        </div>
        <div className="secondary-color mb-2">
          <h6>Warning</h6>
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="w-auto px-4 rounded-pill bg-secondary-color border-0 text-dark mr-3"
          onClick={() => setShown(false)}
        >
          Cancel
        </Button>
        <Button
          className="w-auto px-4 rounded-pill bg-primary-color border-0 text-white"
          onClick={() => confirmStake()}
        >
          I Understand
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StakingWarningModal;
