import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import UnstakeModal from "./UnstakeModal";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// contexts
import { AuthContext } from "context/authContext";
import { EthProviderContext } from "context/ethProviderContext";
// utils and constants
import { sendTransaction } from "services/contract.service";
import { calRewards } from "utils/math";
import { toast } from "react-toastify";
import { durationFormatter, durationToDaysHours } from "utils/format";
import moment from "moment";
// hooks & reducers
import { useWeb3 } from "hooks/useWeb3";
import { useContracts } from "hooks/useContracts";
import { initUnstakeState, unstakeReducer } from "reducers/unstakeReducer";
import { StakingDataContext } from "context/stakingDataContext";
import { useAwaitTxn } from "hooks/useAwaitTxn";
import FireConfetti from "components/FireConfetti";

function ActiveRecordsCard(props) {
  const { stakingRecords, toggleUpdated } = props;

  const [activeRecords, setActiveRecords] = useState([]);
  const [inactiveIds, setInactiveIds] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedTotal, setSelectedTotal] = useState(0);
  const [selectedReward, setSelectedReward] = useState(0);
  const [isSelectedEarlyRecord, setIsSelectedEarlyRecord] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [awaiting, setAwaiting] = useState(true);
  const [unstakeState, dispatch] = useReducer(unstakeReducer, initUnstakeState);

  const [unstakeUnavMsg, setUnstakeUnavMsg] = useState("");
  const [disUnstakeUnavMsg, setDisUnstakeUnavMsg] = useState(false);

  // contexts
  const { authState } = useContext(AuthContext);
  const { ethProviderState } = useContext(EthProviderContext);
  const { stakingDataState } = useContext(StakingDataContext);

  const web3 = useWeb3();
  const [, stakingContract] = useContracts();

  const [noDataText, setNoDataText] = useState();
  const [updateCount, setUpdateCount] = useState(0);

  const [confettiShown, setConfettiShown] = useState(false);
  const confettiRef = useRef();

  useAwaitTxn(
    ethProviderState.provider,
    unstakeState.unstakeTxn,
    toggleUpdated,
    awaiting,
    setAwaiting,
    dispatch
  );

  useEffect(() => {
    if (web3) {
      const tempActiveRecords = [];
      const tempInactiveRecords = [];
      stakingRecords.forEach((record) => {
        // Setup records details (may refine in the future)
        const timeDiff = moment().diff(
          moment.unix(record.contractStart),
          "seconds"
        );
        const timeDiffText = `${durationToDaysHours(timeDiff)}`;
        const formattedStartDate = moment
          .unix(record.contractStart)
          .format("DD-MMM-YYYY HH:mm");
        const formattedEndDate =
          record.contractDuration === "0"
            ? "Anytime"
            : moment
                .unix(record.contractStart)
                .add(record.contractDuration, "seconds")
                .format("DD-MMM-YYYY HH:mm");
        let contractDuration = "N/A";
        if (record.contractDuration === "0") {
          contractDuration = "Flexible";
        } else {
          contractDuration = `${durationFormatter(
            Number(record.contractDuration)
          ).toLocaleString()} Days`;
        }
        const canUnstake =
          record.contractDuration === "0" ||
          timeDiff >= record.minStakingPeriod;
        const isEarlyUnstake =
          record.contractDuration !== "0" && timeDiff < record.contractDuration;

        tempActiveRecords.push({
          contractId: record.contractId,
          amount: web3.utils
            .fromWei(record.stakeAmount.toString(), "Ether")
            .toLocaleString(undefined, { maximumFractionDigits: 4 }),
          contractDuration: contractDuration,
          timeStaked: timeDiffText,
          formattedStartDate: formattedStartDate,
          formattedEndDate: formattedEndDate,
          isEarlyUnstake: isEarlyUnstake,
          perApy: record.perAPY,
          startTimestamp: record.contractStart,
          productDuration: record.contractDuration,
        });
        if (!canUnstake || record.isWithdrawn)
          tempInactiveRecords.push(record.contractId);
      });
      setActiveRecords(tempActiveRecords);
      setInactiveIds(tempInactiveRecords);
    }
  }, [stakingRecords, web3]);

  // show the confetti
  useEffect(() => {
    if (unstakeState.type === "finish" && !confettiShown) {
      confettiRef.current.fire();
      setConfettiShown(true);
    }
  }, [unstakeState, confettiShown]);

  const onRowSelect = (row, isSelected) => {
    setDisUnstakeUnavMsg(false);
    let tempSelectedRecords = selectedRecords;
    isSelected
      ? tempSelectedRecords.push(row)
      : (tempSelectedRecords = tempSelectedRecords.filter(
          (record) => record.contractId !== row.contractId
        ));

    // calculate the rewards
    const tempReward = calRewards(
      row.amount,
      row.perApy,
      row.startTimestamp,
      row.productDuration,
      stakingDataState.earlyUnstakeApy,
      stakingDataState.extraPeriodApy
    );

    let tempSelectedReward = selectedReward;
    let tempSelectedTotal = selectedTotal;
    if (isSelected) {
      tempSelectedReward += tempReward;
      tempSelectedTotal += tempReward + Number(row.amount);
    } else {
      tempSelectedReward =
        tempSelectedReward - tempReward < 0
          ? 0
          : tempSelectedReward - tempReward;
      tempSelectedTotal =
        tempSelectedTotal - (tempReward + Number(row.amount)) < 0
          ? 0
          : tempSelectedTotal - (tempReward + Number(row.amount));
    }

    const rewardPoolNumber = Number(
      web3.utils.fromWei(stakingDataState.rewardPoolBalance.toString())
    );
    tempSelectedRecords.length === 0 || rewardPoolNumber < tempSelectedReward
      ? setIsDisabled(true)
      : setIsDisabled(false);

    // Checks if minstaking period is lower than the staked period for all records
    tempSelectedRecords.forEach((tmpRecord) => {
      const durationRecord = stakingDataState.durationTerms.find(
        (element) =>
          element.period === tmpRecord.contractDuration ||
          (element.period === "0" && tmpRecord.contractDuration === "Flexible")
      );
      if (durationRecord) {
        if (
          durationRecord.minStakingPeriod > tmpRecord.timeStaked &&
          tmpRecord.contractDuration !== "Flexible"
        ) {
          setIsDisabled(false);
          setDisUnstakeUnavMsg(true);
          setUnstakeUnavMsg(
            "as the minimium staking period is not over for a record."
          );
        }
      }
    });

    // check whether user selected the early term for unstaking
    setIsSelectedEarlyRecord(
      tempSelectedRecords.some((record) => record.isEarlyUnstake)
    );
    setSelectedRecords(tempSelectedRecords);
    setSelectedReward(tempSelectedReward);
    setSelectedTotal(parseFloat(tempSelectedTotal));
  };

  const onSelectAll = (isSelected, rows) => {
    if (isSelected) {
      let tempSelectedReward = 0;
      let tempSelectedTotal = 0;
      setIsSelectedEarlyRecord(rows.some((record) => record.isEarlyUnstake));
      rows.forEach((record) => {
        // calculate the rewards
        const tempReward = calRewards(
          record.amount,
          record.perApy,
          record.startTimestamp,
          record.productDuration,
          stakingDataState.earlyUnstakeApy,
          stakingDataState.extraPeriodApy
        );
        tempSelectedReward += tempReward;
        tempSelectedTotal += tempReward + Number(record.amount);
      });
      const rewardPoolNumber = Number(
        web3.utils.fromWei(stakingDataState.rewardPoolBalance.toString())
      );
      setIsDisabled(rewardPoolNumber < tempSelectedReward);
      setSelectedRecords(rows);
      setSelectedReward(tempSelectedReward);
      setSelectedTotal(tempSelectedTotal);
    } else {
      setIsSelectedEarlyRecord(false);
      setIsDisabled(true);
      setDisUnstakeUnavMsg(false);
      setSelectedRecords([]);
      setSelectedReward(0);
      setSelectedTotal(0);
    }
  };

  const unstakeSelectedTerms = async () => {
    if (!authState.isConnectedPoly) {
      toast.error("Please connect to your wallet first");
      return;
    }

    setAwaiting(true);
    setDisUnstakeUnavMsg(false);
    const selectedIds = selectedRecords.map((record) => record.contractId);
    await unstake(selectedIds);
    const tempInactiveIds = inactiveIds.concat(selectedIds);
    setInactiveIds(tempInactiveIds);
    setSelectedRecords([]);
  };

  const unstake = async (selectedTerms) => {
    try {
      dispatch({ type: "UNSTAKING", payload: true });
      setConfettiShown(false);
      const transaction = stakingContract.methods
        .unstake(selectedTerms)
        .encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const unstakeTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      dispatch({ type: "CONFIRM", payload: unstakeTxn });
      setIsSelectedEarlyRecord(false);
      setDisUnstakeUnavMsg(false);
      setSelectedReward(0);
    } catch (err) {
      console.error(err);
      toast.error(err.message + " Reloading your page.");
      dispatch({ type: "ERROR" });
      await new Promise((resolve) => setTimeout(resolve, 5000));
      window.location.reload();
    }
  };

  useEffect(() => {
    setUpdateCount((prevUpdateCount) => prevUpdateCount + 1);
    setNoDataText("Table data is still being loaded. Please wait...");
    if (activeRecords.length === 0 && updateCount >= 2) {
      setNoDataText("There is no data to display");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRecords]);

  useEffect(() => {
    if (selectedRecords.length === 0) {
      setIsDisabled(true);
      setSelectedTotal(0);
    }
  }, [selectedRecords]);

  const handleShowSelectButtonClick = (onClick) => {
    if (selectedRecords.length === 0)
      setNoDataText("You have not selected any records.");
    onClick();
  };

  const customShowSelectButton = (onClick, showSelected) => {
    return (
      <button
        className="btn bg-primary-color text-white mx-3 px-3 rounded-pill"
        onClick={() => handleShowSelectButtonClick(onClick)}
      >
        {showSelected ? "Show All" : "Show Selected Only"}
      </button>
    );
  };

  const selectRowProps = {
    mode: "checkbox",
    columnWidth: "40px",
    clickToSelect: true,
    showOnlySelected: true,
    unselectable: inactiveIds,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  const options = {
    noDataText: noDataText,
    showSelectedOnlyBtn: customShowSelectButton,
  };

  return (
    <div className="row justify-content-center px-3 my-3 RecordsTable">
      <div className="col-12 text-center mb-2">
        <h1 className="tableHeading">Staking History</h1>
      </div>
      <div className="col-12 text-center">
        <h2 className="tableHeading">Current Staking</h2>
      </div>
      <div id="card" className="card mx-3 my-4 p-2 staketables">
        <div>
          <BootstrapTable
            data={activeRecords}
            options={options}
            selectRow={selectRowProps}
            bordered={false}
            hover
            className="mb-3"
          >
            <TableHeaderColumn
              dataField="contractId"
              isKey
              hidden
              className="scroll-box"
            >
              Contract ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="amount"
              dataAlign="center"
              width="120"
              thStyle={{ whiteSpace: "normal" }}
              className="scroll-box"
            >
              Staking Amount
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="perApy"
              dataAlign="center"
              width="120"
              thStyle={{ whiteSpace: "normal" }}
              className="scroll-box"
            >
              APY %
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="contractDuration"
              dataAlign="center"
              width="120"
              thStyle={{ whiteSpace: "normal" }}
              className="scroll-box"
            >
              Contract Duration
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="timeStaked"
              dataAlign="center"
              width="150"
              thStyle={{ whiteSpace: "normal" }}
              tdStyle={{ whiteSpace: "normal" }}
              className="scroll-box"
            >
              Time Staked
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="formattedStartDate"
              dataAlign="center"
              width="180"
              tdStyle={{ whiteSpace: "normal" }}
              className="scroll-box"
            >
              Start Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="formattedEndDate"
              dataAlign="center"
              width="180"
              tdStyle={{ whiteSpace: "normal" }}
              className="scroll-box"
            >
              End Date
            </TableHeaderColumn>
          </BootstrapTable>

          <div className="d-flex flex-column flex-sm-row justify-content-between mb-1">
            <div className="font-weight-bold tableTotal pl-1 mb-2 mb-sm-0 text-center d-flex justify-content-center align-items-center">
              {selectedReward > 0
                ? "Total (with estimated reward): "
                : "Total: "}
              {!web3
                ? 0
                : Number(selectedTotal).toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                  })}
              &nbsp;
              {process.env.REACT_APP_TOKENSYMBOL}
            </div>

            {!web3 ? null : Number(
                web3.utils.fromWei(
                  stakingDataState.rewardPoolBalance.toString()
                )
              ) < selectedReward ? (
              <div
                className="my-2 px-1 text-center"
                style={{ color: "#1A004C", fontSize: "18px" }}
              >
                <span className="font-weight-bold">
                  Unstaking is unavailable while the system is under
                  maintenance. Please try again later. If you have any
                  questions, feel free to&nbsp;
                  <a
                    href="https://daybyday.io/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact us
                  </a>
                  .
                </span>
              </div>
            ) : (
              isSelectedEarlyRecord && (
                <div
                  className="text-center px-4 my-2"
                  style={{ color: "#1A004C" }}
                >
                  <span className="font-weight-bold">Important!</span> You are
                  unstaking your DBD before the end of your staking period. You
                  will lose access to your higher returns and be automatically
                  assigned to the&nbsp;
                  <span className="font-weight-bold">
                    {stakingDataState.earlyUnstakeApy}% APY
                  </span>
                  &nbsp;rate.
                </div>
              )
            )}

            {disUnstakeUnavMsg && (
              <div
                className="text-center px-4 my-2"
                style={{ color: "#1A004C" }}
              >
                <span className="font-weight-bold">Important!</span> Staking is
                unavailable for the selected row/s {unstakeUnavMsg}
              </div>
            )}

            <div className="d-flex justify-content-center align-items-center">
              <button
                id="tableButton"
                disabled={isDisabled}
                className="btn btn-block btn-lg btnClass"
                onClick={unstakeSelectedTerms}
              >
                Unstake
              </button>
            </div>
          </div>
        </div>
        <FireConfetti ref={confettiRef} />
        <UnstakeModal unstakeState={unstakeState} dispatch={dispatch} />
      </div>
    </div>
  );
}

export default ActiveRecordsCard;
