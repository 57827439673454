import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";
import StakeModal from "./StakeModal";
import FireConfetti from "components/FireConfetti";
//contexts
import { EthProviderContext } from "context/ethProviderContext";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "context/authContext";
import { StakingDataContext } from "context/stakingDataContext";
// utils
import DBDToken from "assets/DBDicon.svg";
import {
  getApprovedAmount,
  sendStakeApproveTransaction,
  sendTransaction,
  awaitTransaction,
} from "services/contract.service";
import { durationFormatter } from "utils/format";
import { toast } from "react-toastify";
// hooks & reducers
import { useForm } from "react-hook-form";
import { useWeb3 } from "hooks/useWeb3";
import { useContracts } from "hooks/useContracts";
import { useAwaitTxn } from "hooks/useAwaitTxn";
import { initStakeState, stakeReducer } from "reducers/stakeReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { addressFormatter } from "utils/format";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ConnectModal from "../ConnectModal";

function StakeCard(props) {
  const [searchParams] = useSearchParams();
  const [stakeFor, setStakeFor] = useState(false);

  const { tokenBalance, toggleUpdated } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [awaiting, setAwaiting] = useState(true);
  const [globLimit, setGlobLimit] = useState(0);
  // const [maxTokens, setMaxTokens] = useState(0);
  const [amount, setAmount] = useState(0);
  const [stakingTerms, setStakingTerms] = useState([]);

  const [isShown, setIsShown] = useState(false);
  const [confettiShown, setConfettiShown] = useState(false);
  const confettiRef = useRef();

  const [stakeState, dispatch] = useReducer(stakeReducer, initStakeState);

  // context
  const { authState } = useContext(AuthContext);
  const { ethProviderState } = useContext(EthProviderContext);
  const { stakingDataState } = useContext(StakingDataContext);

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const web3 = useWeb3();
  const [tokenContract, stakingContract] = useContracts();

  useAwaitTxn(
    ethProviderState.provider,
    stakeState.stakeTxn,
    toggleUpdated,
    awaiting,
    setAwaiting,
    dispatch
  );

  useEffect(() => {
    if (!authState.accountPoly) dispatch({ type: "RESET" });
  }, [authState.accountPoly]);

  useEffect(() => {
    let value = searchParams.get(process.env.REACT_APP_STAKEFORQUERY);
    setStakeFor(value);
  }, [searchParams]);

  useEffect(() => {
    const arrayForSort = [...stakingDataState.durationTerms];
    const tmp = arrayForSort.sort(function (a, b) {
      return a.period - b.period;
    });
    setStakingTerms(tmp);
  }, [stakingDataState.durationTerms]);

  useEffect(() => {
    async function getContractsData() {
      if (authState.accountPoly) {
        const tmpApprovedAmt = await getApprovedAmount(
          ethProviderState.provider,
          tokenContract,
          stakingContract,
          authState.accountPoly
        );
        if (tmpApprovedAmt > 0) {
          dispatch({ type: "APPROVED" });
        }
        setIsLoading(false);
      }
    }
    if (tokenContract && stakingContract && ethProviderState.provider) {
      getContractsData();
    }
  }, [
    stakingContract,
    tokenContract,
    authState.accountPoly,
    ethProviderState.provider,
  ]);

  // show the confetti
  useEffect(() => {
    if (stakeState.type === "finish" && !confettiShown) {
      confettiRef.current.fire();
      setConfettiShown(true);
    }
  }, [stakeState, confettiShown]);

  const submit = async (data) => {
    if (!authState.accountPoly) return;

    const submitAmount = amount ? amount : data.amount;

    if (!stakeState.isApproved) {
      try {
        dispatch({ type: "APPROVING" });
        const txn = await sendStakeApproveTransaction(
          ethProviderState.provider,
          tokenContract,
          stakingContract,
          authState.accountPoly,
          submitAmount
        );
        if (ethProviderState.provider.accounts) {
          await new Promise((resolve) => setTimeout(resolve, 10000));
        } else {
          await awaitTransaction(ethProviderState.provider, txn);
        }
        dispatch({ type: "APPROVED" });
      } catch (err) {
        console.error(err);;
        toast.error(
          "Failed to approve token to be used for the staking contract."
        );
        dispatch({ type: "ERROR" });
        return;
      }
      // in approve state stop contiuning if no data detected
      if (!submitAmount || !data.duration) {
        return;
      }
    }

    const minStakingEth = web3.utils.fromWei(
      stakingDataState.minPerStakeAmount.toString()
    );
    if (Number(submitAmount) < Number(minStakingEth)) {
      toast.error("You must stake " + minStakingEth + " or more");
      return;
    }

    if (!authState.isConnectedPoly || !authState.accountPoly) {
      toast.error("Please connect your wallet");
      return;
    }

    const maxPerStakeAmount = Number(
      web3.utils.fromWei(stakingDataState.maxPerStakeAmount.toString())
    );
    const maxPoolBalance = Number(
      web3.utils.fromWei(stakingDataState.maxPoolBalance.toString())
    );
    const currentPoolBalance = Number(
      web3.utils.fromWei(stakingDataState.currentPoolBalance.toString())
    );
    const limit =
      maxPerStakeAmount < maxPoolBalance - currentPoolBalance
        ? maxPerStakeAmount
        : maxPoolBalance - currentPoolBalance;
    let max = Number(limit).toFixed(4);
    let fixedAmount = Number(submitAmount).toFixed(4);
    setGlobLimit(Number(max));
    if (Number(fixedAmount) > Number(max)) {
      toast.error(
        "Your staking amount has exceeded the max individual staking limit. The current limit is " +
          Number(max).toLocaleString()
      );
      return;
    }

    let balance = Number(web3.utils.fromWei(tokenBalance.toString())).toFixed(
      4
    );
    if (Number(balance) < Number(fixedAmount)) {
      toast.error("Your token balance is too low");
      return;
    }

    if (stakeState.isApproved) {
      if (!submitAmount && !data.duration) {
        toast.error(
          "Please enter a valid amount and select a duration term to stake"
        );
        return;
      } else {
        if (!submitAmount || submitAmount <= 0) {
          toast.error("Please enter a valid amount");
          return;
        }
        if (!data.duration) {
          toast.error("Please select your staking duration.");
          return;
        }
      }
    }

    dispatch({ type: "STAKING", payload: true });
    setConfettiShown(false);
    try {
      setAwaiting(true);
      const approvedTxn = await sendStakeApproveTransaction(
        ethProviderState.provider,
        tokenContract,
        stakingContract,
        authState.accountPoly,
        submitAmount
      );
      if (approvedTxn && approvedTxn.startsWith("0x")) {
        const transactionRes = await awaitTransaction(
          ethProviderState.provider,
          approvedTxn
        );
        if (!transactionRes.status) {
          throw new Error("Something wrong with the approve transaction.");
        }
      }
      dispatch({ type: "APPROVED" });
      const stakerAddress = data.address ? data.address : authState.accountPoly;
      const transaction = stakingContract.methods
        .stake(web3.utils.toWei(submitAmount), data.duration, stakerAddress)
        .encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const stakeTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      dispatch({ type: "CONFIRM", payload: stakeTxn });
      reset({ amount: "", duration: "", address: authState.accountPoly });
    } catch (err) {
      console.error(err);;
      toast.error("Failed to stake, please try again.");
      dispatch({ type: "ERROR" });
    }
  };

  const inputChange = (value) => {
    setAmount(value);

    const maxPerStakeAmount = Number(
      web3.utils.fromWei(stakingDataState.maxPerStakeAmount.toString())
    );
    const maxPoolBalance = Number(
      web3.utils.fromWei(stakingDataState.maxPoolBalance.toString())
    );
    const currentPoolBalance = Number(
      web3.utils.fromWei(stakingDataState.currentPoolBalance.toString())
    );
    const limit =
      maxPerStakeAmount < maxPoolBalance - currentPoolBalance
        ? maxPerStakeAmount
        : maxPoolBalance - currentPoolBalance;

    const max = Number(limit).toFixed(4);
    setGlobLimit(max);
  };

  const applyMax = () => {
    const maxPerStakeAmount = Number(
      web3.utils.fromWei(stakingDataState.maxPerStakeAmount.toString())
    );
    const maxPoolBalance = Number(
      web3.utils.fromWei(stakingDataState.maxPoolBalance.toString())
    );
    const currentPoolBalance = Number(
      web3.utils.fromWei(stakingDataState.currentPoolBalance.toString())
    );
    const limit =
      maxPerStakeAmount < maxPoolBalance - currentPoolBalance
        ? maxPerStakeAmount
        : maxPoolBalance - currentPoolBalance;
    let weiBalance = Number(
      web3.utils.fromWei(tokenBalance.toString())
    ).toFixed(4);
    let max = 0;
    if (weiBalance > limit) {
      max = limit;
    } else {
      max = weiBalance;
    }

    if (max !== 0) {
      max = Number(max.toString()).toFixed(4);
      document.getElementById("stakeAmountInput").value = max;
      // setMaxTokens(max);
      inputChange(max);
      setAmount(max);
    }
  };

  return (
    <div
      id="stakeCard"
      className="card mb-4 mx-auto"
      style={{ maxWidth: "550px" }}
    >
      <div className="card-body row justify-content-center">
        <div className="col-11">
          <form onSubmit={handleSubmit(submit)}>
            <div className="row">
              <span id="walletBalance" className="col">
                <img src={DBDToken} height="20" alt="" className="mb-1 pr-3" />
                Wallet Balance:&nbsp;
                {web3
                  ? parseFloat(
                      parseFloat(
                        web3.utils.fromWei(tokenBalance.toString(), "Ether")
                      ).toFixed(4)
                    ).toLocaleString(undefined, { maximumFractionDigits: 4 })
                  : 0}
              </span>
            </div>
            <div className="container">
              <div className="row my-4 d-flex justify-content-center duarationSelection">
                {stakingTerms?.map(
                  (term, index) =>
                    term.isActive && (
                      <div key={index}>
                        <div className="form-check form-check-inline durationRadio">
                          <input
                            id={term.durationIndex}
                            className="form-check-input"
                            {...register("duration")}
                            name="duration"
                            type="radio"
                            value={term.durationIndex}
                          />
                          <label
                            htmlFor={term.durationIndex}
                            className="selDurLabel"
                          >
                            {term.period === "0"
                              ? `Flexible`
                              : `Lock for ${durationFormatter(
                                  parseInt(term.period)
                                ).toLocaleString(undefined, {
                                  maximumFractionDigits: 4,
                                })} Days`}
                          </label>
                          <br />
                          <p className="text-danger">
                            {errors.duration?.message}
                          </p>
                        </div>
                        <p className="apyElement">
                          {parseFloat(term.perAPY).toLocaleString(undefined, {
                            maximumFractionDigits: 4,
                          })}
                          % APY
                        </p>
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="row mb-1">
              <div className="input-group col align-self-end">
                <div className="input-group-prepend">
                  <img src={DBDToken} alt="DBD" id="dbdLogo" className="mr-1" />
                </div>
                <input
                  className="form-control form-control-lg stakeInput"
                  id="stakeAmountInput"
                  {...register("amount")}
                  type="number"
                  step="0.0001"
                  placeholder="0"
                  onChange={(e) => inputChange(e.currentTarget.value)}
                />
              </div>
              <p className="text-danger">{errors.amount?.message}</p>
            </div>
            <div className="text-right mb-3">
              <span className="linkText" onClick={() => applyMax()}>
                Click to Enter Max DBD
              </span>
            </div>

            {authState.isAdminPoly ||
              process.env.REACT_APP_WHITELISTSTAKEFOR.includes(
                authState.accountPoly
              ) ||
              (stakeFor && (
                <div className="row mb-1">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <div className="secondary-color">
                        Want to gift your DBD and staking rewards? Enter their
                        ERC20 wallet address below
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="input-group col align-self-end">
                        <div className="input-group-prepend p-2">
                          <FontAwesomeIcon icon={faAddressBook} size="2x" />
                        </div>
                        <input
                          style={{ fontSize: "14px" }}
                          className="form-control form-control-lg stakeInput text-truncate"
                          {...register("address")}
                          type="text"
                          placeholder={addressFormatter(authState.accountPoly)}
                          defaultValue={authState.accountPoly}
                        />
                      </div>
                      <p className="text-danger">{errors.address?.message}</p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}

            {Number(globLimit) < Number(amount) && (
              <p className="text-danger text-right mb-3">
                Maximum DBD Stake limit reached for this transaction
              </p>
            )}

            {authState.isSigninPoly ? (
              isLoading ? (
                <div className="my-3">
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-danger" role="status">
                      <span className="sr-only">
                        Awaiting to get your approved amount...
                      </span>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ color: "#1a004c" }}
                  >
                    Awaiting to get your approved amount...
                  </div>
                </div>
              ) : (
                <div className="mb-2 mt-4 px-0">
                  {stakeState.isApproved ? (
                    <button
                      disabled={
                        stakingDataState.isPaused || !stakeState.isApproved
                      }
                      className="btn btn-block btn-secondary"
                      onClick={() => handleSubmit()}
                    >
                      Stake DBD
                    </button>
                  ) : (
                    <div className="row">
                      <div className="col-md-6 col-12 mb-md-0 mb-2">
                        <button
                          disabled={
                            stakeState.isApproved || stakeState.isApproving
                          }
                          className="btn btn-block btn-secondary"
                          id="approvalBtn"
                          onClick={() => handleSubmit()}
                        >
                          Approve DBD
                          {stakeState.isApproving ? (
                            <div
                              className="spinner-border approveSpinner"
                              role="status"
                            ></div>
                          ) : null}
                        </button>
                      </div>
                      <div className="col-md-6 col-12">
                        <button
                          disabled={
                            stakingDataState.isPaused || !stakeState.isApproved
                          }
                          className="btn btn-block btn-secondary"
                          onClick={() => handleSubmit()}
                        >
                          Stake DBD
                        </button>
                      </div>
                    </div>
                  )}

                  {stakingDataState.isPaused && (
                    <span id="PauseStaking" className="col">
                      The DBD Staking Period has now closed. Follow&nbsp;
                      <a
                        href={"https://twitter.com/daybydayio"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Day By Day on socials
                      </a>
                      &nbsp;to stay informed about future staking pools.
                    </span>
                  )}
                </div>
              )
            ) : (
              <div className="mb-4 mt-4">
                <div className="row">
                  <button
                    className="btn btn-block btn-secondary"
                    onClick={() => setIsShown(true)}
                  >
                    Connect Wallet
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <FireConfetti ref={confettiRef} />
      <StakeModal stakeState={stakeState} dispatch={dispatch} />
      <ConnectModal isShown={isShown} setIsShown={setIsShown} />
    </div>
  );
}

export default StakeCard;
