import React, { useContext, useEffect } from "react";
// Routes
import { Routes, Route, useLocation } from "react-router-dom";
// Components
import CustomNavbar from "components/layout/Navbar";
import Footer from "components/layout/Footer";
import PolygonPage from "./PolygonPage";
import AlgorandlegacyPage from "./AlgorandLegacyPage";
import AlgorandPage from "./AlgorandPage";
import StakeCardAlgo from "components/algo/StakeCardAlgo";
import AdminPage from "./AdminPage";
import NotFound from "./status/404";
// contexts
import { StakingDataProvider } from "context/stakingDataContext";
import { AlgoProviderContext } from "context/algoProviderContext";
// styles
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "../assets/fontawesome/css/all.min.css";

// css
import "../scss/styles.scss";

// web3
import LandingPage from "./LandingPage";
import { EthProviderContext } from "context/ethProviderContext";
import { EthereumProvider } from "@walletconnect/ethereum-provider";

import { PeraWalletConnect } from "@perawallet/connect";
import { PERA_WALLET } from "utils/constants";

function App() {
  const location = useLocation();
  const { initWalletConnectProvider } = useContext(EthProviderContext);
  const { initAlgoProvider } = useContext(AlgoProviderContext);

  useEffect(() => {
    async function initProviders() {
      const provider = await EthereumProvider.init({
        projectId: process.env.REACT_APP_PROJECTID, // required
        chains: [Number(process.env.REACT_APP_CHAINID)], // required
        optionalMethods: ["wallet_addEthereumChain", "wallet_watchAsset"],
        events: ["accountsChanged", "chainChanged", "disconnect"],
        showQrModal: true, // requires @walletconnect/modal
        qrModalOptions: {
          themeVariables: {
            "--wcm-z-index": "100002",
          },
        },
      });
      initWalletConnectProvider(provider);

      const algoProvider = {
        provider: new PeraWalletConnect(),
        type: PERA_WALLET,
      };
      initAlgoProvider(algoProvider);
    }
    initProviders();
  }, []);

  return (
    <>
      <div>
        <StakingDataProvider>
          <CustomNavbar />
          <main id="main">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route
                path="/polygon"
                element={<PolygonPage location={location} />}
              />
              <Route
                path="/legacy/algorand"
                element={<AlgorandlegacyPage location={location} />}
              />
              <Route
                path="/algorand"
                element={<AlgorandPage location={location} />}
              />
              <Route
                path="/algorand/pool/:Id"
                element={<StakeCardAlgo location={location} />}
              />
              <Route
                path="/admin"
                element={<AdminPage location={location} />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </StakingDataProvider>
      </div>
    </>
  );
}

export default App;
