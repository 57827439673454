import React from "react";
import { useLocation } from "react-router-dom";

function StakingInfo(props) {
  const location = useLocation();
  const { min, limit } = props;

  return (
    <div>
      {/* If pool isn't full */}
      {Number(limit) < Number(min) && (
        <div>
          <p className='mt-4 minMaxTrans'>The staking pool is currently full. Please come back later.</p>
        </div>
      )}
      {/* If pool isn't full */}
      {Number(limit) >= Number(min) && (
        <div>
          <p className='mt-4 minMaxTrans'>
            Minimum DBD stake per transaction is&nbsp;
            <strong>
              {parseFloat(min).toLocaleString(undefined, {
                maximumFractionDigits: 4,
              })}
              &nbsp;DBD
            </strong>
            . Maximum DBD stake per transaction is&nbsp;
            <strong>
              {parseFloat(limit).toLocaleString(undefined, {
                maximumFractionDigits: 4,
              })}
              &nbsp;DBD
            </strong>
            .
            <br />
            {location.pathname.includes('polygon') && <>You will recieve stDBD as confirmation of your stake.</>}
          </p>
        </div>
      )}
    </div>
  );
}

export default StakingInfo;
