import { useEffect } from "react";
import { awaitTransaction } from "services/contract.service";
import { toast } from "react-toastify";

export function useAwaitTxn(
  provider,
  txn,
  toggleUpdated,
  awaiting,
  setAwaiting,
  dispatch
) {
  useEffect(() => {
    async function checkTxHash() {
      try {
        // check the Wallet Connect provider
        if (provider.accounts) {
          // wallet connect doesn't have the event to track the transaction
          await new Promise((resolve) => setTimeout(resolve, 7000));
          toggleUpdated();
          await new Promise((resolve) => setTimeout(resolve, 5000));
          toast.info(
            "Please refresh the page if the records has not been updated."
          );
        } else {
          const rec = await awaitTransaction(provider, txn);
          if (rec) {
            toggleUpdated();
            toast.success(
              "Transaction has been successful. Your records will be updated in a few seconds."
            );
            dispatch({ type: "FINISH" });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (txn && awaiting) {
      checkTxHash();
      setAwaiting(false);
    }
  }, [txn, toggleUpdated, awaiting, provider, setAwaiting, dispatch]);
}
