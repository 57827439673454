import React, { useState, useContext } from "react";
// context
import { AuthContext } from "context/authContext";
import { AlgoProviderContext } from "context/algoProviderContext";
// hooks
import { useAccountProvider } from "hooks/useAlgo";
// utils
import { deployContract, callPyTealContract } from "services/contract.service";
import { toast } from "react-toastify";
import { calculateRewardRatio } from "utils/math";
// images
import algosdk from "algosdk";
import { transfer } from "utils/algoSDKHelpers";

function CreateAlgoContract() {
  const { algoProviderState } = useContext(AlgoProviderContext);

  const { authState } = useContext(AuthContext);

  const providerDetails = useAccountProvider(algoProviderState);

  // updating values
  const [minStake, setMinStake] = useState(0);
  const [maxStake, setMaxStake] = useState(0);
  const [maxStakingPool, setMaxStakingPool] = useState(0);
  const [adminASA, setAdminASA] = useState(0);
  const [rewardASA, setRewardASA] = useState(0);
  const [displayName, setDisplayName] = useState("");
  const [stakeTokenCreator, setStakeTokenCreator] = useState("");
  const [duration, setDuration] = useState(0);

  const [apy, setAPY] = useState(0);
  const [directRewardRatio, setDirectRewardRatio] = useState(0);

  const [stakeASA, setStakeASA] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);

  const create = async () => {
    // Deploy contract
    let durationInDays = duration * 86400;

    let result = await deployContract(
      authState.accountAlgo, providerDetails.provider, providerDetails.type,
      adminASA, rewardASA,
      algosdk.algosToMicroalgos(minStake), algosdk.algosToMicroalgos(maxStake), algosdk.algosToMicroalgos(maxStakingPool),
      directRewardRatio > 0 ? 
      displayName.toLowerCase().includes("nft") ? directRewardRatio * 1000000 : directRewardRatio 
      : calculateRewardRatio(durationInDays, apy), durationInDays, stakeTokenCreator
    );

    let optingInToStakeASA = stakeASA !== 0 && stakeASA !== rewardASA;

    // Top up contract with Algo's
    await transfer(authState.accountAlgo, result.contractAddress, 
      optingInToStakeASA ? 0.4 : 0.3, // amount required
      0, undefined, providerDetails.provider, providerDetails.type
    )

    // Call opt in to reward and stake
    await callPyTealContract(
      "opt_into_asset",
      rewardASA, adminASA, authState.accountAlgo,
      providerDetails.provider, providerDetails.type,
      result.contractId
    );

    if (optingInToStakeASA)
    {
      await callPyTealContract(
        "opt_into_asset",
        stakeASA, adminASA, authState.accountAlgo,
        providerDetails.provider, providerDetails.type,
        result.contractId
      );
    }

    const file = selectedFile;
    const reader = new FileReader();
    
    const base64Image = await new Promise((resolve, reject) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });

    const contractDetails = {
      ApplicationAddress: result.contractAddress,
      AppId: result.contractId,
      AdminASA: adminASA,
      image: base64Image,
      contentType: file.type,
      displayName: displayName,
      stakeTokenCreator: stakeTokenCreator
    };

    // Call insurefi backend
    await fetch(process.env.REACT_APP_BASEURL + "Stake/add", {
      method: "Post",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(contractDetails)
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Contract deployed");
        } else {
          toast.error('Error calling backend');
          throw new Error('Error calling backend');
        }
    })
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
};

  return (
    <div className="card mb-4 mx-auto card-width">
      {!authState.isAdminAlgo ? (
        <div className="text-center">
        </div>
      ) : (
        <div>
          <h2 className="genSettingsHeading">Create Contract</h2>

          {/* Stake Amount Update */}
          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Min Stake per Stake</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setMinStake(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Max Stake Per Stake</b>
                  </label>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    min="0"
                    step={1}
                    className="form-control form-control-lg"
                    onChange={(e) => setMaxStake(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Max Staking Pool</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    step={1}
                    className="form-control form-control-lg"
                    onChange={(e) => setMaxStakingPool(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Duration (Days)&nbsp;</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setDuration(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>APY &nbsp;</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setAPY(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Or Reward Ratio</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setDirectRewardRatio(Number(e.currentTarget.value) * 100)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Admin ASA &nbsp;</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setAdminASA(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Reward ASA &nbsp;</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setRewardASA(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Display Name &nbsp;</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setDisplayName(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Stake token creator &nbsp;</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setStakeTokenCreator(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <p>Specific Stake token - Not required field &nbsp;</p>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    onChange={(e) => setStakeASA(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5 d-flex justify-content-center">
            <input type="file" onChange={handleFileChange} />
          </div>

          <div className="text-center mb-2">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => create()}
            >
              Create
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateAlgoContract;
