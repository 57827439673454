import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ctcparse, refreshInfo } from "utils/reachHelpers";
import * as backend from "build/index.main.mjs";
// Algo imports
import { loadStdlib } from "@reach-sh/stdlib";
import { ALGO_MyAlgoConnect as MyAlgoConnect } from "@reach-sh/stdlib";
import { checkSessionExists } from "@jackcom/reachduck";
import { getAppliactionInfoById } from "../services/contract.service";
// constants
import { ctcInfoStr, MY_ALGO_CONNECT, PERA_WALLET } from "utils/constants";

import { GetAddressFromAppId } from "utils/insurefiHelpers";

const reachObject = loadStdlib("ALGO");

export function useReach() {
  return reachObject;
}

export function useStakingDataReach(acc, ctc, updateStakingData) {
  // Staking contract data
  const [data, setData] = useState();
  const reach = reachObject;

  useEffect(() => {
    async function getTheStakingData(useAcc, useCtc) {
      try {
        const contractData = await refreshInfo(useAcc, useCtc, reach);

        // converting to normal units
        contractData.maxStakePerStake = reach.formatCurrency(
          contractData.maxStakePerStake
        );
        contractData.maxStakingPool = reach.formatCurrency(
          contractData.maxStakingPool
        );
        contractData.minStaking = reach.formatCurrency(contractData.minStaking);

        contractData.remainingRewards = reach.formatCurrency(
          contractData.remainingRewards
        );
        contractData.staked = reach.formatCurrency(contractData.staked);
        contractData.totalStaked = reach.formatCurrency(
          contractData.totalStaked
        );
        contractData.userExpectedRewards = reach.formatCurrency(
          contractData.userExpectedRewards
        );

        setData(contractData);
        return contractData;
      } catch (error) {
        console.error(error);
        toast.error("Failed to get Staking data from the blockchain");
      }
    }

    async function createFreeAccount() {
      try {
        const freeAcc = await reach.createAccount();
        const freectc = freeAcc.contract(backend, ctcparse(ctcInfoStr));

        return [freeAcc, freectc];
      } catch (error) {
        console.error(error);
        toast.error("Failed to create account");
      }
    }

    async function runCode() {
      const storage = window.localStorage;
      const algoData = storage.getItem("algo_data");
      const { exists } = checkSessionExists();
      if (!algoData && !exists) {
        reach.setWalletFallback(
          reach.walletFallback({
            providerEnv: process.env.REACT_APP_ALGOENVIRONMENT, // LocalHost for devnet
            MyAlgoConnect,
          })
        );
        const accDetails = await createFreeAccount();
        const tmpData = await getTheStakingData(accDetails[0], accDetails[1]);
        storage.setItem("algo_data", JSON.stringify(tmpData));
        window.location.reload();
      } else {
        if (acc !== 0 && ctc) {
          storage.removeItem("algo_data");
          await getTheStakingData(acc, ctc);
        } else {
          const algoObj = JSON.parse(algoData);
          setData(algoObj);
        }
      }
    }

    runCode();
  }, [acc, ctc, updateStakingData, reach]);

  return data;
}

export function useStakingDataAlgo(userAddress, updateStakingData, appId) {
  // Staking contract data
  const [data, setData] = useState();

  useEffect(() => {
    async function runCode() {
      let appAddress = await GetAddressFromAppId(appId);

      let globalInfo = await getAppliactionInfoById(
        appId,
        appAddress,
        userAddress
      );
      const globalInfoString = JSON.stringify(globalInfo);
      const algoObj = JSON.parse(globalInfoString);
      setData(algoObj);
    }

    runCode();
  }, [userAddress, updateStakingData]);

  return data;
}

export function useAccountData(account) {
  const [ctc, setCtc] = useState();

  useEffect(() => {
    async function getAccountDetails() {
      const tmpctc = account.contract(backend, ctcparse(ctcInfoStr));
      setCtc(tmpctc);
    }
    if (account && account !== 0 && account.contract) {
      getAccountDetails();
    }
  }, [account]);

  return ctc;
}

export function useAccountProvider(algoProvider) {
  const [provider, setProvider] = useState();

  useEffect(() => {
    async function getAccountDetails() {
      if (algoProvider.type === MY_ALGO_CONNECT) {
        let tmpProvider = null;
        setProvider(tmpProvider);
      } else if (algoProvider.type === PERA_WALLET) {
        let address;
        setProvider({
          address: address,
          provider: algoProvider.provider,
          type: algoProvider.type,
        });
      }
    }
    if (algoProvider.type) {
      getAccountDetails();
    }
  }, [algoProvider.type]);

  return provider;
}
