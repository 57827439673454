import React, { useReducer, createContext } from "react";

const initialState = {
  provider: null,
  type: null,
  connected: false
};

export const AlgoProviderContext = createContext(initialState);

const algoProviderReducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return { ...state, ...action.payload };
    case "CONNECT":
      return { ...state, connected: true };
    case "DISCONNECT":
      return { ...state, provider: null, type: null, connected: false };
    default:
      return state;
  }
};

export function AlgoProvider({ children }) {
  const [algoProviderState, dispatch] = useReducer(
    algoProviderReducer,
    initialState
  );

  const initAlgoProvider = (value) => {
    dispatch({ type: "INIT", payload: value });
  };

  const connectAlgoProvider = (value) => {
    dispatch({ type: "CONNECT", payload: value });
  };

  const disconnectAlgoProvider = () => {
    dispatch({ type: "DISCONNECT" });
  };

  const value = {
    algoProviderState,
    initAlgoProvider,
    connectAlgoProvider,
    disconnectAlgoProvider,
  };

  return (
    <AlgoProviderContext.Provider value={value}>
      {children}
    </AlgoProviderContext.Provider>
  );
}