import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PolyGeneralSettingsCard from "components/admin/settings/PolyGeneralSettingsCard";
import AlgoLegacyGeneralSettingsCard from "components/admin/settings/algorand/AlgoLegacyGeneralSettingsCard";
import MultiContractCard from "components/admin/settings/MultiContractCard";
import DurationRecordsCard from "components/admin/duration/DurationRecordsCard";
import StakingRecordsCard from "components/admin/records/StakingRecordsCard";
import WithdrawnRecordsCard from "components/admin/records/WithdrawnRecordsCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// web3
import Web3 from "web3";
// contexts
import { AuthContext } from "context/authContext";
import { EthProviderContext } from "context/ethProviderContext";
import { AlgoProviderContext } from "context/algoProviderContext";
// reducer
import { adminTxnReducer, initAdminTxnState } from "reducers/adminTxnReducer";
import { useContracts, useStakingData } from "hooks/useContracts";
import { useAccountData, useReach } from "hooks/useAlgo";
// images
import PolygonLogo from "assets/PolygonLogo.svg";
import AlgorandLogo from "assets/AlgorandLogo.svg";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { refreshInfo } from "utils/reachHelpers";
import detectEthereumProvider from "@metamask/detect-provider";

import { NetworkType } from "models/enums";

import { getAppliactionInfoById } from "../services/contract.service";
import { getBalance } from "utils/algoSDKHelpers";
import { GetAddressFromAppId } from "utils/insurefiHelpers";
import {
  ReconnectWallet,
  reconnectAlgoWallet
} from "services/auth.service";
import { PERA_WALLET } from "utils/constants";
import { getContractList } from "utils/insurefiHelpers";
import { checkSessionExists } from "@jackcom/reachduck";

function AdminPage(props) {
  const { location } = props;
  const { ethProviderState } = useContext(EthProviderContext);
  const {
    authState,
    signinAlgo,
    signoutAlgo,
    updateAdminAlgo,
    updateAdminPoly,
    signinPoly,
  } = useContext(AuthContext);
  const { algoProviderState } = useContext(AlgoProviderContext);

  const [adminTxnState, dispatch] = useReducer(
    adminTxnReducer,
    initAdminTxnState
  );

  const [network, setNetwork] = useState(NetworkType.Undefined);
  const [adminActive, setAdminActive] = useState(false);
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();
  const [, stakingContract] = useContracts();
  const stakingData = useStakingData(stakingContract, true);
  const ctc = useAccountData(authState.accountAlgo);
  const reach = useReach();

  useEffect(() => {
    async function getContracts() {
      if (authState.accountAlgo && authState.accountAlgo.networkAccount == null)
      {
        if (authState.accountAlgo === process.env.REACT_APP_STAKINGADMIN_ADDRESS)
        {
          setNetwork(NetworkType.Algorand);
          updateAdminAlgo(true);
          setAdminActive(true);
          return;
        }

        let contractsData = await getContractList(authState.accountAlgo);

        if (network && network.includes('pool'))
        {
          let appIdFromURL = Number(network.replace('/algorand/pool/', ''));

          const foundContract = contractsData.find(el => Number(el.appId) === appIdFromURL);
          if (foundContract) contractsData = [foundContract];
        }

        let algoAdmin = false;
        for (const element of contractsData) {
          let appAddress = await GetAddressFromAppId(element.appId);
  
          let globalInfo = await getAppliactionInfoById(
            element.appId,
            appAddress,
            authState.accountAlgo
          );
          const globalInfoString = JSON.stringify(globalInfo);
          const stakingData = JSON.parse(globalInfoString);
  
          const tokenBal = await getBalance(
            authState.accountAlgo,
            stakingData.adminASA
          );
          if (tokenBal > 0) {
            algoAdmin = true;
            break;
          }
        };
        updateAdminAlgo(algoAdmin);
        setAdminActive(algoAdmin);

        if (!algoAdmin) {
          toast.error(
            `You are not allowed to access the admin portal. It will direct you back to the staking pages in a few seconds.`
          );
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate(network);
        }
      }
    }

    getContracts();
  }, [authState]);

  // set network from url
  useEffect(() => {
    if (location.state && location.state.network) {
      if (location.state.network.toLowerCase().includes(NetworkType.Algorand)) setNetwork(NetworkType.Algorand);
      if (location.state.network.toLowerCase().includes(NetworkType.Polygon)) setNetwork(NetworkType.Polygon);
      if (location.state.network.toLowerCase() === NetworkType.AlgorandLegacy) setNetwork(NetworkType.AlgorandLegacy);
    } else { // Default
      setNetwork(NetworkType.Polygon);
    }
  }, [location]);

  // Change network
  useEffect(() => {
    if (network) {
      setChecked(false);
    }
  }, [network]);

  useEffect(() => {
    async function reconnectPera() {
      if (
        algoProviderState &&
        algoProviderState.provider &&
        algoProviderState &&
        !algoProviderState.accountAlgo &&
        !algoProviderState.provider.isConnected
      ) {
        await ReconnectWallet(signinAlgo, algoProviderState.provider);
      }
    }
    reconnectPera();
  }, [algoProviderState]);

  // Check role
  useEffect(() => {
    // rework function
    async function checkRole() {
      let isPolyAdmin = true;
      let isAlgoAdmin = true;

      if (!authState.accountPoly && !authState.accountAlgo) {
        isPolyAdmin = false;
        isAlgoAdmin = false;
      } else {
        // polygon
        let provider;
        if (ethProviderState.walletConnectProvider?.accounts?.length > 0) {
          provider = ethProviderState.walletConnectProvider;
        } else {
          const metmaskProvider = await detectEthereumProvider();
          if (metmaskProvider) {
            const accounts = await metmaskProvider.request({
              method: "eth_accounts",
            });
            if (accounts.length > 0) {
              provider = metmaskProvider;
            }
          }
        }
        if (provider) {
          const web3 = new Web3(provider);
          const accounts = await web3.eth.getAccounts();
          signinPoly(accounts[0]);
          if (accounts[0] !== stakingData.owner) {
            updateAdminPoly(false);
            isPolyAdmin = false;
          } else {
            updateAdminPoly(true);
            setAdminActive(true);
          }
        }

        // Algorand
        if (authState.accountAlgo && authState.accountAlgo.networkAccount) {
          // Legacy 
          const info = await refreshInfo(authState.accountAlgo, ctc, reach);
          if (
            authState.accountAlgo.networkAccount.addr !==
            reach.formatAddress(info.owner)
          ) {
            isAlgoAdmin = false;
          } else {
            updateAdminAlgo(true);
            setAdminActive(true);
          }
          
        } else {
          isAlgoAdmin = false;
        }
      }

      if (!isPolyAdmin && !isAlgoAdmin) {
        toast.error(
          `You are not allowed to access the admin portal. It will direct you back to the staking pages in a few seconds.`
        );
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate(network);
      }
    }

    async function reconnectPera()
    {
      if (algoProviderState && algoProviderState.type === PERA_WALLET && algoProviderState.provider && !authState.accountAlgo) {
        await ReconnectWallet(signinAlgo, algoProviderState.provider);
      }
    }

    if (network == NetworkType.Algorand) reconnectPera();

    const { exists } = checkSessionExists();
    if (!authState.accountAlgo && network == NetworkType.AlgorandLegacy) {
      if (exists) {
        reconnectAlgoWallet(signinAlgo, signoutAlgo);
      }
      dispatch({ type: "RESET" });
    }

    // When going to algo legacy but auth state is on algo
    if (network === NetworkType.AlgorandLegacy && exists && !authState.accountAlgo.networkAccount)
    {
      reconnectAlgoWallet(signinAlgo, signoutAlgo);
    }

    if (authState && authState.accountAlgo === process.env.REACT_APP_STAKINGADMIN_ADDRESS)
    {
      setNetwork(NetworkType.Algorand);
      updateAdminAlgo(true);
      setAdminActive(true);
      return;
    }

    if (network === NetworkType.Polygon && stakingData.owner && !checked)
    {
      checkRole();
      setChecked(true);
    } else if (stakingData.owner && (!authState.isAdminPoly || !authState.isAdminAlgo) 
      && !checked && authState.accountAlgo != undefined) 
    {
      checkRole();
      setChecked(true);
    }
  }, [
    navigate,
    ctc,
    stakingData,
    authState,
    ethProviderState,
    signinPoly,
    updateAdminAlgo,
    updateAdminPoly,
    reach,
    network,
    checked
  ]);

  return (
    <div>
      {!adminActive ? (
        <div className="text-center mt-5">
          <Link to="/" className="primary-color mb-3">
            Back to Landing Page
          </Link>
          <div>Please wait for a few seconds to check your permission.</div>
        </div>
      ) : (
        <div className="admin-padding">
          <h2 className="my-3 text-center">
            Admin portal
          </h2>
          <div className="my-5 d-flex justify-content-center">
            <div className="mr-4 text-center">
              <Link to={network} className="primary-color">
                <button
                  className="btn btn-block"
                  style={{ width: "120px" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                </button>
              </Link>
            </div>
            <div className="mr-4 text-center">
              <button
                className="btn btn-block"
                onClick={() => setNetwork(NetworkType.Algorand)}
              >
                <img
                  src={AlgorandLogo}
                  alt="Algorand Logo"
                  width={200}
                  height={95}
                />
              </button>
            </div>
            <div className="mr-4 text-center">
              <button
                className="btn btn-block"
                onClick={() => setNetwork(NetworkType.Polygon)}
              >
                <img
                  src={PolygonLogo}
                  alt="Polygon Logo"
                  width={180}
                  height={85}
                />
              </button>
            </div>
            <div>
              <button
                className="btn btn-block"
                onClick={() => setNetwork(NetworkType.AlgorandLegacy)}
              >
                Algorand Legacy
              </button>
            </div>
          </div>

          {network === NetworkType.AlgorandLegacy && checked && authState.accountAlgo.networkAccount && (
            <div className="col">
              <AlgoLegacyGeneralSettingsCard setChecked={setChecked} />
            </div>
          )}

          {network === NetworkType.Algorand && (
            <div className="col">
              <MultiContractCard />
            </div>
          )}

          {network === NetworkType.Polygon && (
            <>
              <div className="col">
                <PolyGeneralSettingsCard
                  adminTxnState={adminTxnState}
                  dispatch={dispatch}
                />
              </div>
              <DurationRecordsCard
                adminTxnState={adminTxnState}
                dispatch={dispatch}
              />

              {authState.accountPoly === stakingData.owner && (
                <>
                  <StakingRecordsCard />
                  <WithdrawnRecordsCard />
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default AdminPage;
