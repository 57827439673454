import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons"

function LandingPageButton(props) {
  const { text, logo, toLink, legacyLink } = props;

  return (
    <div>
      <div className="landingButtonCard">
        <div className="mt-3">
          <img src={logo} alt="Algorand Logo" width={45} />
        </div>

        <p className="font-weight-bold mt-2">{text}</p>

        <div className="mt-4 mb-4 pl-4 pr-4">
          <Link to={toLink} className="btn btn-sm btn-outline-secondary btn-thick w-100 d-flex justify-content-between align-items-center">
              <span className="float-left">Stake</span>
              <i className="fas fa-arrow-right ml-2"></i>
          </Link>
        </div>
      </div>

      {legacyLink ? (
        <div className="d-flex justify-content-center">
          <Link to="/legacy/algorand">
            <div className="legacyLink"><FontAwesomeIcon icon={faLayerGroup} /> Stake DBDv1 on Algorand</div>
          </Link>
        </div>
      ) : null}
    </div>
  );
}
export default LandingPageButton;
