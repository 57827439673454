import { MY_ALGO_CONNECT, PERA_WALLET } from "../utils/constants";
import algosdk, {
  algosToMicroalgos,
  waitForConfirmation,
  microalgosToAlgos,
} from "algosdk";

// Contracts
const approvalProgram = '/approval.teal';
const clearProgram = '/clear.teal';

const baseServer = process.env.REACT_APP_PURESTAKE_ADDRESS;
const port = "";
const token = "";
const algodClient = new algosdk.Algodv2(token, baseServer, port);

// Constants
const MAX_MICRO_ALGO_VALUE = 2 ** 53 - 1;
const MICRO_ALGO = 1000000;

export async function generateApplicationCallTxn(
  from,
  appId,
  applicationArgs,
  foreignAssets = [],
  foreignAccounts = [],
  foreignApps = [],
  fee = 0
) {
  if (applicationArgs.length === 0) {
    throw new Error("Failed to generate application call");
  }

  const suggestedParams = await algodClient.getTransactionParams().do();
  if (fee && fee !== 0) {
    suggestedParams.fee = fee;
    suggestedParams.flatFee = true;
  }

  const txn = algosdk.makeApplicationNoOpTxn(
    from,
    suggestedParams,
    appId,
    applicationArgs,
    foreignAccounts,
    foreignApps,
    foreignAssets
  );
  return txn;
}

export async function generateTransferTransaction(
  from,
  to,
  amount,
  note,
  asaId = 0,
  assumeZeroDecimal = false
) {
  const suggestedParams = await algodClient.getTransactionParams().do();
  if (!asaId || asaId === 0) {
    const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      amount: algosToMicroalgos(amount),
      note: note ? convertToUint8Array(note) : undefined,
      suggestedParams,
    });
    return txn;
  } else {
    let microAmount = amount;

    // Skipping check as it can add a bit of time to a bulk transfer transaction
    if (!assumeZeroDecimal) {
      const saleASAInfo = await algodClient.getAssetByID(asaId).do();

      if (saleASAInfo.params.decimals !== 0) {
        microAmount = algosToMicroalgos(amount);
      }
    }
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: from,
      to: to,
      assetIndex: asaId,
      amount: microAmount,
      note: note ? convertToUint8Array(note) : undefined,
      suggestedParams,
    });
    return txn;
  }
}

export async function GenerateDeployContractTxn(address, appArgs, foreignAssets)
{
  const localInts = 8;
  const localBytes = 0;
  const globalInts = 9;
  const globalBytes = 1;

  const approval_program = await compileProgram(await fetchTealContent(approvalProgram));
  const clear_program = await compileProgram(await fetchTealContent(clearProgram));

  try {
    const onComplete = algosdk.OnApplicationComplete.NoOpOC;
    const params = await algodClient.getTransactionParams().do();
    params.fee = 1000;
    params.flatFee = true;

    const txn = algosdk.makeApplicationCreateTxn(
      address,
      params,
      onComplete,
      approval_program,
      clear_program,
      localInts,
      localBytes,
      globalInts,
      globalBytes,
      appArgs,
      undefined,
      undefined,
      foreignAssets
    );
    txn.extraPages = 1;

    return txn;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to create contract creation transaction");
  }
}

export async function signAndSendGroupTransactions(
  provider,
  providerType,
  address,
  txns,
  optInAppId = null
) {
  try {
    // Check if they need to opt in to application
    if (optInAppId) {
      const accountInfo = await algodClient.accountInformation(address).do();
      if (
        !accountInfo["apps-local-state"].some((app) => app.id === optInAppId)
      ) {
        const suggestedParams = await algodClient.getTransactionParams().do();
        txns = [
          algosdk.makeApplicationOptInTxn(
            address,
            suggestedParams,
            Number(optInAppId)
          ),
          ...txns,
        ];
      }
    }

    if (providerType === MY_ALGO_CONNECT) {
      const txnsArray = txns;
      algosdk.assignGroupID(txns);
      const groupedTxns = txnsArray.map((txn) => ({
        txn: Buffer.from(txn.toByte()).toString("base64"),
      }));
      const tempSignedTxn = await provider.signTxns(groupedTxns);
      const signedTxn = tempSignedTxn.map(
        (s) => new Uint8Array(Buffer.from(s, "base64"))
      );

      const txnIDS = [];

      signedTxn.forEach((txn) => {
        const tmptxn = algosdk.decodeSignedTransaction(txn);
        txnIDS.push(tmptxn.txn.txID().toString());
      });

      const { txId } = await algodClient.sendRawTransaction(signedTxn).do();
      const result = await waitForConfirmation(algodClient, txId, 4);

      if (result) return { status: true, txId: txnIDS };
      return { status: false, txId: txnIDS };
    } else if (providerType === PERA_WALLET) {
      algosdk.assignGroupID(txns);
      const multipleTxnGroups = txns.map((rawTxn) => ({
        txn: rawTxn,
        signers: [address],
      }));
      const signedTxns = await provider.signTransaction([multipleTxnGroups]);
      const txnIDS = [];
      signedTxns.forEach((txn) => {
        const tmptxn = algosdk.decodeSignedTransaction(txn);
        txnIDS.push(tmptxn.txn.txID().toString());
      });

      const { txId } = await algodClient.sendRawTransaction(signedTxns).do();
      await waitForConfirmation(algodClient, txId, 4);

      return { status: true, txId: txnIDS };
    }
    return { status: false, txId: [] };
  } catch (error) {
    console.error(error);
    throw new Error("Unable to sign and send the transaction");
  }
}

export function convertToUint8Array(text) {
  const utf8 = decodeURI(encodeURIComponent(text));
  const array = new Uint8Array(
    utf8.split("").map(function (item) {
      return item.charCodeAt(0);
    })
  );
  return array;
}

export async function signAndSendSingleTransaction(
  txn,
  address,
  provider,
  providerType
) {
  try {
    if (providerType === MY_ALGO_CONNECT) {
      const signedTxn = await provider.signTransaction(
        Buffer.from(txn.toByte()).toString("base64")
      );
      const { txId } = await algodClient
        .sendRawTransaction(signedTxn.blob)
        .do();

      const result = await waitForConfirmation(algodClient, txId, 4);
      if (result) return { status: true, txId: txId };
      return { status: false, txId: txId };
    } else if (providerType === PERA_WALLET) {
      const singleTxnGroups = [{ txn: txn, signers: [address] }];
      const signedTxn = await provider.signTransaction([singleTxnGroups]);
      const { txId } = await algodClient.sendRawTransaction(signedTxn).do();

      const result = await waitForConfirmation(algodClient, txId, 4);
      if (result) return { status: true, txId: txId };
      return { status: false, txId: txId };
    } else {
      throw new Error("Failed to pick a wallet provider");
    }
  } catch (error) {
    console.error(error);
    throw new Error("unable to send the transaction");
  }
}

export async function transfer(
  from,
  to,
  amount,
  asaId,
  note,
  provider,
  providerType
) {
  try {
    const txn = await generateTransferTransaction(
      from,
      to,
      amount,
      note,
      asaId
    );
    const result = await signAndSendSingleTransaction(
      txn,
      from,
      provider,
      providerType
    );
    return result.status;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function acceptToken(address, asaId, provider, providerType) {
  try {
    if (asaId === 0) return true;
    const accountInfo = await algodClient.accountInformation(address).do();
    const asset = accountInfo.assets.filter((a) => a["asset-id"] === asaId);
    if (asset.length === 0) {
      const opted = await transfer(
        address,
        address,
        0,
        asaId,
        "",
        provider,
        providerType
      );
      if (opted) return true;
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function tokenAccepted(address, asaId) {
  try {
    const accountInfo = await algodClient.accountInformation(address).do();
    const asset = accountInfo.assets.filter((a) => a["asset-id"] === asaId);
    return asset.length > 0;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getBalance(address, asaId = 0) {
  try {
    if (!address || !asaId) return 0;
    if (asaId === 0) {
      const accountInfo = await algodClient.accountInformation(address).do();
      if (accountInfo.amount < accountInfo["min-balance"]) {
        return 0;
      } else if (
        accountInfo.amount - accountInfo["min-balance"] >
        MAX_MICRO_ALGO_VALUE
      ) {
        return (accountInfo.amount - accountInfo["min-balance"]) / MICRO_ALGO;
      } else {
        return microalgosToAlgos(
          accountInfo.amount - accountInfo["min-balance"]
        );
      }
    } else {
      const assetInfo = await algodClient
        .accountAssetInformation(address, asaId)
        .do();
      if (!assetInfo.message) {
        if (assetInfo["asset-holding"].amount > MAX_MICRO_ALGO_VALUE) {
          return assetInfo["asset-holding"].amount / MICRO_ALGO;
        } else {
          return microalgosToAlgos(assetInfo["asset-holding"].amount);
        }
      }
      return 0;
    }
  } catch (error) {
    if (error.toString().includes('account asset info not found')) {
      return 0;
    }
    console.error(error);
    throw new Error("Failed to get algorand balance.");
  }
}

export async function GetAccountDetails(userAddress) {
  return await algodClient.accountInformation(userAddress).do();
}

const compileProgram = async (programSource) => {
  const encoder = new TextEncoder();
  const programBytes = encoder.encode(programSource);
  const compileResponse = await algodClient.compile(programBytes).sourcemap(true).do();
  const compiledBytes = new Uint8Array(Buffer.from(compileResponse.result, "base64"));
  return compiledBytes;
};

async function fetchTealContent(path) {
  const response = await fetch(path);
  const content = await response.text();
  return content;
}