import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { /*Button,*/ Modal } from "react-bootstrap";

function TxnProcessModal(props) {
  const { adminTxnState, dispatch } = props;

  const onClose = () => {
    if (adminTxnState.isConfirmed) {
      dispatch({ type: "FINISH" });
    } else {
      dispatch({ type: "PROCESSING", payload: false });
    }
  };

  return (
    <Modal show={adminTxnState.isProcessing} onHide={() => onClose()} centered>
      <Modal.Header
        closeButton={adminTxnState.isConfirmed}
        className='border-0'
      >
        {/* <Modal.Title>DBD Stake to Earn</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className='pt-3 pb-4'>
        {adminTxnState.isConfirmed ? (
          <>
            <div className='d-flex justify-content-center'>
              <FontAwesomeIcon
                icon={faCheckCircle}
                className='text-success stakeModalIcon'
              />
            </div>
            <div className='mt-3 text-center' style={{ fontSize: "20px" }}>
              Transaction Submitted
            </div>
            <div
              className='text-primary text-center'
              style={{ fontSize: "14px" }}
            >
              <a
                href={
                  process.env.REACT_APP_BLOCKEXPLOERURL + "/tx/" + adminTxnState.txnHash
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                View on Block Explorer
              </a>
            </div>
          </>
        ) : (
          <>
            <div className='d-flex justify-content-center'>
              <div
                className='spinner-border text-danger my-2 stakeModalIcon'
                role='status'
              >
                <span className='sr-only'>Waiting for Comfirmation</span>
              </div>
            </div>
            <div className='mt-3 text-center' style={{ fontSize: "20px" }}>
              Waiting for Comfirmation
            </div>
            <div
              className='text-muted text-center'
              style={{ fontSize: "12px" }}
            >
              Confirm this transaction in your wallet
            </div>
          </>
        )}
      </Modal.Body>
      {/* <Modal.Footer className='d-flex justify-content-center'>
        <Button
          className='w-50 rounded bg-primary-color'
          onClick={() => onClose()}
        >
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default TxnProcessModal;
