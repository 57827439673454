import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { /*Button,*/ Modal } from "react-bootstrap";

function StakeModal(props) {
  const { stakeState, dispatch, from } = props;

  const onClose = () => {
    if (stakeState.isConfirmed) {
      dispatch({ type: "FINISH" });
    } else {
      dispatch({ type: "STAKING", payload: false });
    }
  };
  return (
    <Modal show={stakeState.isStaking} onHide={() => onClose()} centered>
      <Modal.Header closeButton={stakeState.isConfirmed} className="border-0 p-0">
        {/* <Modal.Title>DBD Stake to Earn</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="py-5 px-4">
        {stakeState.isConfirmed ? (
          <>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success stakeModalIcon"
              />
            </div>
            <div className="mt-3 text-center" style={{fontSize: "20px"}}>
              {from === "algo"
                ? "Transaction Successful"
                : "Transaction Submitted"}
            </div>
            {stakeState.stakeTxn && (
              <div
                className="text-primary text-center"
                style={{ fontSize: "14px" }}
              >
                <a
                  href={
                    process.env.REACT_APP_BLOCKEXPLOERURL + "/tx/" + stakeState.stakeTxn
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Block Explorer
                </a>
              </div>
            )}
            <div
              className="text-muted text-center mt-1"
              style={{ fontSize: "12px" }}
            >
              {from === "algo"
                ? "Your transaction is successful."
                : "You will be notified when the transaction is successful."}
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border text-danger my-2 stakeModalIcon"
                role="status"
              >
                <span className="sr-only">
                  {from === "algo"
                    ? "Waiting for Signature and Algorand Confirmation"
                    : "Waiting for Comfirmation"}
                </span>
              </div>
            </div>
            <h5 className="mt-3 text-center">
              {from === "algo"
                ? "Waiting for Signature and Algorand Confirmation"
                : "Waiting for Comfirmation"}
            </h5>
            <p
              className="text-center"
            >
              {from === "algo"
                ? "Sign this transaction in your wallet, then wait for the algorand confirmation"
                : "Confirm this transaction in your wallet"}
            </p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default StakeModal;
