import React, { useState, useEffect, useContext } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// hooks
import { useWeb3 } from "hooks/useWeb3";
import { useContracts } from "hooks/useContracts";
// contex
import { AuthContext } from "context/authContext";
// utils
import moment from "moment";
import {
  getAllStakingTerms,
  getAllStakingTermsLength,
} from "services/contract.service";
import { durationFormatter, durationToDaysHours } from "utils/format";

const RECORDS_PER_TIME = 20;

function StakingRecordsCard() {
  const [currentOffset, setCurrentOffset] = useState(0);
  const [allStakingTermsLength, setAllStakingTermsLength] = useState(0);
  const [tmpTerms, setTmpTerms] = useState([]);
  const [stakingRecords, setStakingRecords] = useState([]);
  const [totalStakeAmount, setTotalStakeAmount] = useState(0);
  const [last, setLast] = useState(false);

  const web3 = useWeb3();
  const [, stakingContract] = useContracts();

  const [noDataText, setNoDataText] = useState();
  const [updateCount, setUpdateCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false);

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    async function getStakingTermsLength() {
      try {
        const tmp = await getAllStakingTermsLength(
          stakingContract,
          authState.accountPoly
        );
        setLast(tmp <= RECORDS_PER_TIME);
        setAllStakingTermsLength(tmp);
      } catch (err) {
        console.error(err);
      }
    }
    async function getInitRecords() {
      try {
        const terms = await getAllStakingTerms(
          stakingContract,
          0,
          authState.accountPoly
        );
        setTmpTerms(terms);
        setUpdated(true);
      } catch (err) {
        console.error(err);
      }
    }
    if (stakingContract && authState.accountPoly && loading) {
      getStakingTermsLength();
      getInitRecords();
      setLoading(false);
    }
  }, [stakingContract, authState.accountPoly, loading]);

  useEffect(() => {
    let tempTotalStakeAmount = totalStakeAmount;
    if (web3 && updated) {
      const tempStakingRecords = tmpTerms
        ? tmpTerms.map((record) => {
            // Setup records details (may refine in the future)
            const duration = moment().diff(
              moment.unix(record.contractStart),
              "seconds"
            );
            const timeDiff = `${durationToDaysHours(duration)}`;
            const stakedStart = moment
              .unix(record.contractStart)
              .format("DD-MMM-YYYY HH:mm");
            const stakedEnd =
              record.contractDuration === "0"
                ? "Anytime"
                : moment
                    .unix(record.contractStart)
                    .add(record.contractDuration, "seconds")
                    .format("DD-MMM-YYYY HH:mm");
            let contractDuration = "N/A";
            if (record.contractDuration === "0") {
              contractDuration = "Flexible";
            } else {
              contractDuration = `${durationFormatter(
                record.contractDuration
              )} Days`;
            }
            const stakeAmount = web3.utils.fromWei(
              record.stakeAmount.toString()
            );

            tempTotalStakeAmount += Number(stakeAmount);
            return {
              contractId: record.contractId,
              walletAddress: record.stakerAddress,
              amount: stakeAmount,
              contractDuration: contractDuration,
              timeStaked: timeDiff,
              stakedStartDate: stakedStart,
              stakedEndDate: stakedEnd,
              status: record.isWithdrawn ? "Withdrawn" : "Staking",
            };
          })
        : [];
      setTotalStakeAmount(tempTotalStakeAmount);
      const appendedRecords = [...stakingRecords, ...tempStakingRecords];
      setStakingRecords(appendedRecords);
      setUpdated(false);
    }
  }, [tmpTerms, web3, totalStakeAmount, stakingRecords, updated]);

  const options = {
    noDataText: noDataText,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10,
  };

  useEffect(() => {
    if (stakingRecords) {
      if (updateCount === 0) {
        const tmpCount = updateCount + 1;
        setUpdateCount(tmpCount);
        setNoDataText("Table data is still being loaded. Please wait...");
      } else if (updateCount === 1 && stakingRecords.length === 0) {
        setNoDataText("There is no data to display");
      }
    }
  }, [stakingRecords, updateCount]);

  const getRecords = async (nextOffset) => {
    try {
      const terms = await getAllStakingTerms(
        stakingContract,
        nextOffset,
        authState.accountPoly
      );
      setTmpTerms(terms);
      setUpdated(true);
    } catch (err) {
      console.error(err);
    }
  };

  const loadMoreRecords = async () => {
    const nextOffset = currentOffset + 1;
    if (allStakingTermsLength >= nextOffset * RECORDS_PER_TIME) {
      await getRecords(nextOffset);
      setCurrentOffset(nextOffset);
    }
    if (allStakingTermsLength < (nextOffset + 1) * RECORDS_PER_TIME) {
      setLast(true);
    }
  };

  const CellFormatter = (cell, row) => {
    let link =
      process.env.REACT_APP_BLOCKEXPLOERURL +
      "/token/" +
      process.env.REACT_APP_TOKENADDRESS +
      "?a=" +
      cell;

    return (
      <div>
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {cell}
        </a>
      </div>
    );
  };

  return (
    <div className="card my-4 mx-auto p-2 scroll-box">
      <div className="d-flex justify-content-between align-items-center">
        <div className="my-3 px-3 h2">Current Staking:</div>
        <button
          disabled={last}
          className="btn w-25 bg-primary-color text-white rounded-pill mr-3"
          onClick={() => loadMoreRecords()}
        >
          {last ? "Finish Loading Records" : "Load More"}
        </button>
      </div>
      <div className="h6 pl-4">
        <b>{stakingRecords.length}</b> out of <b>{allStakingTermsLength}</b>
        &nbsp; Terms Loaded
      </div>

      <div>
        <BootstrapTable
          data={stakingRecords}
          bordered={false}
          hover
          options={options}
          pagination
          className="mb-3"
        >
          <TableHeaderColumn
            dataField="contractId"
            width="120"
            dataAlign="center"
            isKey
          >
            Contract ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="walletAddress"
            width="250"
            dataAlign="center"
            dataFormat={CellFormatter}
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Wallet Address
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="amount"
            dataAlign="center"
            width="120"
            thStyle={{ whiteSpace: "normal" }}
          >
            Staking Amount
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="contractDuration"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Contract Duration
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="timeStaked"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Time Staked
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="stakedStartDate"
            dataAlign="center"
            width="150"
            tdStyle={{ whiteSpace: "normal" }}
          >
            Start Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="stakedEndDate"
            dataAlign="center"
            width="200"
            tdStyle={{ whiteSpace: "normal" }}
          >
            Contract End Date
          </TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataAlign="center" width="120">
            status
          </TableHeaderColumn>
        </BootstrapTable>
      </div>

      <div className="d-flex justify-content-between align-items-center px-2">
        <div className="text-black font-weight-bold">
          Loaded Staking Terms: {stakingRecords.length}
        </div>

        <div className="text-black font-weight-bold">
          Loaded Stake Amount: {totalStakeAmount}&nbsp;
          {process.env.REACT_APP_TOKENSYMBOL}
        </div>
      </div>
    </div>
  );
}

export default StakingRecordsCard;
