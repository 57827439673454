import { useState, useEffect } from "react";
// web3
import Web3 from "web3";

export function useWeb3() {
  const [publicWeb3, setPublicWeb3] = useState();

  useEffect(() => {
    async function loadPublilcWeb3() {
      try {
        const web3js = new Web3(process.env.REACT_APP_RPCURL);
        setPublicWeb3(web3js);
      } catch (err) {
        console.error(err.message);
      }
    }
    loadPublilcWeb3();
  }, []);

  return publicWeb3;
}
