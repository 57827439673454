import React, { useState, useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import AlgoGeneralSettingsCard from "./AlgoGeneralSettingsCard";

// utils
import { toast } from "react-toastify";

function AlgoContractRecords(props) {
  const { durationData } = props;

  const [contractRecords, setContractRecords] = useState();
  const { noDataText } = useState("Table is loading");

  const [isShownEditModal, setIsShownEditModal] = useState(false);
  const [editRowSelected, setEditRowSelected] = useState(false);

  const options = {
    noDataText: noDataText,
  };

  useEffect(() => {
    async function getDurationTerms() {
      try {
        const tempcontractRecords = durationData.map((contract, index) => {
          return {
            Id: index,
            AppId: contract.AppId,
            AppAddress: contract.AppAddress,
            StakeTokenCreator: contract.StakeTokenCreator,
            RewardASA: contract.RewardASA,
            APY: contract.APY,
            Duration: contract.Duration,
            Paused: contract.Paused,
            DateCreated: contract.DateCreated,
          };
        });
        setContractRecords(tempcontractRecords);
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      }
    }

    if (durationData) {
      getDurationTerms();
    }
  }, [durationData]);

  const editButton = (cell, row) => (
    <button
      style={{ whiteSpace: "nowrap" }}
      className="btn bg-primary-color text-white rounded-pill"
      onClick={() => {
        setEditRowSelected(row);
        setIsShownEditModal(true);
      }}
    >
      Edit
    </button>
  );

  return (
    <div className="card mb-4 mt-4 mx-auto scroll-box card-width-extended pl-4 pt-4">
      <h2>Current Contracts:</h2>
      <div className="my-3">
        <BootstrapTable
          data={contractRecords}
          hover
          bordered={false}
          options={options}
        >
          <TableHeaderColumn
            isKey
            dataField="Id"
            dataAlign="center"
            editable={false}
            width="50"
            thStyle={{ whiteSpace: "normal" }}
          >
            i
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="AppId"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            AppId
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="StakeTokenCreator"
            dataAlign="center"
            width="250"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Stake Token Creator
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="RewardASA"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Reward ASA
          </TableHeaderColumn>
          <TableHeaderColumn dataField="APY" dataAlign="center" width="90">
            APY (%)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Duration"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Duration
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Paused"
            dataAlign="center"
            width="90"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Paused
          </TableHeaderColumn>
          <TableHeaderColumn
            width="90"
            dataFormat={editButton}
          ></TableHeaderColumn>
        </BootstrapTable>
      </div>
      
      {isShownEditModal && (<AlgoGeneralSettingsCard contractInfo={editRowSelected}/>)}

    </div>
  );
}

export default AlgoContractRecords;
