export function addressFormatter(address) {
  if (!address) return;
  const formattedAddress = `${address.substring(0, 6)}...${address.slice(
    address.length - 4
  )}`;
  return formattedAddress;
}

// seconds to days
export function durationFormatter(duration) {
  if (!duration) return undefined;
  const formattedDuration = duration / 60 / 60 / 24;
  return formattedDuration;
}

// seconds duration to days and hours
export function durationToDaysHours(duration) {
  if (!duration) return undefined;
  let minutes = duration / 60;
  let hours = minutes / 60;
  const days = Math.floor(hours / 24);
  hours = Math.floor(hours % 24);
  let dayText, hourText;
  if (days < 1) {
    dayText = "";
  } else if (days === 1) {
    dayText = `${days} Day`;
  } else {
    dayText = `${days} Days`;
  }
  if (hours < 1) {
    hourText = "";
  } else if (hours === 1) {
    hourText = `${hours} Hour`;
  } else {
    hourText = `${hours} Hours`;
  }
  if (dayText === "") {
    if (hourText === "") {
      minutes = Math.floor(minutes);
      const minutesText =
        minutes < 2 ? `${minutes} Minute` : `${minutes} Minutes`;
      return minutesText;
    } else {
      return hourText;
    }
  } else {
    return `${dayText} ${hourText}`;
  }
}
