import React from "react";

function StakingInfoBar({ title, paused, staked, apy, poolLimit, tokenName, isNFTStaking, rewardTokenName }) {
  return (
    <div className="row stake-info">
        <div className="col-12 pb-3 d-flex align-items-center">
          <h5 className="mb-0 pr-3">{title}</h5>
          {!paused ? (
            <span className="badge badge-pill badge-success px-2">
              <i className="fas fa-circle fa-xs mr-1"></i>Active
            </span>
          ) : (
            <span className="badge badge-pill badge-warning px-2">
              <i className="fas fa-pause fa-xs mr-1"></i>Paused
            </span>
          )}
        </div>

      <div className="col-12">
        <div className="row slim-gutter">
          <div className="col-auto">
            <label className="text-secondary mb-1">{tokenName} Staked</label>
            <h5 className="stake-info mb-0">{staked}</h5>
          </div>

          <div className="col text-right">
            <label className="text-secondary mb-1">{isNFTStaking ? 'Rewards per NFT' : 'APY'}</label>
            <h5 className="stake-info mb-0">{apy} {isNFTStaking ? rewardTokenName : '%'}</h5>
          </div>

          <div className="col text-right">
            <label className="text-secondary mb-1">Staking Pool Limit</label>
            <h5 className="stake-info mb-0">{poolLimit}</h5>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default StakingInfoBar;
