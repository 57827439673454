import React, { useContext, useState } from "react";
// components
import { Modal } from "react-bootstrap";
// context
import { AuthContext } from "context/authContext";
// utils
import { connectAlgoWallet, ConnectWallet } from "services/auth.service";
import { checkSessionExists } from "@jackcom/reachduck";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { AlgoProviderContext } from "../../context/algoProviderContext";
import AlgoLogo from "assets/algorand_full_logo_black.svg";
import PeraWalletLogo from "assets/button-pera-connect.svg";

function AlgoConnect(props) {
  const { isShown, setIsShown } = props;
  const { algoProviderState } = useContext(AlgoProviderContext);

  const location = useLocation();

  const { signinAlgo } = useContext(AuthContext);

  const [isSelectedWalletConnect, setIsSelectedWalletConnect] = useState(false);

  const connect = async (plat) => {
    try {
      if (location.pathname.includes("legacy"))
      {
        const { exists } = checkSessionExists();
        if (isSelectedWalletConnect && !exists) {
          window.location.reload();
        } else {
          if (plat === "WalletConnect") {
            setIsSelectedWalletConnect(true);
          }
        }
        await connectAlgoWallet(plat, signinAlgo);
      } else 
      {
        await ConnectWallet(signinAlgo, algoProviderState.provider);
      }

      setIsShown(false);
    } catch (err) {
      console.error(err);
      toast.error("Fail to connect your Algo account.");
      setIsShown(false);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      window.location.reload();
    }
  };

  return (
    <Modal show={isShown} onHide={() => setIsShown(false)} centered>
      <Modal.Header closeButton className="d-flex align-items-center border-bottom-0 pb-0 pt-2">
      </Modal.Header>
      <Modal.Body className="pt-1 px-sm-5 px-4">
      <div className="row mb-3">
          <div className="col-12 pb-3 text-center">
              <h5 className="connect-title mb-3">Connect with</h5>
              <img className="network-logo chain-normal-logo" src={AlgoLogo} />
          </div>
      </div>
        
      <div className="row mb-4 slim-gutter justify-content-center">

        <div hidden={!location.pathname.includes("legacy")} className="col-6 text-center">
          <button
            className="btn connectWallet-btn"
            onClick={() => connect("MyAlgo")}
          >
            <label>MyALGO Wallet</label>
          </button>
        </div>

        <div className="col-6 text-center" hidden={location.pathname.includes("legacy")}>
          <button
            className="btn connectWallet-btn"
            onClick={() => connect("WalletConnect")}
          >
            <img className="wallet-label" src={PeraWalletLogo} />
            <label>Pera Wallet</label>
          </button>
        </div>
      </div>
        
      </Modal.Body>
    </Modal>
  );
}

export default AlgoConnect;
