import React, { useContext, useEffect, useState } from "react";
// Components and graphics
import UseCase from "components/layout/UseCase";
import StakingInfo from "components/StakingInfo";
import Loading from "components/Loading";
import StakeCard from "components/main/stake/StakeCard";
import ActiveRecordsCard from "components/main/records/ActiveRecordsCard";
import WithdrawnRecordsCard from "components/main/records/WithdrawnRecordsCard";
// context
import { AuthContext } from "context/authContext";
import { EthProviderContext } from "context/ethProviderContext";
// utils & constants
import { loadStatus } from "utils/constants";
import { addERC20Token, getDurationTerms } from "services/contract.service";
// hooks
import { useWeb3 } from "hooks/useWeb3";
import {
  useContracts,
  useStakingData,
  useStakingRecords,
} from "hooks/useContracts";
import Web3 from "web3";
import { addPolygonNetwork, subscribeProvider } from "services/auth.service";
// images
import metamaskIcon from "assets/MetaMask_Fox.svg.png";
import { StakingDataContext } from "context/stakingDataContext";
import StakingTitleBar from "../components/layout/StakingTitleBar";
import detectEthereumProvider from "@metamask/detect-provider";

function PolygonPage(props) {
  const { location } = props;

  const [loading, setLoading] = useState(loadStatus[0]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [limit, setLimit] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [firstLoading, setFirstLoading] = useState(true);

  const [totalStaked, setTotalStaked] = useState(0);
  const [maxStakingPool, setMaxStakingPool] = useState(0);

  const [durationData, setDurationData] = useState([]);

  const { ethProviderState, connectProvider, disconnectProvider } =
    useContext(EthProviderContext);
  const { authState, updateAdminPoly } = useContext(AuthContext);
  const { getData, update } = useContext(StakingDataContext);

  const web3 = useWeb3();
  const [tokenContract, stakingContract] = useContracts();
  const stakingData = useStakingData(stakingContract, isUpdated);
  const [stakingRecords, withdrawnRecords] = useStakingRecords(
    ethProviderState.provider,
    authState.accountPoly,
    isUpdated
  );

  const { signinPoly, signoutPoly } = useContext(AuthContext);

  useEffect(() => {
    async function loadEthereum() {
      try {
        let provider;
        if (ethProviderState.walletConnectProvider?.accounts?.length > 0) {
          provider = ethProviderState.walletConnectProvider;
        } else {
          const metmaskProvider = await detectEthereumProvider();
          if (metmaskProvider) {
            const accounts = await metmaskProvider.request({
              method: "eth_accounts",
            });
            if (accounts.length > 0) {
              provider = metmaskProvider;
            }
          }
        }
        if (provider) {
          connectProvider(provider);
          if (!authState.subscribed) {
            await subscribeProvider(
              provider,
              disconnectProvider,
              signinPoly,
              signoutPoly
            );
          }
          await provider.enable();
          const chainId = await provider.request({
            method: "eth_chainId",
          });
          await addPolygonNetwork(chainId, provider);
          const web3 = new Web3(provider);
          const accounts = await web3.eth.getAccounts();
          if (accounts.length > 0) {
            signinPoly(accounts[0]);
          }
        }
      } catch (err) {
        console.error(err);
        // toast.error(err.message);
      }
    }
    if (
      firstLoading &&
      !ethProviderState.provider &&
      ethProviderState.walletConnectProvider
    ) {
      loadEthereum();
    }
  }, [
    connectProvider,
    ethProviderState,
    disconnectProvider,
    signinPoly,
    signoutPoly,
    firstLoading,
    authState.subscribed,
  ]);

  useEffect(() => {
    async function getDurationData() {
      try {
        if (stakingContract) {
          const durationData = await getDurationTerms(stakingContract);
          const alteredData = durationData.map((d, i) => {
            const newData = { durationIndex: i, ...d };
            return newData;
          });
          setDurationData(alteredData);
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (stakingContract && loading !== loadStatus[loadStatus.length - 1]) {
      getDurationData();
    }
  }, [stakingContract, loading]);

  useEffect(() => {
    if (firstLoading && durationData.length > 0 && stakingData.owner) {
      const stakingDataState = {
        ...stakingData,
        durationTerms: durationData,
      };
      getData(stakingDataState);
      setFirstLoading(false);
      setLoading(loadStatus[loadStatus.length - 1]);
    }
  }, [getData, stakingData, firstLoading, stakingContract, durationData]);

  useEffect(() => {
    if (isStateUpdated) {
      update(stakingData);
      setIsStateUpdated(false);
    }
  }, [isStateUpdated, stakingData, update]);

  useEffect(() => {
    if (stakingData.owner) {
      updateAdminPoly(authState.accountPoly === stakingData.owner);
    }
  }, [stakingData.owner, authState.accountPoly, updateAdminPoly]);

  useEffect(() => {
    if (stakingData && web3) {
      const maxPerStakeAmount = web3.utils.fromWei(
        stakingData.maxPerStakeAmount.toString()
      );
      setTotalStaked(
        Math.round(
          Number(web3.utils.fromWei(stakingData.currentPoolBalance.toString()))
        )
      );
      setMaxStakingPool(
        Math.round(
          Number(web3.utils.fromWei(stakingData.maxPoolBalance.toString()))
        )
      );
      setLimit(maxPerStakeAmount);
    }
  }, [stakingData, web3]);

  useEffect(() => {
    async function getTokenBalance() {
      if (authState.accountPoly) {
        setTokenBalance(
          await tokenContract.methods.balanceOf(authState.accountPoly).call()
        );
      } else {
        setTokenBalance(0);
      }
    }
    if (tokenContract && stakingContract) {
      getTokenBalance();
    }
  }, [stakingContract, tokenContract, authState.accountPoly, isUpdated]);

  const addTokentoMetaMask = async (address, symbol, imageURL) => {
    setLoading(loadStatus[12]);
    await addERC20Token(ethProviderState.provider, address, symbol, imageURL);
    setLoading(loadStatus[loadStatus.length - 1]);
  };

  const toggleUpdated = () => {
    setIsUpdated(!isUpdated);
    setIsStateUpdated(true);
  };

  return (
    <>
      <StakingTitleBar
        location={location}
        isAdmin={authState.isAdminPoly}
        totalStaked={totalStaked}
        maxStakingPool={maxStakingPool}
      />
      <div id="content">
        <div className="container mb-5">
          <StakeCard
            tokenBalance={tokenBalance}
            toggleUpdated={toggleUpdated}
          />

          <div className="container d-flex flex-column align-items-center mb-3 stakeFooter">
            {ethProviderState.provider && (
              <div
                className="row mt-2 slim-gutter justify-content-center mx-auto w-100"
                style={{ maxWidth: "570px" }}
              >
                <div className="col-md-6 col-12">
                  <button
                    className="btn addToMetamask"
                    onClick={() =>
                      addTokentoMetaMask(
                        process.env.REACT_APP_TOKENADDRESS,
                        process.env.REACT_APP_TOKENSYMBOL,
                        "https://i.imgur.com/zMvb3Wd.png"
                      )
                    }
                  >
                    Add DBD Token
                    <img src={metamaskIcon} width="35" alt="" />
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
                <div className="col-md-6 col-12 mt-md-0 mt-2">
                  <button
                    className="btn addToMetamask"
                    onClick={() =>
                      addTokentoMetaMask(
                        process.env.REACT_APP_STAKINGADDRESS,
                        "stDBD",
                        "https://i.imgur.com/kR8kYX2.png"
                      )
                    }
                  >
                    Add stDBD Token
                    <img src={metamaskIcon} width="35" alt="" />
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            )}

            <StakingInfo
              min={
                web3
                  ? web3.utils.fromWei(stakingData.minPerStakeAmount.toString())
                  : 0
              }
              limit={limit}
            />
          </div>
        </div>

        {authState.accountPoly && (
          <div className="container-fluid px-3">
            <ActiveRecordsCard
              stakingRecords={stakingRecords}
              toggleUpdated={toggleUpdated}
            />
            <WithdrawnRecordsCard withdrawnRecords={withdrawnRecords} />
          </div>
        )}

      </div>

      <Loading loadingStatus={loading} />
    </>
  );
}

export default PolygonPage;
