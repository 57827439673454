export const initStakeState = {
  isStaking: false,
  isApproving: false,
  isApproved: false,
  isConfirmed: false,
  stakeTxn: "",
  type: "",
};

export function stakeReducer(state, action) {
  switch (action.type) {
    case "STAKING":
      return { ...state, isStaking: action.payload, type: "staking" };
    case "APPROVING":
      return { ...state, isApproving: true, type: "approving" };
    case "APPROVED":
      return {
        ...state,
        isApproving: false,
        isApproved: true,
        type: "approved",
      };
    case "CONFIRM":
      return {
        ...state,
        isStaking: true,
        isConfirmed: true,
        stakeTxn: action.payload,
        type: "confirmed",
      };
    case "FINISH":
      return {
        ...state,
        isApproving: false,
        isStaking: false,
        isConfirmed: false,
        stakeTxn: "",
        type: "finish",
      };
    case "ERROR":
      return {
        ...state,
        isApproving: false,
        isStaking: false,
        isConfirmed: false,
        stakeTxn: "",
        type: "error",
      };
    case "RESET":
      return initStakeState;
    default:
  }
}
