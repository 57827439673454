import { useState, useEffect } from "react";
// abis
import DBDToken from "abis/DBD.json";
import STWToken from "abis/STW.json";
import Staking from "abis/Staking.json";
// utils
import { useWeb3 } from "./useWeb3";
import { toast } from "react-toastify";
// web3
import Web3 from "web3";

export function useContracts() {
  const [contractAbis, setContractAbis] = useState();
  const [tokenContract, setTokenContract] = useState();
  const [stakingContract, setStakingContract] = useState();

  const web3 = useWeb3();

  useEffect(() => {
    async function loadContractAbis() {
      let tempContractAbis = {};
      // Load Token Abi
      const dbdTokenData =
        process.env.REACT_APP_MODE === "production"
          ? DBDToken.networks[parseInt(process.env.REACT_APP_CHAINID, 16)]
          : STWToken.networks[parseInt(process.env.REACT_APP_CHAINID, 16)];
      if (dbdTokenData) {
        const dbdTokenAbi =
          process.env.REACT_APP_MODE === "production"
            ? DBDToken.abi
            : STWToken.abi;
        tempContractAbis = {
          token: {
            abi: dbdTokenAbi,
            address: process.env.REACT_APP_TOKENADDRESS,
          },
        };
      } else {
        toast.error("DBDToken contract not deployed to detected network.");
      }

      // Load Staking Abi
      const stakeData =
        Staking.networks[parseInt(process.env.REACT_APP_CHAINID, 16)];
      if (stakeData) {
        tempContractAbis = {
          ...tempContractAbis,
          staking: {
            abi: Staking.abi,
            address: process.env.REACT_APP_STAKINGADDRESS,
          },
        };
      } else {
        toast.error("Stake contract not deployed to detected network.");
      }
      setContractAbis(tempContractAbis);
    }
    loadContractAbis();
  }, []);

  useEffect(() => {
    async function getContract() {
      const dbdToken = new web3.eth.Contract(
        contractAbis.token.abi,
        contractAbis.token.address
      );
      const staking = new web3.eth.Contract(
        contractAbis.staking.abi,
        contractAbis.staking.address
      );
      setTokenContract(dbdToken);
      setStakingContract(staking);
    }
    if (web3 && contractAbis) {
      getContract();
    }
  }, [web3, contractAbis]);

  return [tokenContract, stakingContract];
}

export function useStakingData(stakingContract, isUpdated) {
  const [owner, setOwner] = useState();
  const [isPaused, setIsPaused] = useState(false);
  const [rewardPoolBalance, setRewardPoolBalance] = useState(0);
  const [currentPoolBalance, setCurrentPoolBalance] = useState(0);
  const [maxPoolBalance, setMaxPoolBalance] = useState(0);
  const [maxPerStakeAmount, setMaxPerStakeAmount] = useState(0);
  const [minPerStakeAmount, setMinPerStakeAmount] = useState(0);
  const [earlyUnstakeApy, setEarlyUnstakeApy] = useState(0);
  const [extraPeriodApy, setExtraPeriodApy] = useState(0);
  const [updated, setUpdated] = useState(false);

  useEffect(() => setUpdated(true), [isUpdated]);

  useEffect(() => {
    async function getStakingData() {
      try {
        setIsPaused(await stakingContract.methods.isPaused().call());
        setRewardPoolBalance(
          await stakingContract.methods.rewardPoolBalance().call()
        );
        setCurrentPoolBalance(
          await stakingContract.methods.currentPoolBalance().call()
        );
        setMaxPoolBalance(
          await stakingContract.methods.maxPoolBalance().call()
        );
        setMaxPerStakeAmount(
          await stakingContract.methods.maxPerStakeAmount().call()
        );
        setMinPerStakeAmount(
          await stakingContract.methods.minPerStakeAmount().call()
        );
        setEarlyUnstakeApy(
          await stakingContract.methods.earlyUnstakeAPY().call()
        );
        setExtraPeriodApy(
          await stakingContract.methods.extraPeriodAPY().call()
        );
        setOwner(await stakingContract.methods.owner().call());
      } catch (err) {
        console.error(err);
        toast.error("Failed to retrieve the staking data");
      }
    }
    if (stakingContract && updated) {
      getStakingData();
      setUpdated(false);
    }
  }, [stakingContract, updated]);

  const stakingData = {
    owner: owner,
    isPaused: isPaused,
    rewardPoolBalance: rewardPoolBalance,
    currentPoolBalance: currentPoolBalance,
    maxPoolBalance: maxPoolBalance,
    maxPerStakeAmount: maxPerStakeAmount,
    minPerStakeAmount: minPerStakeAmount,
    earlyUnstakeApy: earlyUnstakeApy,
    extraPeriodApy: extraPeriodApy,
  };

  return stakingData;
}

export function useStakingRecords(provider, account, isUpdated) {
  const [stakingRecords, setStakingRecords] = useState([]);
  const [withdrawnRecords, setWithdrawnRecords] = useState([]);
  const [updated, setUpdated] = useState(false);

  useEffect(() => setUpdated(true), [isUpdated]);

  useEffect(() => {
    async function getStakingRecords() {
      try {
        const chainId = await provider.request({
          method: "eth_chainId",
        });
        const nativeChain = provider.accounts
          ? Number(process.env.REACT_APP_CHAINID)
          : process.env.REACT_APP_CHAINID;
        const web3 =
          chainId !== nativeChain
            ? new Web3(process.env.REACT_APP_PUBLIC_RPCURL)
            : new Web3(provider);
        const stakingContract = new web3.eth.Contract(
          Staking.abi,
          process.env.REACT_APP_STAKINGADDRESS
        );

        const tempActiveRecords = await stakingContract.methods
          .getUserStakingTerms()
          .call({ from: account });

        const tempWithdrawnRecords = await stakingContract.methods
          .getUserWithdrawnTerms()
          .call({ from: account });

        setStakingRecords(tempActiveRecords);
        setWithdrawnRecords(tempWithdrawnRecords);
      } catch (err) {
        console.error(err);
        toast.error("Failed to get the staking records");
      }
    }
    if (provider && account && updated) {
      getStakingRecords();
      setUpdated(false);
    }
  }, [account, updated, provider]);

  return [stakingRecords, withdrawnRecords];
}
