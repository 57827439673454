import detectEthereumProvider from "@metamask/detect-provider";
import { AuthContext } from "context/authContext";
import { EthProviderContext } from "context/ethProviderContext";
import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { connectAccount } from "services/auth.service";

import metaMaskFox from "assets/MetaMask_Fox.svg.png"
import walletConnect from "assets/walletconnect_icon.png"
import polygonIcon from "assets/PolygonLogo.svg"

function ConnectModal(props) {
  const { isShown, setIsShown } = props;

  const { authState, signinPoly, signoutPoly } = useContext(AuthContext);
  const { ethProviderState, disconnectProvider, connectProvider } =
    useContext(EthProviderContext);

  const onMetamask = async () => {
    try {
      const provider = await detectEthereumProvider();
      if (provider) {
        await connectAccount(
          provider,
          connectProvider,
          disconnectProvider,
          signinPoly,
          signoutPoly,
          authState.subscribed
        );
        toast.success("Successfully connect with Metamask");
      } else {
        toast.warn("Please install MetaMask first");
      }
      setIsShown(false);
    } catch (error) {
      toast.error(
        "Failed to connect with MetaMask, please try again or contact support"
      );
    }
  };

  const onWalletConnect = async () => {
    try {
      await connectAccount(
        ethProviderState.walletConnectProvider,
        connectProvider,
        disconnectProvider,
        signinPoly,
        signoutPoly,
        authState.subscribed
      );
      toast.success("Successfully connect with Wallet Connect");
      setIsShown(false);
    } catch (error) {
      toast.error(
        "Failed to connect with Wallet Connect, please try again or contact support"
      );
    }
  };

  return (
    <Modal show={isShown} onHide={() => setIsShown(false)} centered>
      <Modal.Header closeButton className="d-flex align-items-center border-bottom-0 pb-0 pt-2">
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-2">
        <div className="row mb-3">
          <div className="col-12 pb-3 text-center">
              <h5 className="connect-title mb-3">Connect with</h5>
              <img className="network-logo chain-normal-logo" src={polygonIcon} />
          </div>
      </div>
        
      <div className="row mb-2 slim-gutter justify-content-center">
        <div className="col-6 text-center">
            <button className="btn connectWallet-btn" onClick={() => onMetamask()}>
                <img className="wallet-label" src={metaMaskFox} />
                <label>Metamask</label>
            </button>

        </div>
        <div className="col-6 text-center">
            <button className="btn connectWallet-btn" onClick={onWalletConnect}>
                <img className="wallet-label" src={walletConnect} />
                <label>WalletConnect</label>
            </button>
        </div>
    </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConnectModal;
