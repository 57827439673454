import React, { useState, useContext, useEffect } from "react";
import CreateAlgoContract from "./algorand/CreateAlgoContract"
import AlgoContractRecords from "./algorand/AlgoContractRecords"
// context
import { AuthContext } from "context/authContext";
import { getAppliactionInfoById } from "services/contract.service";

function MultiContractCard() {
  const { authState } = useContext(AuthContext);

  const [contracts, setContracts] = useState([]);
  const [showCreateContract, setShowCreateContract] = useState(false);

  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    async function getContractList() {
      try {
        await fetch(process.env.REACT_APP_BASEURL + "Stake/getAll", {
          method: "GET",
        })
        .then((response) => response.json())
        .then(async (data) => {
          let contractsData = [];
          let i = 0;

          while (i < data.length) {
            let element = data[i];
          
            // Get blockchain data
            let contractData = await getAppliactionInfoById(Number(element.appId), element.applicationAddress, null);
          
            contractsData.push({
              AppId: element.appId,
              AppAddress: element.applicationAddress,
              StakeTokenCreator: contractData.stakeCreatorAddress,
              RewardASA: contractData.rewardASA,
              APY: contractData.durationApy,
              Duration: contractData.durationThreshold[0],
              Paused: element.paused,
              DateCreated: element.dateCreated,
            });
          
            i++;
          }
          setLoading(false);
          setContracts(contractsData);
        });
      } catch (err) {
        setContracts([]);
        setLoading(false);
        console.error(err);
      }
    }
    getContractList();
  }, []);

  return (
    <div className="card mb-4 mx-auto card-width-extended">
      <div className="text-center">
        <h2 className="genSettingsHeading">Algorand Contracts</h2>
        {loading && (
          <p>Loading. This may take a while</p>
        )}
      </div>
      {!authState.isAdminAlgo ? (
        <div className="text-center">
        </div>
      ) : (
        <div>
            <div className="d-flex justify-content-center mb-2">
              <button
                type="button"
                className="btn btn-lg  bg-primary-color text-white rounded-pill"
                onClick={() => setShowCreateContract(!showCreateContract)}
              >
                Create Contract
              </button>
            </div>

            {showCreateContract && (
              <div>
                <CreateAlgoContract />
              </div>
            )}

          <div>
              <AlgoContractRecords durationData={contracts}/>
          </div>

        </div>

      )}
    </div>
  );
}

export default MultiContractCard;
