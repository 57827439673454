import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PolicyImage from "components/main/PolicyImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function SelectNFTModal({ shown, setShown, setSelectedNFT, NFTs }) {
  const [NFT, selectNFT] = useState(0);

  const confirm = () => {
    setShown(false);
    setSelectedNFT(NFT);
  };

  const selectSpecificNFT = (item) => {
    selectNFT(item);
    NFTs.forEach(nft => {
      nft.selected = false;
    });
    item.selected = true;
  }

  return (
    <Modal show={shown} onHide={() => setShown(false)} size="xl" centered>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="h5 align-self-center">Select an NFT</Modal.Title>
      </Modal.Header>
      <Modal.Body className="nft-body">
        <div>
          {NFTs.length > 0 ? (
            <div className="row slim-gutter">
              {NFTs.map((item) => (
                <div key={item.id} className="col-xl-3 col-lg-4 col-sm-6 col-12 pb-3">
                  <div onClick={() => {selectSpecificNFT(item)}} className={`${item.selected ? "active-nft" : ""}`}>
                    <PolicyImage item={item}/>
                    <span className="selected justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faCheck}/>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="row">
              <div className="col-12 py-5 text-center">
                <p>You have no NFTs in your wallet</p>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button
          className="w-auto px-4 btn btn-light mr-2"
          onClick={() => setShown(false)}
        >
          Cancel
        </button>
        <button
          className="w-auto px-4 btn btn-secondary"
          onClick={() => confirm()}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectNFTModal;
