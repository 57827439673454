exports.loadStatus = ["Default", "AddToken", "NotLoading"];

exports.loadingMsg = [
  "Retrieving data from smart contract, please wait...",
  "Awaiting To Import the Token To Your MetaMask...",
  "Awaiting To Update Staking Data...",
];

exports.admintheadInfo = [
  "Wallet Address",
  "Stake Amount",
  "Contract Duration",
  "Time Staked",
  "Staked Start Date",
  "Staked End Date",
  "Status",
];

exports.chainParams = [
  {
    chainId: process.env.REACT_APP_CHAINID,
    chainName: process.env.REACT_APP_CHAINNAME,
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [process.env.REACT_APP_PUBLIC_RPCURL],
    blockExplorerUrls: [process.env.REACT_APP_BLOCKEXPLOERURL + "/tx/"],
  },
];

exports.ctcInfoStr = `{ "type": "BigNumber", "hex": "${process.env.REACT_APP_CTCINFOSTRINGHEX}" }`;
exports.stakingToken = `{ "type": "BigNumber", "hex": "${process.env.REACT_APP_TOKENIDHEX}" }`;

exports.MY_ALGO_CONNECT = 'MyAlgoConnect';
exports.PERA_WALLET = 'PeraWallet';

exports.creatorStakeFunctionName = 
  {
    opt_into_asset: "gkmLSg==",
    opt_out_asset: "UW2jIg==",
    stakeAlgo: "ZmB2sg==",
    stakeAsset: "JRwxxA==",
    unstake: "m2NQdg==",
    updateSettings: "IhfFcA==",
    setPaused: "/C5Qag==",
    migrateToken: "22wgCg==",
    create: "kxv24A=="
  };