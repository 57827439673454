import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
// components
import UnstakeModal from "components/main/records/UnstakeModal";
import StakeModal from "components/main/stake/StakeModal";
import StakingHistoryAlgo from "./StakingHistoryAlgo";
import AlgoConnect from "./AlgoConnect";
import Loading from "components/Loading";
import FireConfetti from "components/FireConfetti";
// reducers
import { initStakeState, stakeReducer } from "reducers/stakeReducer";
import { initUnstakeState, unstakeReducer } from "reducers/unstakeReducer";
// utils
import { useStakingDataAlgo } from "hooks/useAlgo";
import { callPyTealContract } from "services/contract.service";
import {
  getBalance,
  acceptToken,
  tokenAccepted,
  GetAccountDetails,
} from "utils/algoSDKHelpers";
import algosdk from "algosdk";
import { AuthContext } from "../../context/authContext";
import { AlgoProviderContext } from "../../context/algoProviderContext";
import { toast } from "react-toastify";
import moment from "moment";
import { displayBalance } from "../../utils/textFormater";
import { PickExpectedStakeToken } from "utils/miscHelpers";
import { GetPoolDetails, GetSelectNFTs } from "utils/insurefiHelpers";
// images && constants
import { ReconnectWallet, ConnectWallet } from "services/auth.service";
import { loadStatus, PERA_WALLET } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faWallet,
  faPenToSquare,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import StakingWarningModal from "./StakingWarningModal";
import SelectNFTModal from "./SelectNFTModal";
import PolicyImage from "components/main/PolicyImage";
import { Link, useNavigate } from "react-router-dom";
import StakingInfoBar from "components/layout/StakingInfoBar";

import dbdLogo from "assets/DBDicon.svg"

function StakeCardAlgo({ location }) {
  const { authState, signinAlgo } = useContext(AuthContext);
  const { algoProviderState, connectAlgoProvider } =
    useContext(AlgoProviderContext);

  const [stakeState, dispatch] = useReducer(stakeReducer, initStakeState);
  const [unstakeState, dispatchUnstake] = useReducer(
    unstakeReducer,
    initUnstakeState
  );

  const [isShownConnect, setIsShownConnect] = useState(false);
  const [updateStakingData, setUpdateStakingData] = useState(0);
  const [increasingDuration, setIncreasingDuration] = useState(false);
  const [warningMsg, setWarningMsg] = useState(false);
  const [unstakeUnavailable, setUnstakeUnavailable] = useState(false);
  const [loading, setLoading] = useState(loadStatus[0]);

  const [shownWarningModal, setShownWarningModal] = useState(false);
  const [shownSelectNFTModal, setShowSelectNFTModal] = useState(false);

  const [confirmed, setConfirmed] = useState(false);

  // Acount Details
  const [bal, setBal] = useState(0);
  const [acceptedToken, setAcceptedToken] = useState(2);
  const [isConnecting, setIsConnecting] = useState(false);

  const [poolData, setPoolData] = useState(null);
  const [poolImage, setPoolImage] = useState("");

  // NFT STAKING
  const [isNFTStaking, setIsNFTStaking] = useState(false);
  const [NFTs, setNFTs] = useState([]); // All NFT's for this pool
  const [NFT, setNFT] = useState(null); // Selected NFT

  // Staking Data
  const getAppIdFromPoolId = () => {
    let appIdFromURL = location.pathname.replace("/algorand/pool/", "");
    return Number(appIdFromURL);
  };

  const stakingData = useStakingDataAlgo(
    authState.accountAlgo,
    updateStakingData,
    getAppIdFromPoolId()
  );

  const [totalStaked, setTotalStaked] = useState(0);
  const [maxStakingPool, setMaxStakingPool] = useState(0);
  const [globLimit, setGlobLimit] = useState(0);
  const [amount, setAmount] = useState(0);
  const [stakingDuration, setStakingDuration] = useState(60);
  const [isAdmin, setIsAdmin] = useState(false);
  // Slider limit
  const [sliderMin, setSliderMin] = useState([]);
  const [sliderMax, setSliderMax] = useState([]);

  const [tokenName, setTokenName] = useState("");
  const [rewardTokenName, setRewardTokenName] = useState("");

  const [confettiShown, setConfettiShown] = useState({
    stakeShown: false,
    unstakeShown: false,
  });
  const confettiRef = useRef();
  const navigate = useNavigate();

  const displayWarningForStaking = () => {
    const endTimeDiff = moment
      .unix(stakingData.userStakeEnd)
      .diff(moment(), "days");
    let message = "";
    if (endTimeDiff <= 0) {
      // If user can stake now
      // if unstake is available
      setIncreasingDuration(true);
      const warningMsg = unstakeUnavailable
        ? `Since the system is under maintenance, you can either try to unstake your available tokens later or stake again. 
        However, if you continue with your current stake, you will not be able to unstake and collect your rewards 
        until the new stake end date.`
        : `You have tokens available for unstaking. If you continue with your current stake, you will 
        not be able to unstake and collect your rewards until the new stake end date.`;
      message = warningMsg;
    } else if (endTimeDiff <= stakingDuration) {
      // if there duration selection matters
      setIncreasingDuration(true);
      message = `Your current stake will be available for unstaking in under ${endTimeDiff} days. If you increase your staked amount, you will not be able to unstake and collect your rewards until the new stake end date.`;
    } else {
      const stakeEnddate = moment
        .unix(stakingData.userStakeEnd)
        .format("DD-MMM-YYYY");
      setIncreasingDuration(true);
      message = `You have tokens available for unstaking in ${endTimeDiff} days. If you stake this new amount, you will be able to collect all rewards on ${stakeEnddate}.`;
    }

    if (stakingData.userRewardRatio != stakingData.rewardRatio) {
      // Checking if reward ratio is different
      message += "<br /><br />";
      if (stakingData.userRewardRatio < stakingData.rewardRatio) {
        // Checking if reward ratio being used is old stake
        message +=
          "Additionally, the underlying reward ratio has changed since your current stake was made, because of this any additional stake while your current stake is active you will recieve the old stakes reward ratio.";
      } else {
        // Checking if reward ratio being used is new stake
        message +=
          "Additionally, the underlying reward ratio has changed since your current stake was made, because of this if you stake again your old stake will be subject to the new reward ratio which will result in reduced rewards for the original stake.";
      }
    }

    setWarningMsg(message);
  };

  useEffect(() => {
    async function getPoolData() {
      const contractData = await GetPoolDetails(getAppIdFromPoolId());

      if (!contractData) {
        navigate("/algorand");
        return;
      }

      // Checking if the pool is a NFT staking pool
      if (
        contractData.displayName &&
        contractData.displayName.toLowerCase().includes("nft")
      ) {
        setIsNFTStaking(true);
      }

      if (contractData.displayName.toLowerCase().includes("dbd")) {
        setTokenName("DBD");
      }

      setPoolData(contractData);
      setPoolImage(
        process.env.REACT_APP_BASEURL +
          "Attachment/" +
          contractData.attachmentId
      );
    }

    getPoolData();
  }, []);

  useEffect(() => {
    async function autoConnect() {
      const params = new URLSearchParams(window.location.search);
      if (params.has('autoConnect')) {
        try {await handleConnect();} catch (err) {}
      }
    }

    if (algoProviderState && algoProviderState.provider) {autoConnect();}
  }, [algoProviderState]);

  useEffect(() => {
    async function reconnectPera() {
      if (
        algoProviderState &&
        algoProviderState.provider &&
        algoProviderState &&
        !algoProviderState.accountAlgo
      ) {
        await ReconnectWallet(signinAlgo, algoProviderState.provider);
      }
    }

    reconnectPera();
  }, [algoProviderState]);

  useEffect(() => {
    if (stakingData && loading !== loadStatus[loadStatus.length - 1]) {
      setLoading(loadStatus[loadStatus.length - 1]);
    }
  }, [stakingData, stakingDuration, loading]);

  useEffect(() => {
    async function isConnectingUpdate() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsConnecting(false);
      } catch (err) {
        console.error(err);
      }
    }
    function calcSliderValues() {
      const tmpThresholdArray = stakingData.durationThreshold;
      for (let i = 0; i < tmpThresholdArray.length; i++) {
        if (Number(tmpThresholdArray[i]) === 0) {
          tmpThresholdArray.splice(i, 1);
          i = -1;
        }
      }

      const tmpAPYArray = stakingData.durationApy;
      for (let i = 0; i < tmpAPYArray.length; i++) {
        if (Number(tmpAPYArray[i]) < 2) {
          tmpAPYArray.splice(i, 1);
          i = -1;
        }
      }

      // MARK: only one duration
      setStakingDuration(Number(tmpThresholdArray[0]));
      setSliderMax([
        Number(tmpThresholdArray[tmpThresholdArray.length - 2]),
        Number(tmpAPYArray[tmpAPYArray.length - 1]),
      ]);

      // Setting reward token name - if found
      if (Number(stakingData.rewardASA) === Number(process.env.REACT_APP_DBDV2))
        setRewardTokenName("DBD");
    }

    if (
      stakingData &&
      (stakingData.totalStaked || stakingData.maxStakingPool)
    ) {
      setTotalStaked(stakingData.totalStaked);
      setMaxStakingPool(stakingData.maxStakingPool);
      isConnectingUpdate();
      calcSliderValues();
    }
  }, [stakingData]);

  useEffect(() => {
    async function updateAccountDetails() {
      // Balance
      const tokenBal = await getBalance(
        authState.accountAlgo,
        await PickExpectedStakeToken(
          stakingData.stakeTokens,
          getAppIdFromPoolId()
        )
      );
      setBal(tokenBal);

      let acceptedTokenYet = await tokenAccepted(
        authState.accountAlgo,
        Number(
          await PickExpectedStakeToken(
            stakingData.stakeTokens,
            getAppIdFromPoolId()
          )
        )
      );
      if (acceptedTokenYet) {
        dispatch({ type: "APPROVED" });
      }
      setAcceptedToken(acceptedTokenYet);

      // Check admin permissions
      if (stakingData?.adminASA) {
        const tokenBal = await getBalance(
          authState.accountAlgo,
          stakingData.adminASA
        );
        if (tokenBal > 0) setIsAdmin(true);
      }
    }

    async function stakingDataChange() {
      const limit =
        stakingData.maxStakePerStake <
        stakingData.maxStakingPool - stakingData.totalStaked
          ? stakingData.maxStakePerStake
          : stakingData.maxStakingPool - stakingData.totalStaked;
      let max = Number(limit).toFixed(4);
      setGlobLimit(Number(max));

      // Checking if unstaking is possible
      if (
        Number(stakingData.userExpectedRewards) >
        Number(stakingData.remainingRewards)
      ) {
        setUnstakeUnavailable(true);
      } else if (
        Number(stakingData.remainingRewards) + Number(stakingData.totalStaked) <
        Number(stakingData.userExpectedRewards) + Number(stakingData.staked)
      ) {
        setUnstakeUnavailable(true);
      } else {
        setUnstakeUnavailable(false);
      }
    }

    if (authState.accountAlgo && authState.accountAlgo !== 0 && stakingData) {
      updateAccountDetails();
    }
    if (stakingData) {
      stakingDataChange();
    }
  }, [stakingData, updateStakingData, authState.accountAlgo]);

  // show the confetti
  useEffect(() => {
    if (stakeState.type === "finish" && !confettiShown.stakeShown) {
      confettiRef.current.fire();
      setConfettiShown({ ...confettiShown, stakeShown: true });
    }
  }, [stakeState, confettiShown]);

  useEffect(() => {
    if (unstakeState.type === "finish" && !confettiShown.unstakeShown) {
      confettiRef.current.fire();
      setConfettiShown({ ...confettiShown, unstakeShown: true });
    }
  }, [unstakeState, confettiShown]);

  useEffect(() => {
    async function stake() {
      try {
        dispatch({ type: "STAKING", payload: true });
        setConfettiShown({ ...confettiShown, stakeShown: false });
        await callPyTealContract(
          "stake",
          algosdk.algosToMicroalgos(amount),
          await PickExpectedStakeToken(
            stakingData.stakeTokens,
            getAppIdFromPoolId()
          ),
          authState.accountAlgo,
          algoProviderState.provider, // Provider
          algoProviderState.type, // provider type
          getAppIdFromPoolId()
        );

        setUpdateStakingData(updateStakingData + 1);
        dispatch({ type: "CONFIRM", payload: "" });
        document.getElementById("stakeAmountInput").value = 0;
      } catch (err) {
        console.error(err);
        toast.error("Staking has failed. Please try again");
        dispatch({ type: "ERROR" });
      } finally {
        setConfirmed(false);
      }
    }
    if (
      stakingData &&
      stakingData.staked > 0 &&
      increasingDuration &&
      confirmed
    ) {
      stake();
    }
  }, [confirmed, increasingDuration, stakingData]);

  const validateStake = async () => {
    try {
      // Checks before sending to contract
      if (amount < Number(stakingData.minStaking)) {
        toast.error(
          `Please select an amount of ${parseFloat(
            Number(stakingData.minStaking).toFixed(4)
          ).toLocaleString(undefined, { maximumFractionDigits: 4 })} or higher`
        );
        return;
      }
      if (amount > Number(stakingData.maxStakePerStake)) {
        toast.error(
          `Please select an amount of ${parseFloat(
            Number(stakingData.maxStakePerStake).toFixed(4)
          ).toLocaleString(undefined, { maximumFractionDigits: 4 })} or lower`
        );
        return;
      }
      if (bal < amount) {
        toast.error(`You are trying to stake more than your balance`);
        return;
      }
      if (stakingData.stakingPaused) {
        toast.error(`Staking is currently paused`);
        return;
      }
      if (
        stakingDuration < stakingData.durationThreshold[0] ||
        stakingDuration > sliderMax[0]
      ) {
        toast.error(
          `Please select a duration between ${stakingData.durationThreshold[0]} and ${sliderMax[0]}`
        );
        return;
      }

      if (increasingDuration && stakingData.staked > 0) {
        setShownWarningModal(true);
      } else {
        dispatch({ type: "STAKING", payload: true });
        setConfettiShown({ ...confettiShown, stakeShown: false });

        await callPyTealContract(
          "stake",
          algosdk.algosToMicroalgos(amount),
          await PickExpectedStakeToken(
            stakingData.stakeTokens,
            getAppIdFromPoolId()
          ),
          authState.accountAlgo,
          algoProviderState.provider, // Provider
          algoProviderState.type, // provider type
          getAppIdFromPoolId()
        );

        setUpdateStakingData(updateStakingData + 1);
        dispatch({ type: "CONFIRM", payload: "" });
        document.getElementById("stakeAmountInput").value = 0;
      }
    } catch (err) {
      console.error(err);
      toast.error("Staking has failed. Please try again");
      dispatch({ type: "ERROR" });
    }
  };

  const stakeNFT = async () => {
    try {
      // Validations
      if (!NFT) {
        toast.error("Please select an NFT to stake");
        return;
      }

      if (!NFT.id) {
        toast.error("Failed to get NFT's Id, please try again");
        return;
      }

      dispatch({ type: "STAKING", payload: true });
      setConfettiShown({ ...confettiShown, stakeShown: false });

      await callPyTealContract(
        "stakeNFT",
        NFT.id,
        authState.accountAlgo,
        algoProviderState.provider, // Provider
        algoProviderState.type, // provider type
        getAppIdFromPoolId()
      );

      setUpdateStakingData(updateStakingData + 1);
      dispatch({ type: "CONFIRM", payload: "" });
      setNFT(null);
    } catch (err) {
      console.error(err);
      toast.error("Staking has failed. Please try again");
      dispatch({ type: "ERROR" });
    }
  };

  const approveToken = async () => {
    try {
      dispatch({ type: "APPROVING" });
      await acceptToken(
        authState.accountAlgo,
        Number(
          await PickExpectedStakeToken(
            stakingData.stakeTokens,
            getAppIdFromPoolId()
          )
        ),
        algoProviderState.provider,
        PERA_WALLET
      );
      let acceptedTokenYet = await tokenAccepted(
        authState.accountAlgo,
        Number(
          await PickExpectedStakeToken(
            stakingData.stakeTokens,
            getAppIdFromPoolId()
          )
        )
      );
      if (acceptedTokenYet) {
        dispatch({ type: "APPROVED" });
      }
      setAcceptedToken(acceptedTokenYet);
    } catch (err) {
      console.error(err);
      toast.error("You don't have enough algo to accept this token");
      dispatch({ type: "ERROR" });
    }
  };

  const inputChangeAmount = (value) => {
    if (stakingData.userStakeEnd > 0 && value > 0) {
      displayWarningForStaking();
    } else {
      setIncreasingDuration(false);
    }

    setAmount(Math.floor(value));
  };

  const applyMax = () => {
    const limit =
      stakingData.maxStakePerStake <
      stakingData.maxStakingPool - stakingData.totalStaked
        ? stakingData.maxStakePerStake
        : stakingData.maxStakingPool - stakingData.totalStaked;
    let max = bal > limit ? limit : bal;

    if (max !== 0) {
      max = Number(max.toString()).toFixed(6);
      document.getElementById("stakeAmountInput").value = max;
      setAmount(max);
      displayWarningForStaking();
    }
  };

  const handleConnect = async () => {
    if (algoProviderState.provider.isConnected) {
      await ReconnectWallet(signinAlgo, algoProviderState.provider);
    } else {
      await ConnectWallet(signinAlgo, algoProviderState.provider);
      connectAlgoProvider({
        provider: algoProviderState.provider,
        type: PERA_WALLET,
        connected: true,
      });
    }
  };

  const unstake = async () => {
    try {
      if (unstakeUnavailable) {
        toast.error(`There isn't enough rewards available to unstake`);
        return;
      }

      dispatchUnstake({ type: "UNSTAKING", payload: true });
      setConfettiShown({ ...confettiShown, unstakeShown: false });

      await callPyTealContract(
        isNFTStaking ? "unstakeNFT" : "unstake",
        stakingData.stakedToken,
        stakingData.rewardASA,
        authState.accountAlgo,
        algoProviderState.provider,
        algoProviderState.type,
        getAppIdFromPoolId()
      );
      setUpdateStakingData(updateStakingData + 1);
      dispatchUnstake({ type: "CONFIRM", payload: "" });

      // Reload in their NFTs
      await getNFTS();
    } catch (err) {
      console.error(err);
      toast.error("Unstaking has failed. Please try again");
      dispatchUnstake({ type: "ERROR" });
    }
  };

  const sanatizeAmount = (amt, output) => {
    if (!amt) return 0;

    let result = amt.toFixed(2).toString();
    if (result.split(".")[1] === "00")
      return displayBalance(Number(result.split(".")[0]));
    return displayBalance(Number(result));
  };

  const getNFTS = async () => {
    function CompileAssetList(assetList) {
      let result = [];
      assetList.forEach((asset) => {
        if (
          asset.amount > 0 &&
          stakingData.stakeTokens.some(
            (item) => item.index === asset["asset-id"]
          )
        ) {
          result.push(asset["asset-id"]);
        }
      });

      return result;
    }
    // Get users assets
    const accountDetails = await GetAccountDetails(authState.accountAlgo);
    // Filter list
    const assetList = CompileAssetList(accountDetails["assets"]);

    const result = await GetSelectNFTs(assetList, stakingData.durationThreshold[0]);

    // Filter based on Expiry date
    let filteredResult = [];
    result.forEach((nft) => {
      const expiryDate = moment(nft.expiryDate);
      const durationEndDate = moment().add(
        stakingData.durationThreshold[0],
        "days"
      );

      if (expiryDate > durationEndDate)
        filteredResult.push({ selected: false, ...nft });
    });

    setNFTs(filteredResult);
  };

  const hasRunRef = useRef(false);
  useEffect(() => {
    if (
      authState &&
      authState.accountAlgo &&
      stakingData &&
      !hasRunRef.current &&
      isNFTStaking
    ) {
      getNFTS();
      hasRunRef.current = true;
    }
  }, [stakingData]);

  return (
    <div className="container stake-container mx-auto mt-5">
      {!authState.accountAlgo && <div className="row mt-5 pt-5"></div>}
      <div id="stakeCard" className="card mb-4 mx-auto">
        {isAdmin && (
          <div className="mt-3 text-center">
            <Link
              to="/admin"
              state={{ network: location.pathname }}
              className="primary-color"
              children="Head to Admin Portal"
            />
          </div>
        )}
        <div className="card-body p-4 justify-content-center">
          {poolData && stakingData ? (
            <>
              <StakingInfoBar
                title={poolData.displayName}
                paused={poolData.Paused}
                staked={
                  isNFTStaking
                    ? algosdk.algosToMicroalgos(stakingData.totalStaked)
                    : stakingData.totalStaked
                }
                apy={
                  isNFTStaking
                    ? stakingData.rewardRatio / 100 / 1000000
                    : stakingData.durationApy
                }
                poolLimit={stakingData.maxStakingPool}
                tokenName={tokenName}
                isNFTStaking={isNFTStaking}
                rewardTokenName={rewardTokenName}
              />

              <hr className="card-divider my-4" />
            </>
          ) : (
            <></>
          )}

          {/* Staking actions */}
          <div className="row staking-actions-container">
            {acceptedToken &&
              authState.accountAlgo &&
              !isConnecting &&
              !isNFTStaking && (
                <>
                  {/* Balance */}
                  <div className="col-12 pb-3">
                    <div className="pill pill-balance">
                      <div className="row">
                        <div className="col-9 font-weight-bold d-flex align-items-center pl-3">
                          <div className="wallet-bubble d-flex align-items-center justify-content-center mr-2">
                            <FontAwesomeIcon icon={faWallet} />
                          </div>
                          <span className="wallet-address">
                            {authState.accountAlgo.substring(0, 4).toString() +
                              "..." +
                              authState.accountAlgo
                                .slice(authState.accountAlgo.length - 4)
                                .toString()}

                          </span>
                        </div>
                        <div className="col-3 d-flex flex-column align-items-end">
                          <label className="font-weight-bold mb-1 hang-right">
                            Balance
                          </label>
                          <div className="hang-right">
                            {isNFTStaking ? NFTs.length : displayBalance(bal)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 pb-3">
                    <div className="input-group stake-group">
                      <div className="input-group-prepend text-center">
                        <img
                          src={poolImage.endsWith('/null') ? dbdLogo : poolImage}
                          alt="DBD"
                          id="dbdLogo"
                          className="mx-auto"
                        />
                      </div>
                      <input
                        className="form-control stakeInput"
                        id="stakeAmountInput"
                        type="number"
                        step="0.0001"
                        min="0"
                        placeholder="0"
                        onChange={(e) =>
                          inputChangeAmount(e.currentTarget.value)
                        }
                      />
                      <div className="max-container mr-4">
                      <button onClick={applyMax} className="btn-max">MAX</button>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <p className="mb-5 mb-sm-3 secondary-color">
                      <small className="font-weight-bold">
                        <FontAwesomeIcon
                          icon={faLock}
                          className="secondary-color mr-2"
                        />
                        {stakingData
                          ? sanatizeAmount(stakingData.durationThreshold[0])
                          : 0}{" "}
                        Days at{" "}
                        {stakingData
                          ? sanatizeAmount(stakingData.durationApy[0])
                          : 0}
                        % APY
                      </small>
                    </p>
                  </div>

                  {authState.accountAlgo !== 0 && stakingData && (
                    <div className="col-6 text-right">
                      <p className="mb-2">
                        <small>
                          Min {stakingData.minStaking}
                          {tokenName ? ` ${tokenName}` : ""} / Max{" "}
                          {stakingData.maxStakePerStake}
                          {tokenName ? ` ${tokenName}` : ""}
                        </small>
                      </p>
                    </div>
                  )}
                </>
              )}

            {Number(globLimit) < Number(amount) && (
              <div className="col-12 text-center">
                <p className="text-danger">
                  <small className="font-weight-bold">
                    Maximum DBD Stake limit reached for this transaction
                  </small>
                </p>
              </div>
            )}

            {authState.accountAlgo &&
              !isNFTStaking &&
              acceptedToken === false &&
              !isConnecting && (
                <div className="col-12 text-center">
                  <button
                    className="btn btn-secondary w-75 py-3"
                    onClick={() => approveToken()}
                  >
                    Approve
                  </button>
                </div>
              )}

            {isConnecting && (
              <div className="row mb-2 mt-4">
                <div className="col-12 text-center">
                  <button
                    disabled={true}
                    className="btn btn-secondary w-75 py-3"
                    id="approvalBtn"
                    onClick={() => approveToken()}
                  >
                    connecting account ...
                    <div
                      className="spinner-border spinner-border-sm approveSpinner"
                      role="status"
                    ></div>
                  </button>
                </div>
              </div>
            )}

            {!authState.accountAlgo && (
              <div className="col-12 text-center">
                <button
                  className="btn btn-secondary w-75 py-3"
                  onClick={() => handleConnect()}
                >
                  Connect Wallet
                </button>
              </div>
            )}

            {authState.accountAlgo && acceptedToken === 2 && !isConnecting && (
              <div className="col-12 text-center">
                <button
                  disabled={true}
                  className="btn btn-secondary w-75 py-3"
                  id="approvalBtn"
                  onClick={() => approveToken()}
                >
                  loading account...
                  <div
                    className="spinner-border approveSpinner"
                    role="status"
                  ></div>
                </button>
              </div>
            )}

            {authState.accountAlgo &&
            stakingData &&
            (acceptedToken === true || isNFTStaking) &&
            isConnecting === false &&
            acceptedToken !== 2 ? (
              <div className="col-12">
                {isNFTStaking ? (
                  // NFT STAKING BUTTON
                  <div className="row">
                    {NFT ? (
                      <>
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            className="stakeNFT-wrapper mb-3"
                            onClick={() => setShowSelectNFTModal(true)}
                          >
                            <PolicyImage item={NFT} />
                            <span className="edit">
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </span>
                          </div>
                        </div>
                        <div className="col-12 text-center">
                          <p className="mb-5 mb-sm-3 secondary-color">
                            <small className="font-weight-bold">
                              <FontAwesomeIcon
                                icon={faLock}
                                className="secondary-color mr-2"
                              />
                              {stakingData
                                ? sanatizeAmount(
                                    stakingData.durationThreshold[0]
                                  )
                                : 0}{" "}
                              Days for{" "}
                              {stakingData
                                ? sanatizeAmount(
                                    stakingData.rewardRatio / 100 / 1000000
                                  )
                                : 0}{" "}
                              {rewardTokenName}
                            </small>
                          </p>
                        </div>

                        <div className="col-12 text-center">
                          <button
                            disabled={Boolean(stakingData.stakingPaused)}
                            className="btn btn-secondary py-3 w-75"
                            onClick={() => stakeNFT()}
                          >
                            Confirm
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12 text-center">
                          <button
                            disabled={
                              Boolean(stakingData.stakingPaused) ||
                              NFTs.length === 0 ||
                              stakingData.staked > 0
                            }
                            className="btn btn-nft py-4 px-5"
                            onClick={() => setShowSelectNFTModal(true)}
                          >
                            <span className="mb-2 mx-auto">
                              <FontAwesomeIcon icon={faPlus} />
                            </span>
                            Select an NFT
                          </button>
                        </div>

                        {!stakingData.stakingPaused &&
                          stakingData.staked > 0 && (
                            <div className="col-12 pt-3">
                              <p
                                id="PauseStaking "
                                className="text-center mb-0"
                              >
                                <small className="font-weight-bold">
                                  You already have an active stake and cannot
                                  stake another NFT
                                </small>
                              </p>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12 pt-3 text-center">
                      <button
                        disabled={Boolean(stakingData.stakingPaused)}
                        className="btn btn-secondary w-75 py-3"
                        onClick={() => validateStake()}
                      >
                        Confirm
                      </button>
                    </div>

                    {stakingData.stakingPaused && (
                      <div className="col-12 pt-3">
                        <p id="PauseStaking " className="text-center mb-0">
                          <small className="font-weight-bold">
                            Staking has been paused on this site.
                            <br />
                            You can still unstake.
                          </small>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="mb-4 mt-4"></div>
            )}
          </div>
        </div>
        <StakeModal stakeState={stakeState} dispatch={dispatch} from="algo" />
        <UnstakeModal
          unstakeState={unstakeState}
          dispatch={dispatchUnstake}
          from="algo"
        />
      </div>

      <SelectNFTModal
        shown={shownSelectNFTModal}
        setShown={setShowSelectNFTModal}
        setSelectedNFT={setNFT}
        NFTs={NFTs}
      />

      <StakingWarningModal
        message={warningMsg}
        shown={shownWarningModal}
        setShown={setShownWarningModal}
        setConfirmed={setConfirmed}
      />
      {increasingDuration && stakingData.staked > 0 && <></>}

      {stakingData && stakingData.staked > 0 && (
        <>
          <StakingHistoryAlgo
            updated={updateStakingData}
            stakingData={stakingData}
            unstakingFunction={unstake}
            NFTStaking={isNFTStaking}
            rewardTokenName={rewardTokenName}
          />
        </>
      )}

      <FireConfetti ref={confettiRef} />

      <Loading loadingStatus={loading} />

      <AlgoConnect isShown={isShownConnect} setIsShown={setIsShownConnect} />
    </div>
  );
}

export default StakeCardAlgo;
