import moment from "moment";

export function calRewards(
  amount,
  perAPY,
  contractStartTimestamp,
  contractNumberDays,
  earlyUnstakeApy,
  extraPeriodApy
) {
  // calculate the rewards
  const contractDays = parseInt(contractNumberDays);
  let reward = 0;
  let rewardRate = perAPY / 100 / 365 / 24 / 60 / 60;
  const earlyRewardRate = earlyUnstakeApy / 100 / 365 / 24 / 60 / 60;
  const afterDurationRewardRate = extraPeriodApy / 100 / 365 / 24 / 60 / 60;
  const currentDuration = moment().diff(
    moment.unix(contractStartTimestamp),
    "seconds"
  );
  if (currentDuration < contractDays) {
    rewardRate = earlyRewardRate;
    reward = rewardRate * currentDuration * parseFloat(amount);
  } else {
    if (contractDays === 0) {
      reward = rewardRate * currentDuration * parseFloat(amount);
    } else {
      const rewardWithDuartion = rewardRate * contractDays * parseFloat(amount);
      const timeAfterDuration = currentDuration - contractDays;
      const rewardAfterDuration =
        afterDurationRewardRate * timeAfterDuration * parseFloat(amount);
      reward = rewardWithDuartion + rewardAfterDuration;
    }
  }

  return reward;
}

export function calculateRewardRatio(durationInSeconds, desiredAPY) {
  // Desired APY as a decimal
  const apyAsDecimal = desiredAPY / 100;

  // Calculate total return for the year (including the initial investment)
  const totalReturnForYear = 1 + apyAsDecimal;

  // Convert the year return to the return for the given duration
  const totalReturnForDuration = Math.pow(totalReturnForYear, durationInSeconds / (60 * 60 * 24 * 365));

  // Subtract 1 (the initial investment) and multiply by 100 to get the reward ratio
  const rewardRatio = (totalReturnForDuration - 1) * 100;

  return rewardRatio;
}

export function calculateAPY(durationInSeconds, rewardRatio) {
  // Reward ratio as a decimal (including the initial investment)
  const periodReturn = 1 + (rewardRatio / 100);

  // Calculate the number of duration periods in a year
  const periodsPerYear = (60 * 60 * 24 * 365) / durationInSeconds;

  // Calculate total return for the year using compound interest formula
  const totalReturn = Math.pow(periodReturn, periodsPerYear);

  // Subtract 1 to remove the initial investment and convert back to percentage
  const apy = (totalReturn - 1) * 100;

  if (apy === Infinity || apy === -Infinity) return 0.01;

  return Math.round(apy, 2);
}