import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TxnProcessModal from "../TxnProcessModal";
// contexts
import { EthProviderContext } from "context/ethProviderContext";
import { AuthContext } from "context/authContext";
// hooks
import { useForm } from "react-hook-form";
import { useContracts } from "hooks/useContracts";
// utils
import { toast } from "react-toastify";
import { sendTransaction } from "services/contract.service";
import { durationFormatter } from "utils/format";

function EditDurationModal(props) {
  const {
    editDuration,
    setIsUpdated,
    isShownEditModal,
    setIsShownEditModal,
    adminTxnState,
    dispatch,
  } = props;

  const [minSeconds, setMinSeconds] = useState(0);
  const { authState } = useContext(AuthContext);
  const { ethProviderState } = useContext(EthProviderContext);

  const [, stakingContract] = useContracts();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (editDuration) {
      setMinSeconds(editDuration.minStaking * 24 * 60 * 60);
    }
  }, [editDuration]);

  const submit = async (data) => {
    data.durationIndex = editDuration.durationIndex;
    data.period = editDuration.period;
    if (!data.apy || !data.minStaking) {
      toast.error("Please fill out all input fields");
      return;
    }

    const minStakingInSeconds = Math.floor(data.minStaking * 24 * 60 * 60);
    if (Number(data.period) < Number(minStakingInSeconds)) {
      toast.error(
        "Please ensure Min Staking Period is lower than Contract Duration"
      );
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods
        .setDurationTerm(
          data.durationIndex,
          data.apy,
          minStakingInSeconds,
          editDuration.isActive
        )
        .encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const modifyDurationTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      reset({
        durationIndex: data.durationIndex,
        period: data.period,
        apy: data.apy,
        minStaking: data.minStaking,
      });
      dispatch({ type: "CONFIRM", payload: modifyDurationTxn });
    } catch (err) {
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
    // window.location.reload();
    setIsShownEditModal(false);
  };

  return (
    <div>
      <Modal show={isShownEditModal} onHide={() => setIsShownEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(submit)}>
            <div>
              <label className="d-block float-left">
                <b>Contract Duration ID</b>
              </label>
            </div>
            <div className="input-group mb-4">
              <input
                type="number"
                className="form-control form-control-lg"
                {...register("durationIndex")}
                value={editDuration ? editDuration.durationIndex : 0}
                disabled
              />
            </div>
            <div>
              <label className="float-left">
                <b>Contract Duration</b>
              </label>
            </div>
            <div className="input-group">
              <input
                type="number"
                className="form-control form-control-lg"
                {...register("period")}
                value={
                  editDuration ? durationFormatter(editDuration.period) : 0
                }
                disabled
              />
              <div className="input-group-append">
                <div className="input-group-text">Day(s)</div>
              </div>
            </div>
            <small>
              Convert to Seconds:&nbsp;
              <b>{editDuration ? editDuration.period : 0}</b>
              &nbsp; seconds
            </small>

            <div className="mt-4">
              <label className="float-left">
                <b>APY</b>
              </label>
            </div>
            <div className="input-group mb-4">
              <input
                min="0"
                type="number"
                className="form-control form-control-lg"
                placeholder={editDuration ? editDuration.apy : 0}
                {...register("apy")}
                required
              />
              <div className="input-group-append">
                <div className="input-group-text">%</div>
              </div>
            </div>

            <div>
              <label className="float-left">
                <b>Min Staking Period</b>
              </label>
            </div>
            <div className="input-group">
              <input
                type="number"
                min="0"
                className="form-control form-control-lg"
                placeholder={editDuration ? editDuration.minStaking : 0}
                defaultValue={editDuration ? editDuration.minStaking : 0}
                {...register("minStaking")}
                required
                onChange={(e) =>
                  setMinSeconds(Math.floor(e.target.value * 24 * 60 * 60))
                }
              />
              <div className="input-group-append">
                <div className="input-group-text">Day(s)</div>
              </div>
            </div>
            <small>
              Convert to Seconds: <b>{minSeconds}</b> seconds
            </small>

            <div className="my-4 text-center">
              <button
                className="btn btn-lg w-50 bg-primary-color text-white rounded-pill"
                onClick={() => handleSubmit()}
              >
                Edit Contract
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            onClick={() => setIsShownEditModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <TxnProcessModal adminTxnState={adminTxnState} dispatch={dispatch} />
    </div>
  );
}

export default EditDurationModal;
