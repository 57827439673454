import React, { useState, useEffect } from "react";
// utils
import moment from "moment";

function StakingHistoryAlgo({
  updated,
  stakingData,
  unstakingFunction,
  NFTStaking,
  rewardTokenName
}) {
  const [checkingUnstake, setCheckingUnstake] = useState(0);
  const [daysToUnstake, setDaysToUnstake] = useState(0);
  const [unstakeDate, setUnstakeDate] = useState(0);
  const [unstakable, setUnstakable] = useState(false);

  useEffect(() => {
    if (stakingData) {
      let timeInDays = moment
        .unix(stakingData.userStakeEnd)
        .diff(moment(), "days");
      let timeDiff = `${timeInDays} ${
        moment().diff(moment.unix(stakingData.userStakeEnd), "days") >= 1
          ? "Day"
          : "Days"
      }`;
      setUnstakeDate(
        moment.unix(stakingData.userStakeEnd).format("DD-MMM-YYYY HH:mm:ss")
      );

      if (timeInDays < 0) {
        timeDiff = "0 Days";
      }
      setDaysToUnstake(timeDiff);
    }
  }, [stakingData, updated]);

  useEffect(() => {
    // Check if unstakable
    if (
      Number(stakingData.userExpectedRewards) >
      Number(stakingData.remainingRewards)
    ) {
      setUnstakable(false);
    } else if (
      Number(stakingData.userExpectedRewards) + Number(stakingData.staked) >
      Number(stakingData.remainingRewards) + Number(stakingData.totalStaked)
    ) {
      setUnstakable(false);
    } else if (moment.unix(stakingData.userStakeEnd) <= moment()) {
      setUnstakable(true);
    } else {
      setUnstakable(false);
      if (checkingUnstake > 5) {
        setCheckingUnstake(0);
      } else {
        setCheckingUnstake(checkingUnstake + 1);
      }
    }
  }, [checkingUnstake, stakingData]);

  function GetRewardText()
  {
    return (`${parseFloat(
      Number(stakingData.userExpectedRewards).toFixed(4)
    ).toLocaleString(undefined, {
      maximumFractionDigits: 4,
    })}` + ` ${rewardTokenName}`)
  }

  return (
    <div className="mt-4">
      <h4 className="mb-2" id="stakingHistory">
        Staking History
      </h4>
      <div style={{ fontSize: "13px" }}>
        *Please Note: Placing an additional stake will add to your staking
        duration. <br /> APY rewards are calculated automatically by the
        Algorand PPOS Consensus Mechanism.
      </div>
      <div className="w-100 mb-4">
        <div className="pb-2 table-responsive">
          <table className="pool-table">
            <thead>
              <tr>
                <th className="border-side-left text-left">Total Staked</th>
                <th scope="col">Rewards</th>
                <th scope="col">Days To Unstake*</th>
                <th scope="col">Unstake Date</th>
                <th className="border-side-right text-right"></th>
              </tr>
            </thead>
            <thead className="spacing"></thead>
            <tbody>
              <tr>
                <td className="align-middle">
                  {NFTStaking
                    ? Number(stakingData.staked) * 1000000
                    : parseFloat(
                        Number(stakingData.staked).toFixed(4)
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 4,
                      })}
                </td>
                <td className="align-middle">
                  {GetRewardText()}
                </td>
                <td className="align-middle">{daysToUnstake}</td>
                <td className="align-middle">{unstakeDate}</td>
                <td className="align-middle text-right">
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() => unstakingFunction()}
                    disabled={!unstakable}
                  >
                    Unstake
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          {moment.unix(stakingData.userStakeEnd) <= moment() ? (
            Number(stakingData.userExpectedRewards) >
              Number(stakingData.remainingRewards) && (
              <div className="p-4 text-center">
                <h6
                  className="font-weight-normal"
                  style={{ lineHeight: "1.5" }}
                >
                  Unstaking is unavailable while the system is under
                  maintenance. Please try again later. <br />
                  If you have any questions, feel free to&nbsp;
                  <a
                    className="text-danger text-underline"
                    href="https://daybyday.io/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact us
                  </a>
                  .
                </h6>
              </div>
            )
          ) : (
            <div>
              *Please Note: You can only unstake from&nbsp;
              {moment.unix(stakingData.userStakeEnd).format("Do")} of&nbsp;
              {moment.unix(stakingData.userStakeEnd).format("MMMM")}&nbsp;
              {moment.unix(stakingData.userStakeEnd).format("YYYY")}.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StakingHistoryAlgo;
