export const initAdminTxnState = {
  isProcessing: false,
  isConfirmed: false,
  txnHash: '',
};

export function adminTxnReducer(state, action) {
  switch (action.type) {
    case 'PROCESSING':
      return { ...state, isProcessing: action.payload };
    case 'CONFIRM':
      return {
        ...state,
        isProcessing: true,
        isConfirmed: true,
        txnHash: action.payload,
      };
    case 'FINISH':
      return initAdminTxnState;
    default:
  }
}
