import React from "react";
import StakeCardAlgoLegacy from "components/algo/StakeCardAlgoLegacy";
import UseCase from "components/layout/UseCase";

function algorandlegacyPage() {
  return (
    <>
      <div className="text-center mt-3">
        <StakeCardAlgoLegacy />
        <UseCase />
      </div>
    </>
  );
}

export default algorandlegacyPage;
