export function displayBalance(amount) {
  let finalAmount ="0";
  let magnature = "";

  if (!amount) return finalAmount;
  if (amount >= 1000000000000) {
    finalAmount = (amount / 1000000000000).toFixed(1);
    magnature = "T";
  } else if (amount >= 1000000000) {
    finalAmount = (amount / 1000000000).toFixed(1);
    magnature = "B";
  } else if (amount >= 1000000) {
    finalAmount = (amount / 1000000).toFixed(1);
    magnature = "M";
  } else if (amount >= 1000) {
    finalAmount = (amount / 1000).toFixed(1);
    magnature = "K";
  } else {
    finalAmount = amount.toString();
  }

  if (finalAmount.split(".")[1] === "0") return finalAmount.split('.')[0] + magnature;

  return finalAmount + magnature;
}

export function formatNumber(number, fixedAmt = 6) {
  const fixedNumber = number.toFixed(fixedAmt);
  const formattedNumber = fixedNumber.replace(/\.?0+$/, '');

  return Number(formattedNumber);
}