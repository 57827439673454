import React, { useReducer, createContext } from "react";

const initialState = {
  accountPoly: null,
  accountAlgo: null,
  isConnectedPoly: false,
  isConnectedAlgo: false,
  isSigninPoly: false,
  isSigninAlgo: false,
  isAdminPoly: false,
  isAdminAlgo: false,
  subscribed: false,
};

export const AuthContext = createContext(initialState);

const authReducer = (state, action) => {
  switch (action.type) {
    case "CONNECT_POLY":
      return { ...state, isConnectedPoly: true };
    case "UPDATE_ADMIN_POLY":
      return { ...state, isAdminPoly: action.payload };
    case "SIGNIN_POLY":
      return {
        ...state,
        accountPoly: action.payload,
        isSigninPoly: true,
        isConnectedPoly: true,
        subscribed: true,
      };
    case "SIGNOUT_POLY":
      return {
        ...state,
        accountPoly: null,
        isSigninPoly: false,
        isAdminPoly: false,
      };
    case "CONNECT_ALGO":
      return { ...state, isConnectedAlgo: true };
    case "UPDATE_ADMIN_ALGO":
      return { ...state, isAdminAlgo: action.payload };
    case "SIGNIN_ALGO":
      return {
        ...state,
        accountAlgo: action.payload,
        isSigninAlgo: true,
        isConnectedAlgo: true,
      };
    case "SIGNOUT_ALGO":
      return {
        ...state,
        accountAlgo: null,
        isSigninAlgo: false,
        isAdminAlgo: false,
      };
    default:
      return state;
  }
};

export function AuthProvider({ children }) {
  const [authState, dispatch] = useReducer(authReducer, initialState);

  const connectPoly = () => {
    dispatch({ type: "CONNECT_POLY" });
  };

  const updateAdminPoly = (value) => {
    dispatch({
      type: "UPDATE_ADMIN_POLY",
      payload: value,
    });
  };

  const signinPoly = (account) => {
    dispatch({
      type: "SIGNIN_POLY",
      payload: account,
    });
  };

  const signoutPoly = () => {
    dispatch({ type: "SIGNOUT_POLY" });
  };

  const connectAlgo = () => {
    dispatch({ type: "CONNECT_ALGO" });
  };

  const updateAdminAlgo = (value) => {
    dispatch({
      type: "UPDATE_ADMIN_ALGO",
      payload: value,
    });
  };

  const signinAlgo = (account) => {
    dispatch({
      type: "SIGNIN_ALGO",
      payload: account,
    });
  };

  const signoutAlgo = () => {
    dispatch({ type: "SIGNOUT_ALGO" });
  };

  const value = {
    authState,
    connectPoly,
    updateAdminPoly,
    signinPoly,
    signoutPoly,
    connectAlgo,
    updateAdminAlgo,
    signinAlgo,
    signoutAlgo,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
