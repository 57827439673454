import React, { useState, useEffect, useContext } from "react";
import EditDurationModal from "./EditDurationModal";
import TxnProcessModal from "../TxnProcessModal";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// contexts
import { AuthContext } from "context/authContext";
import { EthProviderContext } from "context/ethProviderContext";
// hooks
import { useContracts } from "hooks/useContracts";
// utils
import { toast } from "react-toastify";
import {
  sendTransaction,
  awaitTransaction,
  getDurationTerms,
} from "services/contract.service";
import AddDurationModal from "./AddDurationModal";
import { durationFormatter } from "utils/format";

function DurationRecordsCard(props) {
  const { adminTxnState, dispatch } = props;

  const [durationRecords, setDurationRecords] = useState();
  const [editDuration, setEditDuration] = useState();
  const [isShownAddModal, setIsShownAddModal] = useState(false);
  const [isShownEditModal, setIsShownEditModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [durationData, setDurationData] = useState([]);

  const { authState } = useContext(AuthContext);
  const { ethProviderState } = useContext(EthProviderContext);

  const [, stakingContract] = useContracts();
  const [noDataText, setNoDataText] = useState();
  const [adminConnected, setAdminConnected] = useState(false);

  const options = {
    noDataText: noDataText,
  };

  useEffect(() => {
    async function getDurationData() {
      try {
        const durationData = await getDurationTerms(stakingContract);
        setDurationData(durationData);
      } catch (err) {
        console.error(err);;
        toast.error("Faile to get the duration data");
      }
    }
    if (stakingContract) {
      getDurationData();
    }
  }, [stakingContract]);

  useEffect(() => {
    async function updateDuration() {
      await awaitTransaction(ethProviderState.provider, adminTxnState.txnHash);
      toast.success(
        "Transaction has been successful. The fields will be changed in a few seconds"
      );
      setIsUpdated(false);
      try {
        const durationData = await getDurationTerms(stakingContract);
        setDurationData(durationData);
      } catch (err) {
        console.error(err);
      }
    }
    if (adminTxnState.txnHash && isUpdated) {
      updateDuration();
    }
  }, [
    adminTxnState.txnHash,
    isUpdated,
    ethProviderState.provider,
    stakingContract,
  ]);

  useEffect(() => {
    if (durationData.length === 0) {
      setNoDataText("Table data is still being loaded. Please wait...");
    } else {
      setNoDataText("There is no data to display");
    }
  }, [durationData]);

  useEffect(() => {
    setAdminConnected(authState.isAdminPoly);
  }, [adminTxnState.txnHash, authState]);

  useEffect(() => {
    async function getDurationTerms() {
      try {
        const tempDurationRecords = durationData.map((term, index) => {
          const duration =
            term.period === 0
              ? "Flexible"
              : durationFormatter(term.period).toString();
          return {
            durationIndex: index,
            duration: duration,
            apy: term.perAPY,
            minStaking: durationFormatter(term.minStakingPeriod),
            isActive: term.isActive,
            period: term.period,
          };
        });
        setDurationRecords(tempDurationRecords);
      } catch (err) {
        console.error(err);;
        toast.error(err.message);
      }
    }
    if (durationData.length > 0) {
      getDurationTerms();
    }
  }, [durationData, stakingContract]);

  const toggleDuration = async (id, apy, minPeriod, isActive) => {
    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const minStakingInSeconds = Math.floor(minPeriod * 24 * 60 * 60);
      const transaction = stakingContract.methods
        .setDurationTerm(id, apy, minStakingInSeconds, !isActive)
        .encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const toggleDurationTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      dispatch({ type: "CONFIRM", payload: toggleDurationTxn });
    } catch (err) {
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const statusButton = (cell, row) => (
    <button
      style={{ whiteSpace: "nowrap" }}
      className="btn bg-primary-color text-white rounded-pill"
      disabled={!adminConnected}
      onClick={() =>
        toggleDuration(row.durationIndex, row.apy, row.minStaking, row.isActive)
      }
    >
      {cell ? "Deactivate" : "Activate"}
    </button>
  );

  const editButton = (cell, row) => (
    <button
      style={{ whiteSpace: "nowrap" }}
      className="btn bg-primary-color text-white rounded-pill"
      disabled={!adminConnected}
      onClick={() => {
        setEditDuration(row);
        setIsShownEditModal(true);
      }}
    >
      Edit
    </button>
  );

  return (
    <div className="card mb-4 mt-4 mx-auto scroll-box card-width pl-4 pt-4">
      <h2>Current Contracts:</h2>
      <div className="my-3">
        <BootstrapTable
          data={durationRecords}
          hover
          bordered={false}
          options={options}
        >
          <TableHeaderColumn
            isKey
            dataField="durationIndex"
            dataAlign="center"
            editable={false}
            width="100"
            thStyle={{ whiteSpace: "normal" }}
          >
            Duration Index
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="duration"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Duration (Days)
          </TableHeaderColumn>
          <TableHeaderColumn dataField="apy" dataAlign="center" width="90">
            APY (%)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="minStaking"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Min Staking Period (Days)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="isActive"
            dataAlign="center"
            width="150"
            dataFormat={statusButton}
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            width="90"
            dataFormat={editButton}
          ></TableHeaderColumn>
        </BootstrapTable>
      </div>
      <div className="text-center mb-3">
        <button
          style={{ whiteSpace: "nowrap" }}
          className="btn w-25 bg-primary-color text-white rounded-pill"
          onClick={() => setIsShownAddModal(true)}
          disabled={!adminConnected}
        >
          Add new product
        </button>
      </div>

      {isShownAddModal && (
        <AddDurationModal
          durationTerms={durationData}
          setIsUpdated={setIsUpdated}
          isShownAddModal={isShownAddModal}
          setIsShownAddModal={setIsShownAddModal}
          adminTxnState={adminTxnState}
          dispatch={dispatch}
        />
      )}

      {isShownEditModal && (
        <EditDurationModal
          editDuration={editDuration}
          setIsUpdated={setIsUpdated}
          isShownEditModal={isShownEditModal}
          setIsShownEditModal={setIsShownEditModal}
          adminTxnState={adminTxnState}
          dispatch={dispatch}
        />
      )}

      <TxnProcessModal adminTxnState={adminTxnState} dispatch={dispatch} />
    </div>
  );
}

export default DurationRecordsCard;
