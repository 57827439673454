import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
// contexts
import { EthProviderContext } from "context/ethProviderContext";
import { AuthContext } from "context/authContext";
// hooks
import { useForm } from "react-hook-form";
import { useContracts } from "hooks/useContracts";
// utils
import { toast } from "react-toastify";
import { sendTransaction } from "services/contract.service";
import TxnProcessModal from "../TxnProcessModal";
import { useState } from "react";

function AddDurationModal(props) {
  const {
    durationTerms,
    setIsUpdated,
    isShownAddModal,
    setIsShownAddModal,
    adminTxnState,
    dispatch,
  } = props;

  const [durationSeconds, setDurationSeconds] = useState(0);
  const [minSeconds, setMinSeconds] = useState(0);
  const { authState } = useContext(AuthContext);
  const { ethProviderState } = useContext(EthProviderContext);

  const [, stakingContract] = useContracts();

  const { register, handleSubmit, reset } = useForm();

  const submit = async (data) => {
    if (!data.period || !data.apy || !data.minStaking) {
      toast.error("Please fill out all input fields");
      return;
    }

    if (Number(data.period) < Number(data.minStaking)) {
      toast.error(
        "Please ensure Min Staking Period is lower than Contract Duration"
      );
      return;
    }

    const periodInSeconds = Math.floor(data.period * 24 * 60 * 60);
    const minStakingInSeconds = Math.floor(data.minStaking * 24 * 60 * 60);
    if (durationTerms.find((term) => term.period === periodInSeconds)) {
      toast.error("Please ensure the duration has not been used");
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods
        .addDurationTerm(periodInSeconds, data.apy, minStakingInSeconds)
        .encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const addDurationTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      reset({
        period: data.period,
        apy: data.apy,
        minStaking: data.minStaking,
      });
      dispatch({ type: "CONFIRM", payload: addDurationTxn });
    } catch (err) {

      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
    // window.location.reload();
    setIsShownAddModal(false);
  };

  return (
    <div>
      <Modal show={isShownAddModal} onHide={() => setIsShownAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(submit)}>
            <div>
              <label className="float-left">
                <b>Contract Duration</b>
              </label>
            </div>
            <div className="input-group">
              <input
                type="number"
                placeholder="0"
                className="form-control form-control-lg"
                {...register("period")}
                required
                onChange={(e) =>
                  setDurationSeconds(Math.floor(e.target.value * 24 * 60 * 60))
                }
              />
              <div className="input-group-append">
                <div className="input-group-text">Day(s)</div>
              </div>
            </div>
            <small>
              Convert to Seconds: <b>{durationSeconds}</b> seconds
            </small>

            <div className="mt-4">
              <label className="float-left">
                <b>APY</b>
              </label>
            </div>
            <div className="input-group mb-4">
              <input
                min="0"
                type="number"
                className="form-control form-control-lg"
                placeholder="0"
                {...register("apy")}
                required
              />
              <div className="input-group-append">
                <div className="input-group-text">%</div>
              </div>
            </div>
            <div>
              <label className="float-left">
                <b>Min Staking Period</b>
              </label>
            </div>
            <div className="input-group">
              <input
                type="number"
                min="0"
                className="form-control form-control-lg"
                placeholder="0"
                {...register("minStaking")}
                required
                onChange={(e) =>
                  setMinSeconds(Math.floor(e.target.value * 24 * 60 * 60))
                }
              />
              <div className="input-group-append">
                <div className="input-group-text">Day(s)</div>
              </div>
            </div>
            <small>
              Convert to Seconds: <b>{minSeconds}</b> seconds
            </small>

            <div className="my-4 text-center">
              <button
                className="btn btn-lg w-50 bg-primary-color text-white rounded-pill"
                onClick={() => handleSubmit()}
              >
                Add Contract
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button variant="secondary" onClick={() => setIsShownAddModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <TxnProcessModal adminTxnState={adminTxnState} dispatch={dispatch} />
    </div>
  );
}

export default AddDurationModal;
