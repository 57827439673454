import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// hooks & reducers
import { useWeb3 } from "hooks/useWeb3";
// utils
import moment from "moment";
import { durationFormatter, durationToDaysHours } from "utils/format";

function WithdrawnRecordsCard(props) {
  const { withdrawnRecords } = props;

  const [inactiveRecords, setInactiveRecords] = useState([]);
  const [totalStakeAmount, setTotalStakeAmount] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);

  const [noDataText, setNoDataText] = useState();
  const [updateCount, setUpdateCount] = useState(0);

  const web3 = useWeb3();

  useEffect(() => {
    if (web3) {
      const tempInactiveRecords = [];
      let tempTotalAmount = 0;
      let tempTotalRewards = 0;
      withdrawnRecords.forEach((record) => {
        // Setup records details (may refine in the future)
        const timeDiff = moment
          .unix(record.contractEnd)
          .diff(moment.unix(record.contractStart), "seconds");
        const timeDiffText = `${durationToDaysHours(timeDiff)}`;
        const formattedStartDate = moment
          .unix(record.contractStart)
          .format("DD-MMM-YYYY HH:mm");
        const fmtExactEndDate = moment
          .unix(record.contractEnd)
          .format("DD-MMM-YYYY  HH:mm");
        const fmtContractEndDate =
          record.contractDuration === "0"
            ? "Anytime"
            : moment
                .unix(record.contractStart)
                .add(record.contractDuration, "seconds")
                .format("DD-MMM-YYYY HH:mm");
        let contractDuration = "N/A";
        if (record.contractDuration === "0") {
          contractDuration = "Flexible";
        } else {
          contractDuration = `${durationFormatter(
            Number(record.contractDuration)
          ).toLocaleString(undefined, { maximumFractionDigits: 4 })} Days`;
        }
        const stakeAmount = web3.utils.fromWei(record.stakeAmount.toString());
        const stakePerAPY = record.perAPY.toString();
        const reward = web3.utils.fromWei(record.receivedReward.toString());

        tempTotalAmount += Number(stakeAmount);
        tempTotalRewards += Number(reward);
        tempInactiveRecords.push({
          contractId: record.contractId,
          amount: Number(stakeAmount).toLocaleString(undefined, {
            maximumFractionDigits: 4,
          }),
          perAPY: Number(stakePerAPY).toLocaleString(undefined, {
            maximumFractionDigits: 4,
          }),
          contractDuration: contractDuration,
          timeStaked: timeDiffText,
          formattedStartDate: formattedStartDate,
          fmtExactEndDate: fmtExactEndDate,
          fmtContractEndDate: fmtContractEndDate,
          receivedReward: Number(reward).toLocaleString(undefined, {
            maximumFractionDigits: 4,
          }),
        });
      });
      setTotalStakeAmount(tempTotalAmount);
      setTotalRewards(tempTotalRewards);
      setInactiveRecords(tempInactiveRecords);
    }
  }, [withdrawnRecords, web3]);

  const options = {
    noDataText: noDataText,
  };

  useEffect(() => {
    setUpdateCount((prevUpdateCount) => prevUpdateCount + 1);
    setNoDataText("Table data is still being loaded. Please wait...");
    if (withdrawnRecords.length === 0 && updateCount >= 1) {
      setNoDataText("There is no data to display");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawnRecords]);

  return (
    <div className="row justify-content-center px-3 my-3">
      <div className="col-12 text-center">
        <h2 className="tableHeading">Withdrawn Staking</h2>
      </div>
      <div id="card" className="card my-4 mx-3 p-2 staketables">
        <div>
          <BootstrapTable
            data={inactiveRecords}
            options={options}
            bordered={false}
            hover
            className="mb-3"
          >
            <TableHeaderColumn dataField="contractId" isKey hidden>
              Contract ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="amount"
              dataAlign="center"
              width="120"
              thStyle={{ whiteSpace: "normal" }}
            >
              Staking Amount
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="perAPY"
              dataAlign="center"
              width="120"
              thStyle={{ whiteSpace: "normal" }}
            >
              APY %
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="receivedReward"
              dataAlign="center"
              width="120"
              thStyle={{ whiteSpace: "normal" }}
            >
              Received Reward
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="contractDuration"
              dataAlign="center"
              width="120"
              thStyle={{ whiteSpace: "normal" }}
            >
              Contract Duration
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="timeStaked"
              dataAlign="center"
              width="150"
              thStyle={{ whiteSpace: "normal" }}
              tdStyle={{ whiteSpace: "normal" }}
            >
              Time Staked
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="formattedStartDate"
              dataAlign="center"
              width="180"
              tdStyle={{ whiteSpace: "normal" }}
            >
              Start Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="fmtExactEndDate"
              dataAlign="center"
              width="180"
              tdStyle={{ whiteSpace: "normal" }}
            >
              Exact End Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="fmtContractEndDate"
              dataAlign="center"
              width="180"
              tdStyle={{ whiteSpace: "normal" }}
            >
              Original End Date
            </TableHeaderColumn>
          </BootstrapTable>
        </div>

        <div className="row text-center">
          <div className="col-md-4 col-12 font-weight-bold tableTotal pt-2">
            Total Withdrawn:
            <br />
            {withdrawnRecords.length}
          </div>

          <div className="col-md-4 col-12 font-weight-bold tableTotal pt-2">
            Total Staked Amount:
            <br />
            {Number(totalStakeAmount).toLocaleString(undefined, {
              maximumFractionDigits: 4,
            })}
            &nbsp;
            {process.env.REACT_APP_TOKENSYMBOL}
          </div>

          <div className="col-md-4 col-12 font-weight-bold tableTotal pt-2">
            Total Rewards:
            <br />
            {Number(totalRewards).toLocaleString(undefined, {
              maximumFractionDigits: 4,
            })}
            &nbsp;
            {process.env.REACT_APP_TOKENSYMBOL}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithdrawnRecordsCard;
