import React, { useContext, useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// hooks & reducers
import { useWeb3 } from "hooks/useWeb3";
import { useContracts } from "hooks/useContracts";
// context
import { AuthContext } from "context/authContext";
// utils
import moment from "moment";
import {
  getAllWithdrawnTerms,
  getAllWithdrawnTermsLength,
} from "services/contract.service";
import { durationFormatter, durationToDaysHours } from "utils/format";

const RECORDS_PER_TIME = 20;

function WithdrawnRecordsCard() {
  const [currentOffset, setCurrentOffset] = useState(0);
  const [allWithdrawnTermsLength, setAllWithdrawnTermsLength] = useState(0);
  const [tmpTerms, setTmpTerms] = useState([]);
  const [inactiveRecords, setInactiveRecords] = useState([]);
  const [totalStakeAmount, setTotalStakeAmount] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [last, setLast] = useState(false);

  const web3 = useWeb3();
  const [, stakingContract] = useContracts();

  const [noDataText, setNoDataText] = useState();
  const [updateCount, setUpdateCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false);

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    async function getWithdrawnTermsLength() {
      try {
        const tmp = await getAllWithdrawnTermsLength(
          stakingContract,
          authState.accountPoly
        );
        setLast(tmp <= RECORDS_PER_TIME);
        setAllWithdrawnTermsLength(tmp);
      } catch (err) {
        console.error(err);
      }
    }
    async function getInitRecords() {
      try {
        const tmpTerms = await getAllWithdrawnTerms(
          stakingContract,
          0,
          authState.accountPoly
        );
        setTmpTerms(tmpTerms);
        setUpdated(true);
      } catch (err) {
        console.error(err);
      }
    }
    if (stakingContract && authState.accountPoly && loading) {
      getWithdrawnTermsLength();
      getInitRecords(0);
      setLoading(false);
    }
  }, [stakingContract, authState.accountPoly, loading]);

  useEffect(() => {
    let tempTotalAmount = 0;
    let tempTotalRewards = 0;
    if (web3 && updated) {
      const tempInactiveRecords = tmpTerms
        ? tmpTerms.map((record) => {
            // Setup records details (may refine in the future)
            const timeDiff = moment
              .unix(record.contractEnd)
              .diff(moment.unix(record.contractStart), "seconds");
            const timeDiffText = `${durationToDaysHours(timeDiff)}`;
            const formattedStartDate = moment
              .unix(record.contractStart)
              .format("DD-MMM-YYYY HH:mm");
            const fmtExactEndDate = moment
              .unix(record.contractEnd)
              .format("DD-MMM-YYYY  HH:mm");
            const fmtContractEndDate =
              record.contractDuration === "0"
                ? "Anytime"
                : moment
                    .unix(record.contractStart)
                    .add(record.contractDuration, "d")
                    .format("DD-MMM-YYYY HH:mm");
            let contractDuration = "N/A";
            if (record.contractDuration === "0") {
              contractDuration = "Flexible";
            } else {
              contractDuration = `${durationFormatter(
                record.contractDuration
              )} Days`;
            }
            const stakeAmount = web3.utils.fromWei(
              record.stakeAmount.toString()
            );
            const reward = web3.utils.fromWei(record.receivedReward.toString());

            tempTotalAmount += Number(stakeAmount);
            tempTotalRewards += Number(reward);
            return {
              contractId: record.contractId,
              walletAddress: record.stakerAddress,
              amount: stakeAmount,
              contractDuration: contractDuration,
              timeStaked: timeDiffText,
              formattedStartDate: formattedStartDate,
              fmtExactEndDate: fmtExactEndDate,
              fmtContractEndDate: fmtContractEndDate,
              receivedReward: Number(reward).toFixed(4),
              status: record.isWithdrawn ? "Withdrawn" : "Staking",
            };
          })
        : [];
      setTotalStakeAmount(tempTotalAmount);
      setTotalRewards(tempTotalRewards);
      const appendedRecords = [...inactiveRecords, ...tempInactiveRecords];
      setInactiveRecords(appendedRecords);
      setUpdated(false);
    }
  }, [tmpTerms, web3, inactiveRecords, updated]);

  const options = {
    noDataText: noDataText,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10,
  };

  useEffect(() => {
    if (inactiveRecords) {
      if (updateCount === 0) {
        const tmp = updateCount + 1;
        setUpdateCount(tmp);
        setNoDataText("Table data is still being loaded. Please wait...");
      } else if (inactiveRecords.length === 0 && updateCount === 1) {
        setNoDataText("There is no data to display");
      }
    }
  }, [inactiveRecords, updateCount]);

  const getRecords = async (nextOffset) => {
    try {
      const tmpTerms = await getAllWithdrawnTerms(
        stakingContract,
        nextOffset,
        authState.accountPoly
      );
      setTmpTerms(tmpTerms);
      setUpdated(true);
    } catch (err) {
      console.error(err);
    }
  };

  const loadMoreRecords = async () => {
    const nextOffset = currentOffset + 1;
    if (allWithdrawnTermsLength >= nextOffset * RECORDS_PER_TIME) {
      await getRecords(nextOffset);
      setCurrentOffset(nextOffset);
    }
    if (allWithdrawnTermsLength < (nextOffset + 1) * RECORDS_PER_TIME) {
      setLast(true);
    }
  };

  return (
    <div className="card p-2 my-4 mx-auto scroll-box withdrawn-card">
      <div className="d-flex justify-content-between align-items-center">
        <div className="my-3 px-3 h2">Withdrawn Terms:</div>
        <button
          disabled={last}
          className="btn w-25 bg-primary-color text-white rounded-pill mr-3"
          onClick={() => loadMoreRecords()}
        >
          {last ? "Finish Loading Records" : "Load More"}
        </button>
      </div>
      <div className="h6 pl-4">
        <b>{inactiveRecords.length}</b> out of <b>{allWithdrawnTermsLength}</b>
        &nbsp; Terms Loaded
      </div>

      <div>
        <BootstrapTable
          data={inactiveRecords}
          bordered={false}
          options={options}
          pagination
          hover
          className="mb-3"
        >
          <TableHeaderColumn
            dataField="contractId"
            width="120"
            dataAlign="center"
            isKey
          >
            Contract ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="walletAddress"
            width="250"
            dataAlign="center"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Wallet Address
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="amount"
            dataAlign="center"
            width="120"
            thStyle={{ whiteSpace: "normal" }}
          >
            Staking Amount
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="receivedReward"
            dataAlign="center"
            width="120"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Received Reward
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="contractDuration"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Contract Duration
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="timeStaked"
            dataAlign="center"
            width="150"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Time Staked
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="formattedStartDate"
            dataAlign="center"
            width="150"
            tdStyle={{ whiteSpace: "normal" }}
          >
            Start Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="fmtExactEndDate"
            dataAlign="center"
            width="200"
            tdStyle={{ whiteSpace: "normal" }}
          >
            Exact End Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="fmtContractEndDate"
            dataAlign="center"
            width="200"
            tdStyle={{ whiteSpace: "normal" }}
          >
            Original End Date
          </TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataAlign="center" width="120">
            status
          </TableHeaderColumn>
        </BootstrapTable>
      </div>

      <div className="d-flex justify-content-between align-items-center px-2">
        <div className="text-black font-weight-bold">
          Loaded Withdrawn Terms: {inactiveRecords?.length}
        </div>

        <div className="text-black font-weight-bold">
          Loaded Staked Amount: {Number(totalStakeAmount).toFixed(4)}&nbsp;
          {process.env.REACT_APP_TOKENSYMBOL}
        </div>

        <div className="text-black font-weight-bold">
          Loaded Rewards: {Number(totalRewards).toFixed(4)}{" "}
          {process.env.REACT_APP_TOKENSYMBOL}
        </div>
      </div>
    </div>
  );
}

export default WithdrawnRecordsCard;
