import React, { useState, useContext, useEffect } from "react";
// context
import { AuthContext } from "context/authContext";
import { EthProviderContext } from "context/ethProviderContext";
// hooks
import { useWeb3 } from "hooks/useWeb3";
import { useContracts, useStakingData } from "hooks/useContracts";
// utils
import { toast } from "react-toastify";
import {
  sendStakeApproveTransaction,
  sendTransaction,
  awaitTransaction,
} from "services/contract.service";
// images
import DBDToken from "assets/DBDTokenLogo.png";
import { Modal } from "react-bootstrap";
import ConnectModal from "components/main/ConnectModal";

function PolyGeneralSettingsCard(props) {
  const { adminTxnState, dispatch } = props;

  const [adminConnected, setAdminConnected] = useState(false);

  const [rewardAmount, setRewardAmount] = useState(0);
  const [newMaxPoolBalance, setNewMaxPoolBalance] = useState(0);
  const [newMaxPerStakingAmount, setNewMaxPerStakingAmount] = useState(0);
  const [newMinPerStakingAmount, setNewMinPerStakingAmount] = useState(0);
  const [newEarlyApy, setNewEarlyApy] = useState(0);
  const [newExtraPeriodApy, setNewExtraPeriodApy] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isShownUnstake, setIsShownUnstake] = useState(false);
  const [connected, setConnected] = useState(false);
  const [isShownPoly, setIsShownPoly] = useState(false);

  const { authState, updateAdminPoly } = useContext(AuthContext);
  const { ethProviderState } = useContext(EthProviderContext);

  const web3 = useWeb3();
  const [tokenContract, stakingContract] = useContracts();
  const stakingData = useStakingData(stakingContract, isUpdated);

  useEffect(() => {
    async function updateFeilds() {
      await awaitTransaction(ethProviderState.provider, adminTxnState.txnHash);
      dispatch({ type: "FINISH" });
      toast.success(
        "Transaction has been successful. The fields will be updated in a few seconds"
      );
      setIsUpdated(false);
    }
    if (adminTxnState.txnHash && isUpdated) {
      updateFeilds();
    }
  }, [adminTxnState.txnHash, isUpdated, ethProviderState.provider]);

  useEffect(() => {
    setAdminConnected(authState.isAdminPoly);
  }, [authState.isAdminPoly]);

  const toggleStake = async () => {
    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods.toggleStaking().encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const toggleStakeTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      dispatch({ type: "CONFIRM", payload: toggleStakeTxn });
    } catch (err) {
      console.error(err);
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const addToRewardPool = async () => {
    if (rewardAmount <= 0) {
      toast.error("Please type in a valid amount to add to the reward pool");
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const rewardAmountWei = web3.utils.toWei(rewardAmount.toString());
      const approvedTxn = await sendStakeApproveTransaction(
        ethProviderState.provider,
        tokenContract,
        stakingContract,
        authState.accountPoly,
        rewardAmount
      );
      if (approvedTxn && approvedTxn.startsWith("0x")) {
        const transactionRes = await awaitTransaction(
          ethProviderState.provider,
          approvedTxn
        );
        if (!transactionRes.status) {
          throw new Error("Something wrong with the approve transaction.");
        }
      }
      const transaction = stakingContract.methods
        .addToRewardPool(rewardAmountWei)
        .encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const addRewardTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      dispatch({ type: "CONFIRM", payload: addRewardTxn });
    } catch (err) {
      console.error(err);;
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const removeFromRewardPool = async () => {
    if (rewardAmount <= 0) {
      toast.error("Please type in a valid amount to remove from reward pool");
      return;
    }
    const rewardAmountWei = web3.utils.toWei(rewardAmount.toString());
    if (rewardAmountWei > stakingData.rewardPoolBalance) {
      toast.error(
        "Reward amount requested is more than the reward amount in rewards pool"
      );
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods
        .removeFromRewardPool(rewardAmountWei)
        .encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const removeRewardTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      dispatch({ type: "CONFIRM", payload: removeRewardTxn });
    } catch (err) {
      console.error(err);;
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const updateMaxPoolBalance = async () => {
    if (
      newMaxPoolBalance < 0 &&
      newMaxPoolBalance !== stakingData.maxPoolBalance
    ) {
      toast.error(
        "Please type in a valid amount to update to the Maximum Pool balance"
      );
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const newMaxPoolBalanceWei = web3.utils.toWei(newMaxPoolBalance);
      const transaction = stakingContract.methods
        .setMaxPoolBalance(newMaxPoolBalanceWei)
        .encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const updateMaxPoolTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      dispatch({ type: "CONFIRM", payload: updateMaxPoolTxn });
    } catch (err) {
      console.error(err);;
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const updateMaxPerStakingAmt = async () => {
    if (newMaxPerStakingAmount <= 0) {
      toast.error("Please enter the amount larger than 0");
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods
        .setMaxPerStakeAmount(
          web3.utils.toWei(newMaxPerStakingAmount.toString(), "Ether")
        )
        .encodeABI();
      const txnParameters = {
        to: stakingContract._address,
        from: authState.accountPoly,
        data: transaction,
      };
      const updateMaxPerStakingAmountTxn = await sendTransaction(
        ethProviderState.provider,
        txnParameters
      );
      dispatch({ type: "CONFIRM", payload: updateMaxPerStakingAmountTxn });
    } catch (err) {
      console.error(err);;
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const updateMinPerStakingAmt = async () => {
    if (newMinPerStakingAmount <= 0) {
      toast.error("Please enter the amount larger than 0");
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods
        .setMinPerStakeAmount(
          web3.utils.toWei(newMinPerStakingAmount.toString(), "Ether")
        )
        .encodeABI();
      const txnParameters = {
        to: stakingContract._address,
        from: authState.accountPoly,
        data: transaction,
      };
      const updateMaxPerStakingAmountTxn = await sendTransaction(
        ethProviderState.provider,
        txnParameters
      );
      dispatch({ type: "CONFIRM", payload: updateMaxPerStakingAmountTxn });
    } catch (err) {
      console.error(err);;
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const updateEarlyUnstakeAPY = async () => {
    if (newEarlyApy < 0 || newEarlyApy >= 100) {
      toast.error("Please enter the APY between 0 to 100");
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods
        .setEarlyUnstakeAPY(newEarlyApy)
        .encodeABI();
      const txnParameters = {
        to: stakingContract._address,
        from: authState.accountPoly,
        data: transaction,
      };
      const updateEarlyApyTxn = await sendTransaction(
        ethProviderState.provider,
        txnParameters
      );
      dispatch({ type: "CONFIRM", payload: updateEarlyApyTxn });
    } catch (err) {
      console.error(err);;
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const updateExtraPeriodAPY = async () => {
    if (newExtraPeriodApy < 0 || newExtraPeriodApy >= 100) {
      toast.error("Please enter the APY between 0 to 100");
      return;
    }

    try {
      setIsUpdated(true);
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods
        .setExtraPeriodAPY(newExtraPeriodApy)
        .encodeABI();
      const txnParameters = {
        to: stakingContract._address,
        from: authState.accountPoly,
        data: transaction,
      };
      const updateExtraApyTxn = await sendTransaction(
        ethProviderState.provider,
        txnParameters
      );
      dispatch({ type: "CONFIRM", payload: updateExtraApyTxn });
    } catch (err) {
      console.error(err);;
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const unstakeAll = async () => {
    try {
      dispatch({ type: "PROCESSING", payload: true });
      const transaction = stakingContract.methods.unstakeAll().encodeABI();
      const transactionParameters = {
        to: stakingContract._address, // Required except during contract publications.
        from: authState.accountPoly, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };
      const unstakeAllTxn = await sendTransaction(
        ethProviderState.provider,
        transactionParameters
      );
      setIsUpdated(true);
      dispatch({ type: "CONFIRM", payload: unstakeAllTxn });
    } catch (err) {
      console.error(err);;
      toast.error(err.message);
      dispatch({ type: "FINISH" });
    }
  };

  const connectWallet = async () => {
    try {
      if (authState.isSigninPoly) {
        if (stakingData.owner !== authState.accountPoly) {
          toast.warn("Please select the admin account to sigin again.");
        } else {
          updateAdminPoly(true);
        }
      } else {
        if (!connected) {
          setIsShownPoly(true);
        }
        setConnected(true);
      }
    } catch (err) {
      console.error(err);;
      toast.error("Failed to connect to your account");
    }
  };

  return (
    <>
      <div className="card mb-4 mx-auto card-width">
        <div className="text-center">
          <h2 className="genSettingsHeading">Poly General Settings:</h2>
          <div className="mb-3">
            {!adminConnected && (
              <button
                className="btn mr-2 w-25 bg-primary-color text-white rounded-pill"
                onClick={() => connectWallet()}
              >
                {connected ? "Check the Admin" : "Connect Wallet"}
              </button>
            )}
          </div>
        </div>

        <div>
          <h5 className="my-3 text-center font-weight-bold">Staking Status</h5>
          <div className="mb-4 d-flex justify-content-center">
            <button
              type="button"
              disabled={!adminConnected}
              className="btn w-50 bg-primary-color text-white rounded-pill"
              onClick={() => toggleStake()}
            >
              {stakingData.isPaused ? "Continue staking" : "Pause staking"}
            </button>
          </div>

          {/* Reward Pool Balance Update */}
          <div className="mx-4">
            <div>
              <label className="float-left">
                <b>Reward Tokens</b>
              </label>
              <span className="float-right text-muted">
                Reward Balance:&nbsp;
                {web3
                  ? web3.utils.fromWei(
                      stakingData.rewardPoolBalance.toString(),
                      "Ether"
                    )
                  : 0}
              </span>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                className="form-control form-control-lg"
                placeholder={
                  web3
                    ? web3.utils.fromWei(
                        stakingData.rewardPoolBalance.toString(),
                        "Ether"
                      )
                    : 0
                }
                onChange={(e) => setRewardAmount(e.currentTarget.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <img src={DBDToken} height="32" alt="DBD" />
                  &nbsp;&nbsp;&nbsp;DBD
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-3">
            <button
              disabled={!adminConnected}
              className="btn w-25 mr-4 bg-primary-color text-white rounded-pill"
              onClick={() => addToRewardPool()}
            >
              Add
            </button>
            <button
              disabled={!adminConnected}
              style={{ whiteSpace: "nowrap" }}
              className="btn w-25 bg-primary-color text-white rounded-pill"
              onClick={() => removeFromRewardPool()}
            >
              Remove
            </button>
          </div>

          {/* Max Pool Balance Update */}
          <div className="mx-4">
            <div>
              <label className="float-left">
                <b>Max Pool Balance</b>
              </label>
              <span className="float-right text-muted">
                Current Maximum Pool Balance:&nbsp;
                {web3
                  ? web3.utils.fromWei(
                      stakingData.maxPoolBalance.toString(),
                      "Ether"
                    )
                  : 0}
              </span>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                className="form-control form-control-lg"
                placeholder={
                  web3
                    ? web3.utils.fromWei(
                        stakingData.maxPoolBalance.toString(),
                        "Ether"
                      )
                    : 0
                }
                onChange={(e) => setNewMaxPoolBalance(e.currentTarget.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <img src={DBDToken} height="32" alt="DBD" />
                  &nbsp;&nbsp;&nbsp;DBD
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-3">
            <button
              disabled={!adminConnected}
              className="btn w-25 bg-primary-color text-white rounded-pill"
              onClick={() => updateMaxPoolBalance()}
            >
              Update
            </button>
          </div>

          {/* Max Per Staking Amount Update */}
          <div className="mx-4">
            <div>
              <label className="float-left">
                <b>Max Staking Amount Per Staking</b>
              </label>
              <span className="float-right text-muted">
                Current Max per Staking Amount:&nbsp;
                {web3
                  ? web3.utils.fromWei(stakingData.maxPerStakeAmount.toString())
                  : 0}
              </span>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                step={1}
                className="form-control form-control-lg"
                placeholder={
                  web3
                    ? web3.utils.fromWei(
                        stakingData.maxPerStakeAmount.toString()
                      )
                    : 0
                }
                onChange={(e) =>
                  setNewMaxPerStakingAmount(e.currentTarget.value)
                }
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <img src={DBDToken} height="32" alt="DBD" />
                  &nbsp;&nbsp;&nbsp;DBD
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-3">
            <button
              disabled={!adminConnected}
              className="btn w-25 bg-primary-color text-white rounded-pill"
              onClick={() => updateMaxPerStakingAmt()}
            >
              Update
            </button>
          </div>

          {/* Min Per Staking Amount Update */}
          <div className="mx-4">
            <div>
              <label className="float-left">
                <b>Min Staking Amount Per Staking</b>
              </label>
              <span className="float-right text-muted">
                Current Min per Staking Amount:&nbsp;
                {web3
                  ? web3.utils.fromWei(stakingData.minPerStakeAmount.toString())
                  : 0}
              </span>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                step={1}
                className="form-control form-control-lg"
                placeholder={
                  web3
                    ? web3.utils.fromWei(
                        stakingData.minPerStakeAmount.toString()
                      )
                    : 0
                }
                onChange={(e) =>
                  setNewMinPerStakingAmount(e.currentTarget.value)
                }
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <img src={DBDToken} height="32" alt="DBD" />
                  &nbsp;&nbsp;&nbsp;DBD
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-3">
            <button
              disabled={!adminConnected}
              className="btn w-25 bg-primary-color text-white rounded-pill"
              onClick={() => updateMinPerStakingAmt()}
            >
              Update
            </button>
          </div>

          {/* Early Unstaking APY Update */}
          <div className="mx-4">
            <div>
              <label className="float-left">
                <b>Early Unstake APY</b>
              </label>
              <span className="float-right text-muted">
                Current Early Unstake APY: {stakingData.earlyUnstakeApy}%
              </span>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                step={1}
                className="form-control form-control-lg"
                placeholder={stakingData.earlyUnstakeApy}
                onChange={(e) => setNewEarlyApy(e.currentTarget.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">%</div>
              </div>
            </div>
          </div>
          <div className="text-center mb-3">
            <button
              disabled={!adminConnected}
              className="btn w-25 bg-primary-color text-white rounded-pill"
              onClick={() => updateEarlyUnstakeAPY()}
            >
              Update
            </button>
          </div>

          {/* Extra Staking Period APY Update */}
          <div className="mx-4">
            <div>
              <label className="float-left">
                <b>Extra Period APY</b>
              </label>
              <span className="float-right text-muted">
                Current Extra Period APY: {stakingData.extraPeriodApy}%
              </span>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                step={1}
                className="form-control form-control-lg"
                placeholder={stakingData.extraPeriodApy}
                onChange={(e) => setNewExtraPeriodApy(e.currentTarget.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">%</div>
              </div>
            </div>
          </div>
          <div className="text-center mb-4">
            <button
              disabled={!adminConnected}
              className="btn w-25 bg-primary-color text-white rounded-pill"
              onClick={() => updateExtraPeriodAPY()}
            >
              Update
            </button>
          </div>

          {/* Panic Button: unstake all contracts */}
          <h5 className="mb-2 text-center font-weight-bold">Panic Button</h5>
          <div className="mb-4 d-flex justify-content-center">
            <button
              type="button"
              disabled={!adminConnected}
              className="btn w-50 bg-primary-color text-white rounded-pill"
              onClick={() => setIsShownUnstake(true)}
            >
              Unstake All
            </button>
          </div>
        </div>

        <Modal show={isShownUnstake} centered>
          <Modal.Body className="p-4" style={{ fontSize: "x-large" }}>
            You cannot reverse this action, please consider.&nbsp;
            <b>Are you sure to unstake all contract?</b>
          </Modal.Body>
          <Modal.Footer>
            <div className="mr-3">
              <button
                className="btn bg-secondary text-white"
                onClick={() => {
                  setIsShownUnstake(false);
                  unstakeAll();
                }}
              >
                Yes
              </button>
            </div>
            <div>
              <button
                className="btn bg-primary-color text-white"
                onClick={() => setIsShownUnstake(false)}
              >
                No
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <ConnectModal isShown={isShownPoly} setIsShown={setIsShownPoly} />
    </>
  );
}

export default PolyGeneralSettingsCard;
