import React from "react";
import { Link } from "react-router-dom";
// import PageNotFound from "assets/PageNotFound.png";

function NotFound() {
  return (
    <div className="text-center mt-5">
      {/* Image is too small, use text at the moment */}
      {/* <img src={PageNotFound} /> */}
      <div
        className="font-weight-bolder secondary-color"
        style={{ fontSize: "12rem" }}
      >
        404!
      </div>
      <div className="mb-3 text-secondary" style={{ fontSize: "2rem" }}>
        Page not found.
      </div>
      <div className="d-flex justify-content-center">
        <Link to="/" className="text-decoration-none">
          <button className="btn btn-block btn-primary px-4">
            Landing Page
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
