import React from "react";
import { Link, useLocation } from "react-router-dom";
import PolygonLogo from "assets/PolygonLogo.svg";
import AlgorandLogo from "assets/AlgorandLogo.svg";

function StakingTitleBar(props) {
  const { isAdmin, totalStaked, maxStakingPool, network } = props;
  const location = useLocation();

  return (
    <div className="container titlebar">
      {isAdmin && (
        <div className="mb-3 text-center">
          <Link
            to="/admin"
            state={{ network: location.pathname }}
            className="primary-color"
            children="Head to Admin Portal"
          />
        </div>
      )}

      <p className="heading">
        {location.pathname.includes("legacy")
          ? "Legacy Stake DBDv1"
          : location.pathname.includes("polygon")
          ? "Stake DBD"
          : "Stake DBDv2"}
      </p>

      {network === "Algorand" && (
        <div>
          <div className="networkText">
            <img alt="Algorand" src={AlgorandLogo} height="70"></img>
          </div>
          <div className="SwitchnetworkText">
            <a href="/">Switch networks</a>
          </div>
        </div>
      )}
      {network === "Polygon" && (
        <div>
          <div className="networkText">
            <img
              alt="Polygon"
              src={PolygonLogo}
              height="30"
              style={{ margin: "1.3rem" }}
            ></img>
          </div>
          <div className="SwitchnetworkText">
            <a href="/">Switch networks</a>
          </div>
        </div>
      )}
      <div className="content">
        <div className="row justify-content-center mx-0">
          <div className="col-sm-4 col-12">
            <div className="value mx-0 px-0 text-break">
              {totalStaked
                ? parseFloat(Number(totalStaked).toFixed(4)).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 4 }
                  )
                : 0}
              &nbsp;
              {process.env.REACT_APP_TOKENSYMBOL}
            </div>
            <div className="valueTitle">Total DBD Staked</div>
          </div>
          <div className="col-sm-4 col-12 mt-sm-0 mt-3 text-break">
            <div className="value">
              {maxStakingPool
                ? parseFloat(Number(maxStakingPool).toFixed(4)).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 4 }
                  )
                : 0}
              &nbsp;
              {process.env.REACT_APP_TOKENSYMBOL}
            </div>
            <div className="valueTitle">Staking Pool Limit</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StakingTitleBar;
