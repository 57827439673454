import { getAppliactionInfoById } from "services/contract.service";
import { toast } from "react-toastify";
import { formatNumber } from "utils/textFormater";
import algosdk from "algosdk";

export async function GetPoolDetails(appId) {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASEURL + "Stake/get/" + appId,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching address:", error);
    return null;
  }
}

export async function GetAddressFromAppId(appId) {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASEURL + "Stake/get/" + appId,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return data.applicationAddress;
  } catch (error) {
    console.error("Error fetching address:", error);
    return null;
  }
}

export async function getContractList(userAddress) {
  const initialData = await getContractListInitial();
  const blockchainData = await getContractListBlockchain(
    initialData,
    userAddress
  );

  return blockchainData;
}

// Only used in admin portal
async function getContractListBlockchain(data, userAddress) {
  let contractsData = [];
  for (let i = 0; i < data.length; i++) {
    let element = data[i];

    // Get blockchain data
    let contractData = await getAppliactionInfoById(
      Number(element.appId),
      element.applicationAddress,
      userAddress
    );

    const globalInfoString = JSON.stringify(contractData);
    const algoObj = JSON.parse(globalInfoString);

    contractsData.push({
      appId: element.appId,
      poolName: element.poolName,
      poolImageURL: element.poolImageURL,
      active: element.active,

      // Blockchain Data
      apy: algoObj.durationApy,
      totalLimit:
        formatNumber(
          element.poolName.toLowerCase().includes("nft")
            ? algosdk.algosToMicroalgos(Number(algoObj.totalStaked))
            : algoObj.totalStaked,
          2
        ) +
        " / " +
        algoObj.maxStakingPool,
      adminASA: algoObj.adminASA,
      staked: algoObj.staked,
      totalStaked: algoObj.totalStaked,
      stakeTokens: algoObj.stakeTokens,
    });
  }

  return contractsData;
}

export async function getContractListInitial() {
  const response = await fetch(process.env.REACT_APP_BASEURL + "Stake/getAll", {
    method: "GET",
  });

  const data = await response.json();

  let contractsData = [];
  for (let i = 0; i < data.length; i++) {
    let element = data[i];

    contractsData.push({
      appId: element.appId,
      applicationAddress: element.applicationAddress,
      poolName: element.displayName,
      poolImageURL:
        process.env.REACT_APP_BASEURL + "Attachment/" + element.attachmentId,
      active: !element.paused,

      // Gets filled in in getContractListBlockchainPerElement()
      apy: null,
      duration: "Loading...",
      adminASA: null,
      staked: null,
      totalStaked: null,
      stakeTokens: null,
    });
  }

  return contractsData;
}

export async function getContractListBlockchainPerElement(
  element,
  userAddress
) {
  // Get blockchain data
  let result = null;
  let contractData = await getAppliactionInfoById(
    Number(element.appId),
    element.applicationAddress,
    userAddress
  );

  const globalInfoString = JSON.stringify(contractData);
  const algoObj = JSON.parse(globalInfoString);

  let totalStaked = element.poolName.toLowerCase().includes("nft")
  ? algosdk.algosToMicroalgos(Number(algoObj.totalStaked))
  : algoObj.totalStaked;
  let percFull = formatNumber((Number(totalStaked) / Number(algoObj.maxStakingPool)) * 100, 2);

  result = {
    ...element,

    // Blockchain Data
    duration: `${formatNumber(algoObj.durationThreshold[0], 2)} Days`, // seconds to days
    rewardASA: algoObj.rewardASA,
    rewardRatio: algoObj.rewardRatio,
    apy: algoObj.durationApy,
    totalLimit: percFull + " %",
    adminASA: algoObj.adminASA,
    staked: algoObj.staked,
    totalStaked: algoObj.totalStaked,
    stakeTokens: algoObj.stakeTokens,
  };

  return result;
}

export async function UpdateContract(appId, body) {
  await fetch(process.env.REACT_APP_BASEURL + `Stake/update/${appId}`, {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      toast.error("Error calling backend");
      console.error(response);
      throw new Error("Error calling backend");
    }
  });
}

export async function GetSelectNFTs(nftIds, durationInDays) {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASEURL + `Stake/getSelectNFTs/${durationInDays}`,
      {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(nftIds),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching address:", error);
    return null;
  }
}

export async function InsurefiOptContractIn(appId, asaId) {
  try {
    await fetch(
      process.env.REACT_APP_BASEURL + "Stake/optContractIn/" + appId,
      {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(asaId),
      }
    );
  } catch (error) {
    console.error("Error fetching address:", error);
    throw error;
  }
}

export async function InsurefiOptContractOut(
  appId,
  asaId,
  usersAddress,
  tokenCreator
) {
  try {
    await fetch(
      process.env.REACT_APP_BASEURL + "Stake/optContractOut/" + appId,
      {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tokenId: asaId,
          UnstakersAddress: usersAddress,
          OptOutToo: tokenCreator,
        }),
      }
    );
  } catch (error) {
    console.error("Error fetching address:", error);
    return null; // No need to throw error as user has already opted out
  }
}
