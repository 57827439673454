import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { /*Button,*/ Modal } from "react-bootstrap";

function UnstakeModal(props) {
  const { unstakeState, dispatch, from } = props;

  const onClose = () => {
    if (unstakeState.isConfirmed) {
      dispatch({ type: "FINISH" });
    } else {
      // dispatch({ type: 'UNSTAKING', payload: false });
    }
  };

  return (
    <Modal show={unstakeState.isUnstaking} onHide={() => onClose()} centered>
      <Modal.Header closeButton={unstakeState.isConfirmed} className="border-0">
        {/* <Modal.Title>DBD Stake to Earn</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="pt-3 pb-4">
        {unstakeState.isConfirmed ? (
          <>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success stakeModalIcon"
              />
            </div>
            <div className="mt-3 text-center" style={{ fontSize: "20px" }}>
              {from === "algo"
                ? " Transaction Successful"
                : " Transaction Submitted"}
            </div>
            {unstakeState.unstakeTxn && (
              <div
                className="text-primary text-center"
                style={{ fontSize: "14px" }}
              >
                <a
                  href={
                    process.env.REACT_APP_BLOCKEXPLOERURL + "/tx/" +
                    unstakeState.unstakeTxn
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Block Explorer
                </a>
              </div>
            )}
            <p
              className="text-center"
            >
              {from === "algo"
                ? "Your transaction is successful."
                : "You will be notified when the transaction is successful."}
            </p>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border text-danger my-2 stakeModalIcon"
                role="status"
              >
                <span className="sr-only">
                  {from === "algo"
                    ? "Waiting for Signature and Algorand Confirmation"
                    : "Waiting for Comfirmation"}
                </span>
              </div>
            </div>
            <h5 className="mt-3 text-center">
              {from === "algo"
                ? "Waiting for Signature and Algorand Confirmation"
                : "Waiting for Comfirmation"}
            </h5>
            <p
              className="text-center"
            >
              {from === "algo"
                ? "Sign this transaction in your wallet, then wait for the algorand confirmation"
                : "Confirm this transaction in your wallet"}
            </p>
          </>
        )}
      </Modal.Body>
      {/* <Modal.Footer className='d-flex justify-content-center'>
        <Button
          className='w-50 rounded bg-primary-color'
          onClick={() => onClose()}
        >
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default UnstakeModal;
