import React, { useEffect, useState } from "react";

function PolicyImage({ item }) {

  const [assetTypes, setAssetTypes] = useState([]);
  const [events, setEvents] = useState([]);

  // Sets assetTypes and events
  useEffect(() => {
    item.attributes.forEach(attribute => {
      if (attribute.attributeKey === "Asset Types")
      {
        setAssetTypes(attribute.attributeValues)
      } else if (attribute.attributeKey === "Events")
      {
        setEvents(attribute.attributeValues)
      }
    });
  }, []);

  return (
    <>
      <div className="nft-container">
        {/* NFT Thumbnail */}
        <div className="thumbnail">

          <img
              className="edition-dp"
              src={
                item.imageURL ||
                process.env.REACT_APP_BASEURL + "Attachment/" + item.attachmentId
              }
              alt={item.title}
            />
        </div>
        {/* End of NFT Thumbnail */}

        {/* NFT Details */}
        <div className="nft-att">
          <div className="row">
            <div className="col-12 pt-2 pb-3">

            <div className="asset-cat mb-2">
              <h6>Asset Type</h6>
              <p>
              {assetTypes.map((type) => (
                <span key={type}>
                  {type}
                </span>
              ))}
              </p>
            </div>
            <div className="event-cat">
              <h6>Events</h6>
              <p>
              {events.map((type) => (
                <span key={type}>
                  {type}
                </span>
              ))}
              </p>
            </div>

            </div>
          </div>
        </div>
        <div className="nft-details">
          <div className="row">
            <div className="col-12">
              <div className="title-wrapper">
                <h6 className="pt-2 mb-1 policy-title">{item.title}</h6>
              </div>
            </div>
          </div>

            </div>
            {/* End of NFT Details */}
        </div>
    </>
  );
}
export default PolicyImage;
