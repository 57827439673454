// web3
import Web3 from "web3";
// utils
import { toast } from "react-toastify";
import { chainParams, creatorStakeFunctionName } from "utils/constants";
// algo
import { pretty, refreshInfo } from "utils/reachHelpers";
import {
  generateApplicationCallTxn,
  generateTransferTransaction,
  signAndSendGroupTransactions,
  signAndSendSingleTransaction,
  tokenAccepted,
  acceptToken,
  GenerateDeployContractTxn,
  getBalance,
} from "utils/algoSDKHelpers";
import algosdk, { getApplicationAddress } from "algosdk";

import {
  InsurefiOptContractIn,
  InsurefiOptContractOut,
} from "utils/insurefiHelpers";
import { calculateAPY } from "utils/math";

const baseServer = process.env.REACT_APP_PURESTAKE_ADDRESS;
const port = "";
const token = "";
const algodClient = new algosdk.Algodv2(token, baseServer, port);

// get polygon staking data
export async function getAllStakingTermsLength(stakingContract, account) {
  try {
    const allStakingTermsLength = await stakingContract.methods
      .getAllStakingTermsLength()
      .call({ from: account });
    return allStakingTermsLength;
  } catch (err) {
    console.error(err);
    toast.error("Failed to retreive the all staking terms length");
  }
}

export async function getAllWithdrawnTermsLength(stakingContract, account) {
  try {
    const allWithdrawnTermsLength = await stakingContract.methods
      .getAllWithdrawnTermsLength()
      .call({ from: account });
    return allWithdrawnTermsLength;
  } catch (err) {
    console.error(err);
    toast.error("Failed to retreive the all withdrawn terms length");
  }
}

export async function getDurationTerms(stakingContract) {
  try {
    const durationTerms = await stakingContract.methods
      .getDurationTerms()
      .call();
    return durationTerms;
  } catch (err) {
    console.error(err);
    toast.error("Failed to retreive the duration terms");
  }
}

export async function getAllStakingTerms(stakingContract, nextOffset, account) {
  try {
    const allStakingTerms = await stakingContract.methods
      .getBatchStakingTerms(nextOffset)
      .call({ from: account });
    return allStakingTerms;
  } catch (err) {
    console.error(err);
    toast.error("Failed to retreive the staking terms");
  }
}

export async function getAllWithdrawnTerms(
  stakingContract,
  nextOffset,
  account
) {
  try {
    const allWithdrawnTerms = await stakingContract.methods
      .getBatchWithdrawnTerms(nextOffset)
      .call({ from: account });
    return allWithdrawnTerms;
  } catch (err) {
    console.error(err);
    toast.error("Failed to retreive the withdrawn terms");
  }
}

export async function addERC20Token(provider, address, symbol, imgURL) {
  try {
    if (provider) {
      const wasAdded = await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: address,
            symbol: symbol,
            decimals: 18,
            image: imgURL,
          },
        },
      });
      if (wasAdded) {
        toast.success(`Successfully added ${symbol} to your wallet`);
      } else {
      }
    } else {
      toast.info(
        "Cannot detect your metamask, please install Metamask to start staking"
      );
    }
  } catch (error) {
    toast.error("Failed to add the token to your metamask.");
  }
}

export async function getApprovedAmount(
  provider,
  tokenContract,
  stakingContract,
  account
) {
  if (!tokenContract || !stakingContract) {
    throw new Error("Contract is not loaded, unable to send transaction.");
  }

  try {
    if (provider) {
      const allowance = await tokenContract.methods
        .allowance(account, stakingContract._address)
        .call();
      return allowance;
    } else {
      throw new Error("Ehtereum Provider is not detected");
    }
  } catch (error) {
    throw error;
  }
}

export async function sendStakeApproveTransaction(
  provider,
  tokenContract,
  stakingContract,
  account,
  amount
) {
  if (!tokenContract || !stakingContract) {
    throw new Error("Contract is not loaded, unable to send transaction.");
  }
  if (!amount) amount = 0;

  try {
    if (provider) {
      const web3 = new Web3(provider);
      const chainId = await web3.eth.getChainId();
      if (chainId !== parseInt(process.env.REACT_APP_CHAINID, 16)) {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: chainParams,
        });
        throw new Error(
          "Incorrect Network Detected. Please Switch To Polygon Network to Continue."
        );
      }

      const allowance = await tokenContract.methods
        .allowance(account, stakingContract._address)
        .call();

      if (Number(amount) < Number(web3.utils.fromWei(allowance))) {
        return "Good to go!";
      }

      const approveValue = "100000000";
      const transaction = tokenContract.methods
        .approve(
          stakingContract._address,
          web3.utils.toWei(approveValue, "ether")
        )
        .encodeABI();
      //set up your Polygon transaction
      const transactionParameters = {
        to: tokenContract._address, // Required except during contract publications.
        from: account, // must match user's active address.
        data: transaction, //make call to NFT smart contract
      };

      //sign transaction via Metamask
      return await sendTransaction(provider, transactionParameters);
    } else {
      throw new Error("Ehtereum Provider is not detected");
    }
  } catch (error) {
    throw error;
  }
}

export async function sendTransaction(provider, transactionParameters) {
  try {
    if (provider) {
      const web3 = new Web3(provider);
      const chainId = await web3.eth.getChainId();
      if (chainId !== parseInt(process.env.REACT_APP_CHAINID, 16)) {
        provider.request({
          method: "wallet_addEthereumChain",
          params: chainParams,
        });
        throw new Error("Invalid Network");
      }

      const result = await provider.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      return result;
    } else {
      throw new Error("Ehtereum Provider is not detected");
    }
  } catch (error) {
    if (error.code === 4001) {
      throw new Error("Denied transaction signature.");
    } else if (error.code === -32603) {
      throw new Error("Transaction underpriced, please try again.");
    } else if (error.message === "Invalid Network") {
      throw new Error(
        "Incorrect Network Detected. Please Switch To Polygon Network to Continue."
      );
    }
    throw new Error(
      "Please ensure you are logged into your wallet and try again."
    );
  }
}

export async function awaitTransaction(provider, pendingTxHash) {
  const web3 = new Web3(provider);
  const transaction = await new Promise((resolve) =>
    web3.eth.subscribe("newBlockHeaders", async (error, event) => {
      try {
        const blockTxHashes = await web3.eth.getBlock(event.hash);
        if (blockTxHashes && blockTxHashes.transactions) {
          if (blockTxHashes.transactions.includes(pendingTxHash)) {
            const receipt = await web3.eth.getTransactionReceipt(pendingTxHash);
            resolve(receipt);
          }
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Failed to await the transaction. Please refresh the page after the transcation goes through."
        );
      }
    })
  );
  return transaction;
}

// ALGO Below
export async function callAPI(name, account, ctc, reach, ...args) {
  try {
    const res = await ctc.apis.Staker[name](...args);
    await refreshInfo(account, ctc, reach);
    return pretty(res);
  } catch (err) {
    console.error(err.message);
    throw Error(`Failed to call ${name} function`);
  }
}

export async function deployContract(
  address,
  provider,
  providerType,
  adminASA,
  rewardASA,
  minStake,
  maxStake,
  poolLimit,
  rewardRatio,
  duration,
  stakeTokenCreator
) {
  const args = [
    new Uint8Array(Buffer.from(creatorStakeFunctionName.create, "base64")),
    new Uint8Array(Buffer.from("AA==", "base64")), // index 0 in the foreignAssets array - adminASA
    new Uint8Array(Buffer.from(integerToBase64(minStake), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(maxStake), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(poolLimit), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(rewardRatio), "base64")),
    new Uint8Array(Buffer.from("AQ==", "base64")), // index 1 in the foreignAssets array - rewardASA
    new Uint8Array(Buffer.from(integerToBase64(duration), "base64")),
    new Uint8Array(Buffer.from(stringToBase64(stakeTokenCreator), "base64")),
  ];

  let creationTxn = await GenerateDeployContractTxn(address, args, [
    Number(adminASA),
    Number(rewardASA),
  ]);

  let result = await signAndSendSingleTransaction(
    creationTxn,
    address,
    provider,
    providerType
  );

  const transactionResponse = await algodClient
    .pendingTransactionInformation(result.txId)
    .do();
  const appId = transactionResponse["application-index"];
  const contractAddress = getApplicationAddress(appId);

  return {
    contractId: appId,
    contractAddress: contractAddress,
  };
}

export async function getAppliactionInfoById(appId, appAddress, userAddress) {
  // Global States
  const applicationInfo = await algodClient.getApplicationByID(appId).do();
  applicationInfo.params["global-state"].forEach((element) => {
    element.key = Buffer.from(element.key, "base64").toString();
    element.Label = element.key;
    delete element.key;
    if (element.value.type === 1) {
      element.Value = algosdk.encodeAddress(
        Buffer.from(element.value.bytes, "base64")
      );
    } else {
      element.Value = element.value.uint.toString();
    }
    delete element.value;
  });

  // Local States
  let appLocalInfo = null;
  const accountInfo =
    userAddress != null
      ? await algodClient.accountInformation(userAddress).do()
      : null;

  if (accountInfo?.["apps-local-state"].length > 0) {
    for (const element of accountInfo["apps-local-state"]) {
      if (element.id === Number(appId)) {
        appLocalInfo = element?.["key-value"].map((el) => {
          return {
            Label: Buffer.from(el.key, "base64").toString(),
            Value:
              el.value.type === 1
                ? algosdk.encodeAddress(Buffer.from(el.value.bytes, "base64"))
                : el.value.uint.toString(),
          };
        });
        break;
      }
    }
  }

  // Setting local state values
  let staked = -1;
  let userStakeEnd = 0;
  let currentRewardRatio = 0;
  let stakedToken = 0;

  if (userAddress && appLocalInfo) {
    staked = algosdk.microalgosToAlgos(
      Number(appLocalInfo.find((el) => el.Label === "currentStakeAmount").Value)
    );
    userStakeEnd = Number(
      appLocalInfo.find((el) => el.Label === "currentStakeEndDate").Value
    );
    currentRewardRatio = Number(
      appLocalInfo.find((el) => el.Label === "currentRewardRatio").Value
    );
    stakedToken = Number(
      appLocalInfo.find((el) => el.Label === "currentStakeASA").Value
    );
  }

  let appInfo = applicationInfo.params["global-state"];
  const maxStake = algosdk.microalgosToAlgos(
    Number(appInfo.find((el) => el.Label === "maxStake").Value)
  );
  const poolLimit = algosdk.microalgosToAlgos(
    Number(appInfo.find((el) => el.Label === "poolLimit").Value)
  );
  const adminASA = Number(appInfo.find((el) => el.Label === "adminASA").Value);
  const minStake = algosdk.microalgosToAlgos(
    Number(appInfo.find((el) => el.Label === "minStake").Value)
  );
  const totalStaked = algosdk.microalgosToAlgos(
    Number(appInfo.find((el) => el.Label === "totalStaked").Value)
  );
  const rewardASA = Number(
    appInfo.find((el) => el.Label === "rewardASA").Value
  );
  let duration = Number(appInfo.find((el) => el.Label === "duration").Value);
  let rewardRatio = Number(
    appInfo.find((el) => el.Label === "rewardRatio").Value
  );
  const paused = Number(appInfo.find((el) => el.Label === "paused").Value);
  const stakeCreatorAddress = appInfo.find(
    (el) => el.Label === "stakeTokenCreator"
  ).Value;
  let remainingRewards = await getBalance(appAddress, rewardASA);

  // If reward token is same as stake token then this needs to be accounted for
  const assetDetails = await algodClient.getAssetByID(rewardASA).do();
  if (assetDetails.params["creator"] === stakeCreatorAddress) {
    remainingRewards -= totalStaked;
  }

  // Pick most likely staking token
  const stakeCreatorAddressInfo = await algodClient
    .accountInformation(stakeCreatorAddress)
    .do();

  return {
    // Global states
    maxStakePerStake: maxStake,
    maxStakingPool: poolLimit,
    minStaking: minStake,
    remainingRewards: remainingRewards,
    stakeCreatorAddress: stakeCreatorAddress,
    totalStaked: totalStaked,
    rewardASA: rewardASA,
    adminASA: adminASA,
    stakingPaused: paused === 0 ? false : true, // Fix this up too
    rewardRatio: rewardRatio,

    // Local states
    staked: staked,
    stakedToken: stakedToken,
    userStakeEnd: userStakeEnd,
    userRewardRatio: currentRewardRatio,

    // Requires calculation
    userExpectedRewards: (currentRewardRatio * staked) / 100,
    durationThreshold: [duration / 86400], // seconds to days
    durationApy: [calculateAPY(duration, rewardRatio)],

    // Maybe values
    stakeTokens: stakeCreatorAddressInfo["created-assets"],
  };
}

// PyTeal Below
export async function callPyTealContract(functionName, ...args) {
  switch (functionName) {
    case "stakeAlgo":
      throw Error("No implementation error");
    case "stake":
      await stakeAsset(...args);
      break;
    case "stakeNFT":
      await stakeNFT(...args);
      break;
    case "unstake":
      await unstake(...args);
      break;
    case "unstakeNFT":
      await unstakeNFT(...args);
      break;
    case "updateSettings":
      await updateSettings(...args);
      break;
    case "setPaused":
      await setPaused(...args);
      break;
    case "opt_into_asset":
      await optContractIn(...args);
      break;
    case "opt_out_asset":
      await optContractOut(...args);
      break;
    case "migrateToken":
      await migrateToken(...args);
      break;
    default:
      throw Error(`Failed to call ${functionName} function`);
  }
}

async function stakeNFT(stakeAssetId, address, provider, providerType, appId) {
  // Send 0.102 to Contract Admin Wallet
  // 0.001 (Call to opt in from admin account) + 0.001 (contract opting in fee) + 0.1 (Holding amt)
  let transferTxn = await generateTransferTransaction(
    address,
    process.env.REACT_APP_STAKINGADMIN_ADDRESS,
    0.102,
    undefined,
    0,
    true
  );

  // Send transaction
  await signAndSendSingleTransaction(
    transferTxn,
    address,
    provider,
    providerType
  );

  // Call Insurefi to Opt contract into the token
  await InsurefiOptContractIn(appId, stakeAssetId);
  // call stakeAsset
  await stakeAsset(1, stakeAssetId, address, provider, providerType, appId);
}

async function unstakeNFT(
  stakeAssetId,
  rewardAssetId,
  address,
  provider,
  providerType,
  appId
) {
  // User transfers Algo's to call unstake - 0.003
  let transferTxn = await generateTransferTransaction(
    address,
    process.env.REACT_APP_STAKINGADMIN_ADDRESS,
    0.003,
    undefined,
    0,
    true
  );

  // Send transaction
  await signAndSendSingleTransaction(
    transferTxn,
    address,
    provider,
    providerType
  );

  // User unstakes
  await unstake(
    stakeAssetId,
    rewardAssetId,
    address,
    provider,
    providerType,
    appId
  );

  // Call Insurefi to opt contract out of that asset
  // Includes transfering user back locked algo - 0.1
  const assetDetails = await algodClient.getAssetByID(stakeAssetId).do();

  await InsurefiOptContractOut(
    appId,
    stakeAssetId,
    address,
    assetDetails.params["creator"]
  );
}

async function stakeAsset(
  amount,
  stakeAssetId,
  address,
  provider,
  providerType,
  appId
) {
  let appAddress = getApplicationAddress(appId);

  const args = [
    new Uint8Array(Buffer.from(creatorStakeFunctionName.stakeAsset, "base64")),
    new Uint8Array(Buffer.from("AA==", "base64")),
  ];

  let appTxn = await generateApplicationCallTxn(
    address,
    Number(appId),
    args,
    [Number(stakeAssetId)],
    undefined,
    undefined,
    1000
  );
  let transferTxn = await generateTransferTransaction(
    address,
    appAddress,
    amount,
    undefined,
    Number(stakeAssetId),
    true
  );

  await signAndSendGroupTransactions(
    provider,
    providerType,
    address,
    [transferTxn, appTxn],
    Number(appId)
  );
}

async function unstake(
  stakeAsssetId,
  rewardAssetId,
  address,
  provider,
  providerType,
  appId
) {
  try {
    // Check they opted into rewardASA
    if (!(await tokenAccepted(address, rewardAssetId))) {
      await acceptToken(address, rewardAssetId, provider, providerType);
    }

    const args = [
      new Uint8Array(Buffer.from(creatorStakeFunctionName.unstake, "base64")),
      new Uint8Array(Buffer.from("AA==", "base64")),
      new Uint8Array(Buffer.from("AQ==", "base64")),
    ];

    let appTxn = await generateApplicationCallTxn(
      address,
      Number(appId),
      args,
      [Number(stakeAsssetId), Number(rewardAssetId)],
      undefined,
      undefined,
      3000
    );

    await signAndSendSingleTransaction(appTxn, address, provider, providerType);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function setPaused(
  pausing,
  adminASA,
  address,
  provider,
  providerType,
  appId
) {
  const args = [
    new Uint8Array(Buffer.from(creatorStakeFunctionName.setPaused, "base64")),
    new Uint8Array(Buffer.from(pausing ? "gA==" : "AA==", "base64")),
    new Uint8Array(Buffer.from("AA==", "base64")),
  ];

  let appTxn = await generateApplicationCallTxn(
    address,
    Number(appId),
    args,
    [Number(adminASA)],
    undefined,
    undefined,
    1000
  );

  await signAndSendSingleTransaction(appTxn, address, provider, providerType);
}

async function optContractIn(
  asset,
  adminASA,
  address,
  provider,
  providerType,
  appId
) {
  const args = [
    new Uint8Array(
      Buffer.from(creatorStakeFunctionName.opt_into_asset, "base64")
    ),
    new Uint8Array(Buffer.from(integerToBase64(asset), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(adminASA), "base64")),
  ];

  let appTxn = await generateApplicationCallTxn(
    address,
    Number(appId),
    args,
    [Number(asset), Number(adminASA)],
    undefined,
    undefined,
    2000
  );

  await signAndSendSingleTransaction(appTxn, address, provider, providerType);
}

async function optContractOut(
  optingOut,
  adminASA,
  address,
  provider,
  providerType,
  appId
) {
  const args = [
    new Uint8Array(
      Buffer.from(creatorStakeFunctionName.opt_out_asset, "base64")
    ),
    new Uint8Array(Buffer.from("AA==", "base64")),
    new Uint8Array(Buffer.from("AQ==", "base64")),
  ];

  // Get creator address of optingOut token
  const assetDetails = await algodClient.getAssetByID(optingOut).do();

  let appTxn = await generateApplicationCallTxn(
    address,
    Number(appId),
    args,
    [Number(optingOut), Number(adminASA)],
    [assetDetails.params["creator"]],
    undefined,
    3000
  );

  await signAndSendSingleTransaction(appTxn, address, provider, providerType);
}

async function migrateToken(
  amount,
  removingASA,
  adminASA,
  address,
  provider,
  providerType,
  appId
) {
  amount = algosdk.algosToMicroalgos(amount);

  const args = [
    new Uint8Array(
      Buffer.from(creatorStakeFunctionName.migrateToken, "base64")
    ),
    new Uint8Array(Buffer.from(integerToBase64(amount), "base64")),
    new Uint8Array(Buffer.from("AA==", "base64")),
    new Uint8Array(Buffer.from("AQ==", "base64")),
  ];

  let appTxn = await generateApplicationCallTxn(
    address,
    Number(appId),
    args,
    [Number(removingASA), Number(adminASA)],
    undefined,
    undefined,
    2000
  );

  await signAndSendSingleTransaction(appTxn, address, provider, providerType);
}

async function updateSettings(
  oldAdminASA,
  newAdminASA,
  minStake,
  maxStake,
  poolLimit,
  rewardRatio,
  duration,
  stakeTokenCreator,
  address,
  provider,
  providerType,
  appId
) {
  rewardRatio = Math.floor(rewardRatio);

  const args = [
    new Uint8Array(
      Buffer.from(creatorStakeFunctionName.updateSettings, "base64")
    ),
    new Uint8Array(Buffer.from(integerToBase64(Number(newAdminASA)), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(Number(minStake)), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(Number(maxStake)), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(Number(poolLimit)), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(Number(rewardRatio)), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(Number(duration)), "base64")),
    new Uint8Array(Buffer.from(stringToBase64(stakeTokenCreator), "base64")),
    new Uint8Array(Buffer.from(integerToBase64(Number(oldAdminASA)), "base64")),
  ];

  let appTxn = await generateApplicationCallTxn(
    address,
    Number(appId),
    args,
    [Number(oldAdminASA)],
    undefined,
    undefined,
    1000
  );

  await signAndSendSingleTransaction(appTxn, address, provider, providerType);
}

function integerToBase64(num) {
  // Create an array buffer of 8 bytes (64 bits)
  var buffer = new ArrayBuffer(8);
  // Create a new DataView from buffer
  var view = new DataView(buffer);
  // Set the number at the first position of the buffer
  view.setUint32(0, Math.floor(num / 4294967296), false);
  view.setUint32(4, num % 4294967296, false);
  // Convert the ArrayBuffer to a base64 string
  var base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
  return base64;
}

// Base32 decode function
function base32Decode(input) {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
  let bits = "";
  let bytes = [];

  for (let i = 0; i < input.length; i++) {
    let char = input[i];
    if (char === "=") {
      break;
    }

    let index = alphabet.indexOf(char);
    if (index === -1) {
      throw new Error("Invalid character found: " + char);
    }

    bits += index.toString(2).padStart(5, "0");
  }

  for (let i = 0; i + 8 <= bits.length; i += 8) {
    bytes.push(parseInt(bits.substring(i, i + 8), 2));
  }

  return new Uint8Array(bytes);
}

// Convert Algorand address to base64
function stringToBase64(address) {
  let bytes = base32Decode(address);

  // Strip off the last 4 bytes (checksum)
  bytes = bytes.slice(0, -4);

  let binary = "";
  bytes.forEach((byte) => {
    binary += String.fromCharCode(byte);
  });

  return btoa(binary);
}
