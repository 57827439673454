import Web3 from "web3";
// utils & constants
import { toast } from "react-toastify";
import { chainParams } from "utils/constants";
import {
  connectUser,
  checkSessionExists,
  reconnectUser,
  disconnectUser,
  loadReachWithOpts,
} from "@jackcom/reachduck";
import {
  loadStdlib,
  ALGO_MyAlgoConnect as MyAlgoConnect,
  ALGO_WalletConnect as WalletConnect,
} from "@reach-sh/stdlib";
// encryption
var CryptoJS = require("crypto-js");

export async function addPolygonNetwork(chainId, provider) {
  const nativeChain = provider.accounts
    ? Number(process.env.REACT_APP_CHAINID)
    : process.env.REACT_APP_CHAINID;
  if (chainId !== nativeChain) {
    toast.info(
      "Incorrect Network Detected. Please Switch To Polygon Network to Continue."
    );
    await provider.request({
      method: "wallet_addEthereumChain",
      params: chainParams,
    });
  }
}

export async function subscribeProvider(
  provider,
  disconnectProvider,
  signin,
  signout
) {
  if (!provider.on) {
    return;
  }
  provider.on("accountsChanged", async (accounts) => {
    signout();
    if (accounts.length > 0) {
      signin(accounts[0]);
    } else {
      freeProvider(provider, disconnectProvider, signout);
      window.location.replace("/polygon");
    }
  });
  provider.on("chainChanged", async (chainId) => {
    if (provider.accounts) {
      if (chainId !== Number(process.env.REACT_APP_CHAINID))
        window.location.replace("/polygon");
    } else {
      await addPolygonNetwork(chainId, provider);
    }
  });
}

export async function connectAccount(
  provider,
  connectProvider,
  disconnectProvider,
  signin,
  signout,
  subscribed
) {
  try {
    connectProvider(provider);
    if (!subscribed) {
      await subscribeProvider(provider, disconnectProvider, signin, signout);
    }
    await provider.enable();
    if (provider) {
      // check the wallet connect connection
      if (provider.accounts) {
        signin(provider.accounts[0]);
      } else {
        await provider.request({
          method: "eth_requestAccounts",
        });
        const web3js = new Web3(provider);
        const accounts = await web3js.eth.getAccounts();
        if (accounts.length > 0) {
          signin(accounts[0]);
        } else {
          toast.error("Cannot get your metamask account");
        }
      }
      // update chain
      const chainId = await provider.request({
        method: "eth_chainId",
      });
      await addPolygonNetwork(chainId, provider);
    }
  } catch (error) {
    throw error;
  }
}

/* Disconnect the wallet from the platform */
export async function freeProvider(provider, disconnectProvider, signout) {
  try {
    if (provider?.disconnect && typeof provider.disconnect === "function")
      await provider.disconnect();
    disconnectProvider();
    signout();
  } catch (err) {
    console.error(err);
    toast.error("Failed to disconnect to your wallet");
  }
}

/* Algo Connect */
/** Connect user Wallet */
export async function connectAlgoWallet(provider, signin) {
  try {
    configureWalletProvider(provider);
    const updates = await connectUser();

    // only encrypt for myalgo connect
    if (provider === "MyAlgo") {
      // encrypt
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(localStorage.getItem("user")),
        process.env.REACT_APP_CIPHERKEY
      ).toString();
      // overwirte the storage data
      localStorage.setItem("user", ciphertext);
    }

    signin(updates.account);
    return updates.account;
  } catch (err) {
    console.error(err);
    throw Error("Fail to connect your Algo account.");
  }
}

/** Reconnect user session */
export async function reconnectAlgoWallet(signin, signout) {
  try {
    const { addr = undefined, isWCSession } = checkSessionExists();
    let reconnectAddress = addr;

    // my algo connect will be a "user" item
    // wallet connect will be a "walletconnect" item
    if (localStorage.getItem("user")) {
      // Decrypt
      const bytes = CryptoJS.AES.decrypt(addr, process.env.REACT_APP_CIPHERKEY);
      reconnectAddress = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    configureWalletProvider(isWCSession ? "WalletConnect" : "MyAlgo");
    const updates = await reconnectUser(reconnectAddress);
    signin(updates.account);

    if (localStorage.getItem("user")) {
      // encrypt
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(localStorage.getItem("user")),
        process.env.REACT_APP_CIPHERKEY
      ).toString();
      // overwirte the storage data
      localStorage.setItem("user", ciphertext);
    }

    return updates.account;
  } catch (err) {
    console.error(err);
    toast.error("Fail to re-connect your Algo account.");
    disconnectAlgoWallet(signout);
  }
}

/** Dissconnect user session */
export async function disconnectAlgoWallet(signout, peraWallet = null) {
  if (peraWallet && peraWallet.isConnected) {
    peraWallet.disconnect();
  }
  disconnectUser();
  signout();
}

function configureWalletProvider(pr) {
  if (!["WalletConnect", "MyAlgo"].includes(pr)) return;

  const fallback = pr === "MyAlgo" ? { MyAlgoConnect } : { WalletConnect };

  loadReachWithOpts(loadStdlib, {
    walletFallback: fallback,
    network: process.env.REACT_APP_ALGOENVIRONMENT,
  });
}

export function handleDisconnectWalletClick(peraWallet) {
  peraWallet.disconnect();
}

export async function ConnectWallet(signIn, peraWallet) {
  try {
    return peraWallet.connect().then((newAccounts) => {
      signIn(newAccounts[0]);
      return newAccounts[0];
    });
  } catch (ex) {
    console.error(ex);
  }
}

export async function ReconnectWallet(signIn, peraWallet) {
  try {
    return peraWallet.reconnectSession().then((newAccounts) => {
      signIn(newAccounts[0]);
      return newAccounts[0];
    });
  } catch (ex) {
    console.error(ex);
  }
}
