import React, { useEffect, useState, useContext } from "react";
import { Nav } from "react-bootstrap";
import AlgoConnect from "components/algo/AlgoConnect";
// contexts
import { AuthContext } from "context/authContext";
import { AlgoProviderContext } from "../../context/algoProviderContext";
import { EthProviderContext } from "context/ethProviderContext";
// utils
import { useLocation } from "react-router-dom";
import { disconnectAlgoWallet, freeProvider } from "services/auth.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";

// images
import DBDLogo from "assets/DayByDayLogo.png";
import DBDLogoMob from "assets/DayByDayLogo.png";

import DBDToken from "assets/DBDicon.svg";
import polygonIcon from "assets/matic.png";
import algoIcon from "assets/algorand_icon_circle-dark.svg";

import ConnectModal from "components/main/ConnectModal";

function CustomNavbar() {
  const location = useLocation();
  const [dbdPrice, setdbdPrice] = useState(0.009);
  const [isUserConnected, setIsUserConnected] = useState(false);

  const { authState, signoutPoly, signoutAlgo } = useContext(AuthContext);
  const { algoProviderState, disconnectAlgoProvider } =
    useContext(AlgoProviderContext);

  const { ethProviderState, disconnectProvider } =
    useContext(EthProviderContext);

  const [isShownAlgoConnect, setIsShownAlgoConnect] = useState(false);
  const [isShownPolyConnect, setIsShownPolyConnect] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [displayConnectButton, setDisplayConnectButton] = useState(false);

  const [walletAddress, setWalletAddress] = useState("");

  useEffect(() => {
    async function locationPathCheck() {
      if (
        location.pathname.includes("polygon") ||
        location.pathname.includes("algorand")
      ) {
        setDisplayConnectButton(true);
      } else {
        setDisplayConnectButton(false);
      }
    }
    locationPathCheck();
  }, [location]);

  useEffect(() => {
    async function isConnected() {
      let isUserConnected =
        (authState.accountPoly !== undefined &&
          authState.accountPoly !== null) ||
        (authState.accountAlgo !== undefined && authState.accountAlgo !== null);

      setIsUserConnected(isUserConnected);
      if (isUserConnected) {
        if (authState.accountPoly) {
          setWalletAddress(
            authState.accountPoly.substring(0, 4) +
            "..." +
            authState.accountPoly.slice(authState.accountPoly.length - 4)
          );
        } else if (authState.accountAlgo) {
          if (authState.accountAlgo.networkAccount) {
            setWalletAddress(
              authState.accountAlgo.networkAccount.addr.substring(0, 4) +
              "..." +
              authState.accountAlgo.networkAccount.addr.slice(
                authState.accountAlgo.networkAccount.addr.length - 4
              )
            );
          } else {
            setWalletAddress(
              authState.accountAlgo.substring(0, 4) +
              "..." +
              authState.accountAlgo.slice(authState.accountAlgo.length - 4)
            );
          }
        }
      }
    }
    isConnected();
  }, [authState]);

  const connectWallet = async () => {
    if (location.pathname.includes("polygon")) {
      setIsShownPolyConnect(true);
    } else if (location.pathname.includes("algorand")) {
      setIsShownAlgoConnect(true);
    } else {
      return;
    }
  };

  useEffect(() => {
    async function getDBDPrice() {
      try {
        await fetch(process.env.REACT_APP_BASEURL + "lookup/getDBDPrice", {
          headers: {
            "Api-Key": process.env.REACT_APP_APIKEY,
          },
          method: "GET",
        })
          .then((response) => response.text())
          .then((data) => setdbdPrice(data));
      } catch (err) {
        setdbdPrice("Error");
      }
    }
    getDBDPrice();
  }, []);

  const disconnectWallet = async () => {
    if (!isDisconnecting) {
      setIsDisconnecting(true);
      if (location.pathname.includes("polygon")) {
        freeProvider(
          ethProviderState.provider,
          disconnectProvider,
          signoutPoly
        );
      } else if (location.pathname.includes("algorand")) {
        disconnectAlgoWallet(signoutAlgo, algoProviderState.provider);
        disconnectAlgoProvider();
      } else {
        return;
      }
      setIsDisconnecting(false);
    }
  };

  return (
    <header>
      <nav
        id="main-container"
        className="navbar navbar-expand-xl navbar-dark d-flex flex-wrap py-3"
      >
        <div className="container container-extend">
          <Nav.Link className="navbar-brand mr-5 active" href="/">
            <img
              className="normallogo d-sm-inline-block d-none"
              src={DBDLogo}
              alt="Logo"
            />
            <img
              className="normallogo d-sm-none d-inline-block"
              src={DBDLogoMob}
              alt="Icon"
            />
          </Nav.Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>

          <div className="navbar-collapse collapse justify-content-between ">
            <ul className="navbar-nav mt-xl-0 mt-5 flex-grow-1 pt-xl-0 pt-5">
              <li className="nav-item position-relative DBD">
                <button
                  className="btn dropdown-btn dropdown-btn-normal mb-xl-0 mb-3"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>NFTs</span>

                </button>
                <div className="dropdown-menu">
                  <a
                    href="https://app.daybyday.io/mint"
                    data-target=".navbar-collapse.show"
                    className="nav-link dropdown-item d-flex align-items-center"
                  >
                    <span>Mint</span>

                  </a>
                  <a
                    href="https://app.daybyday.io/marketplace"
                    data-target=".navbar-collapse.show"
                    className="nav-link dropdown-item d-flex align-items-center"
                  >
                    <span>Marketplace</span>

                  </a>
                </div>
              </li>
              <li className="nav-item">
                <Nav.Link
                  className="nav-link GA_navlink"
                  rel="noopener noreferrer"
                  href="https://app.daybyday.io/underwrite"
                >
                  Underwrite
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link
                  className="nav-link GA_navlink"
                  rel="noopener noreferrer"
                  href="https://app.daybyday.io/get-protection"
                >
                  Get a Quote
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link
                  className="nav-link GA_navlink"
                  rel="noopener noreferrer"
                  href="https://app.daybyday.io/about-us"
                >
                  About Us
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link
                  className="nav-link GA_navlink"
                  rel="noopener noreferrer"
                  href="https://stake.daybyday.io/"
                >
                  Staking
                </Nav.Link>
              </li>

              <li className="nav-item">
                <Nav.Link
                  className="nav-link GA_navlink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://daybyday.io/download-the-app"
                >
                  Register Assets
                  <svg aria-hidden="true" class="navbar_externalArrow___VWBd" height="7" viewBox="0 0 6 6" width="7"><path d="M1.25215 5.54731L0.622742 4.9179L3.78169 1.75597H1.3834L1.38936 0.890915H5.27615V4.78069H4.40513L4.41109 2.38538L1.25215 5.54731Z" fill="var(--accents-3)"></path></svg>
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://daybydayio.medium.com/"
                >
                  Blog
                </Nav.Link>
              </li>
              <li className="nav-item position-relative DBD">
                <button
                  className="btn dropdown-btn dropdown-btn-normal mb-xl-0 mb-3"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>Investors</span>
                </button>
                <div className="dropdown-menu">
                  <a
                    href="https://docs.daybyday.io/day-by-day-overview/media/press-releases"
                    data-target=".navbar-collapse.show"
                    className="nav-link dropdown-item d-flex align-items-center"
                  >
                    <span>Press Releases</span>
                  </a>
                  <a
                    href="https://token.daybyday.io"
                    data-target=".navbar-collapse.show"
                    className="nav-link dropdown-item d-flex align-items-center"
                  >
                    <span>DBD Token</span>
                  </a>
                </div>
              </li>

              <li className={`nav-item position-relative DBD dbd-purchase ml-auto mr-xl-4 mr-0 ${!displayConnectButton ? 'extra-margin' : ''}`}>
                <button
                  data-toggle="dropdown"
                  className="btn dropdown-btn mb-xl-0 mb-3"
                >
                  <div className="icon-wrapper">
                    <img src={DBDToken} height="30" alt="DBD Logo" />
                  </div>
                  <span>{dbdPrice} USD</span>
                </button>

                <div className="dropdown-menu">
                  <a
                    href="https://www.bitmart.com/trade/en?layout=basic&symbol=DBD_USDT"
                    className="nav-link dropdown-item d-flex align-items-center"
                    data-target=".navbar-collapse.show"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    DBD Tokens <span className="label">Buy</span>
                  </a>
                  <a
                    href="https://quickswap.exchange/#/swap?currency0=0x72b9f88e822cf08b031c2206612b025a82fb303c&currency1=ETH"
                    className="nav-link dropdown-item d-flex align-items-center"
                    data-target=".navbar-collapse.show"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Polygon DBD <span className="label">Swap</span>
                  </a>
                  <a
                    href="https://app.humble.sh/swap?poolId=878359186&asset_in=0&asset_out=747635241"
                    className="nav-link dropdown-item d-flex align-items-center"
                    data-target=".navbar-collapse.show"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Algorand DBD <span className="label">Swap</span>
                  </a>
                </div>
              </li>

              {displayConnectButton && (
                <div>
                  {/* Wallet Button */}
                  <li className="nav-item position-relative Account">
                    <div>
                      {/* Connect Button */}
                      {!isUserConnected ? (
                        <button
                          className={`btn dropdown-btn wallet 'connected`}
                          onClick={() => connectWallet()}
                        >
                          <div className="icon-wrapper">
                            {!isUserConnected && (
                              <i
                                alt="Connect"
                                className="fas fa-wallet ml-1"
                              ></i>
                            )}
                          </div>
                          <span>
                            {!isUserConnected ? "Connect" : walletAddress}
                          </span>
                        </button>
                      ) : (
                        <div>
                          {/* Wallet button */}
                          <button
                            data-toggle="dropdown"
                            className={`btn dropdown-btn wallet ${isUserConnected ? "connected" : ""
                              }`}
                          >
                            <div className="icon-wrapper">
                              {isUserConnected && authState.accountPoly && (
                                <img src={polygonIcon} alt="Matic" />
                              )}
                              {isUserConnected && authState.accountAlgo && (
                                <img src={algoIcon} alt="Algo" />
                              )}
                            </div>
                            <span>{isUserConnected && walletAddress}</span>
                          </button>

                          <div
                            className={`dropdown-menu dropdown-menu-right logged-in`}
                            aria-labelledby="username"
                          >
                            <a
                              className="nav-link dropdown-item"
                              onClick={() => disconnectWallet()}
                            >
                              Disconnect <i className="fas fa-sign-out-alt"></i>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                </div>
              )}
            </ul>
          </div>
        </div>
        <AlgoConnect
          isShown={isShownAlgoConnect}
          setIsShown={setIsShownAlgoConnect}
        />

        <ConnectModal
          isShown={isShownPolyConnect}
          setIsShown={setIsShownPolyConnect}
        />
      </nav>
    </header>
  );
}

export default CustomNavbar;
