import React, { useEffect, useState } from "react";
// constants
import { loadStatus, loadingMsg } from "utils/constants";

function Loading(props) {
  const { loadingStatus } = props;
  const [statusNo, setStatusNo] = useState();

  useEffect(() => {
    loadStatus.map((status, index) => {
      if (loadingStatus === status) setStatusNo(index);
      return status;
    });
  }, [loadingStatus]);

  return (
    <div
      hidden={loadingStatus === loadStatus[loadStatus.length - 1]}
      className="cal-loading"
    >
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p style={{ whiteSpace: "pre-line", zIndex: 999 }}>
        {loadingMsg[statusNo]}
      </p>
    </div>

  );
}

export default Loading;
