import React, { useState, useContext, useEffect } from "react";
// context
import { AuthContext } from "context/authContext";
// hooks
import { useReach } from "hooks/useAlgo";
import { useStakingDataReach, useAccountData } from "hooks/useAlgo";
// utils
import { connectAlgoWallet } from "services/auth.service";
import { callAPI } from "services/contract.service";
import { toast } from "react-toastify";
// images
import DBDToken from "assets/DBDTokenLogo.png";

function AlgoLegacyGeneralSettingsCard(props) {
  const { setChecked } = props;

  const [upDateStakingData, setUpDateStakingData] = useState(0);

  const { authState, signinAlgo } = useContext(AuthContext);

  // Algo
  const reach = useReach();
  const ctc = useAccountData(authState.accountAlgo);
  const algostakingData = useStakingDataReach(
    authState.accountAlgo,
    ctc,
    upDateStakingData
  );

  // updating values
  const [rewardAmount, setRewardAmount] = useState(0);
  const [newOwner, setNewOwner] = useState(0);
  const [pause, setPause] = useState(false);
  // updating staking values
  const [minStake, setMinStake] = useState(0);
  const [maxStake, setMaxStake] = useState(0);
  const [maxStakingPool, setMaxStakingPool] = useState(0);
  // updating staking thresholds days
  const [threshold, setThreshold] = useState([0, 0, 0, 0, 0, 0]);
  // updating staking thresholds %
  const [thresholdApy, setThresholdApy] = useState([0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    async function updateValues() {
      setMinStake(algostakingData.minStaking);
      setMaxStake(algostakingData.maxStakePerStake);
      setMaxStakingPool(algostakingData.maxStakingPool);

      setThreshold(algostakingData.durationThreshold);
      setThresholdApy(algostakingData.durationApy);
    }
    if (algostakingData) {
      updateValues();
    }
  }, [algostakingData]);

  const updateAlgoSettings = async (paused, field) => {
    let tmpNewOwner;
    if (newOwner !== 0) {
      tmpNewOwner = newOwner;
    } else {
      tmpNewOwner = authState.accountAlgo;
    }

    setPause(paused);

    let thresholdChecked = true;
    threshold.forEach((t, index) => {
      if (index > 0) {
        if (Number(t) > 0 && Number(t) <= Number(threshold[index - 1])) {
          thresholdChecked = false;
        } else if (
          Number(t) === 0 &&
          Number(t) < Number(threshold[index - 1])
        ) {
          thresholdChecked = false;
        }
      }
    });
    if (!thresholdChecked) {
      toast.warn(
        "Please make sure the thresholds you entered are greater than the previous threshold."
      );
      return;
    }
    let apyChecked = true;
    thresholdApy.forEach((apy, index) => {
      if (index > 0 && index !== thresholdApy.length - 1) {
        if (Number(apy) > 1) {
          if (
            Number(apy) <= Number(thresholdApy[index - 1]) ||
            (index !== thresholdApy.length - 2 &&
              Number(apy) >= Number(thresholdApy[index + 1]))
          )
            apyChecked = false;
        } else {
          if (Number(apy) < Number(thresholdApy[index - 1])) apyChecked = false;
        }
      }
    });
    if (!apyChecked) {
      toast.warn(
        "Please make sure the apys you entered are greater than the previous apy."
      );
      return;
    }

    try {
      await callAPI(
        "updateAdminVarialbes",
        authState.accountAlgo,
        ctc,
        reach,
        reach.parseCurrency(minStake),
        reach.parseCurrency(maxStake),
        reach.parseCurrency(maxStakingPool),
        tmpNewOwner,
        86400, // This changes it from seconds to days or whatever you want so if set to 1 then 1 duration is 1 second
        threshold,
        thresholdApy,
        paused
      );
      setUpDateStakingData(upDateStakingData + 1);
      switch (field) {
        case "pause":
          toast.success(`${paused ? "Pause" : "Resume"} staking successfully.`);
          break;
        case "owner":
          toast.success("Update onwer successfully.");
          break;
        case "amount":
          toast.success("Update staking amount successfully.");
          break;
        case "threshold":
          toast.success(
            "Update thresholds and corresponding apys successfully."
          );
          break;
        default:
          toast.success("Update admin varaibles successfully.");
          break;
      }
    } catch (err) {
      toast.error("Failed to update algo settings");
    }
  };

  const removeFromRewardPool = async () => {
    try {
      await callAPI(
        "removeRewards",
        authState.accountAlgo,
        ctc,
        reach,
        reach.parseCurrency(rewardAmount)
      );
      setUpDateStakingData(upDateStakingData + 1);
      toast.success("Remove tokens from reward pool successfully.");
    } catch (err) {
      toast.error("Failed to remove the rewards.");
    }
  };

  const addToRewardPool = async () => {
    try {
      await callAPI(
        "addRewards",
        authState.accountAlgo,
        ctc,
        reach,
        reach.parseCurrency(rewardAmount)
      );
      setUpDateStakingData(upDateStakingData + 1);
      toast.success("Add tokens to reward pool successfully.");
    } catch (err) {
      toast.error("Failed to add the rewards.");
    }
  };

  const connectWallet = async () => {
    try {
      await connectAlgoWallet("MyAlgo", signinAlgo);
      setChecked(false);
    } catch (err) {
      toast.error("Fail to connect your Algo account.");
    }
  };

  const inputChange = (field, index, value) => {
    if (field === "threshold") {
      const tmpThreshold = threshold;
      tmpThreshold.splice(index, 1, value);
      setThreshold(tmpThreshold);
    } else if (field === "apy") {
      const tmpThresholdApy = thresholdApy;
      tmpThresholdApy.splice(index, 1, value);
      setThresholdApy(tmpThresholdApy);
    }
  };

  return (
    <div className="card mb-4 mx-auto card-width">
      <div className="text-center">
        <h2 className="genSettingsHeading">Algo General Settings:</h2>
      </div>
      {!authState.isAdminAlgo ? (
        <div className="text-center">
          <div className="mb-4">
            <button
              className="btn mr-2 btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => connectWallet()}
            >
              Connect Wallet
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="genSettingsHeading">Algo General Settings:</h2>
          <div className="my-3 text-center h5 font-weight-bold">
            Staking Status
          </div>
          <div className="mb-5 d-flex justify-content-center">
            {algostakingData && (
              <div>
                {algostakingData.stakingPaused ? (
                  <button
                    disabled={!authState.isAdminAlgo}
                    type="button"
                    className="btn btn-lg  bg-primary-color text-white rounded-pill"
                    onClick={() => updateAlgoSettings(false, "pause")}
                  >
                    Continue staking
                  </button>
                ) : (
                  <button
                    disabled={!authState.isAdminAlgo}
                    type="button"
                    className="btn btn-lg  bg-primary-color text-white rounded-pill"
                    onClick={() => updateAlgoSettings(true, "pause")}
                  >
                    Pause staking
                  </button>
                )}
              </div>
            )}
          </div>

          {/* Reward Pool Balance Update */}
          <div className="mx-4">
            <div>
              <label className="float-left">
                <b>Reward Tokens</b>
              </label>
              <span className="float-right text-muted">
                Reward Balance:&nbsp;
                {algostakingData ? algostakingData.remainingRewards : 0}
              </span>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                className="form-control form-control-lg"
                placeholder={
                  algostakingData ? algostakingData.remainingRewards : 0
                }
                onChange={(e) => setRewardAmount(e.currentTarget.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <img src={DBDToken} height="32" alt="DBD" />
                  &nbsp;&nbsp;&nbsp;DBD
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-25 mr-4 bg-primary-color text-white rounded-pill"
              onClick={() => addToRewardPool()}
            >
              Add
            </button>
            <button
              disabled={!authState.isAdminAlgo}
              style={{ whiteSpace: "nowrap" }}
              className="btn btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => removeFromRewardPool()}
            >
              Remove
            </button>
          </div>

          {/* Transfer owner */}
          <div className="mx-4 mb-2">
            <div>
              <label className="float-left">
                <b>Owner</b>
              </label>
            </div>
            <div className="input-group mb-2">
              <input
                min="0"
                className="form-control form-control-lg"
                // placeholder={AlgostakingData ? AlgostakingData.owner : 0}
                onChange={(e) => setNewOwner(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-25 mr-4 bg-primary-color text-white rounded-pill"
              onClick={() => updateAlgoSettings(pause, "owner")}
            >
              Transfer
            </button>
          </div>

          {/* Stake Amount Update */}
          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Min Stake per Stake</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.minStaking : 0}
                  </span>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.minStaking : 0
                    }
                    onChange={(e) => setMinStake(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Max Stake Per Stake</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.maxStakePerStake : 0}
                  </span>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    min="0"
                    step={1}
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.maxStakePerStake : 0
                    }
                    onChange={(e) => setMaxStake(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Max Staking Pool</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.maxStakingPool : 0}
                  </span>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    step={1}
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.maxStakingPool : 0
                    }
                    onChange={(e) => setMaxStakingPool(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => updateAlgoSettings(pause, "amount")}
            >
              Update values
            </button>
          </div>

          {/* Duration APY's */}
          {/* Duration till and APY Update 1 */}
          <div className="row">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Duration till and APY</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <div className="row mb-1">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData
                            ? algostakingData.durationThreshold[0]
                            : 0
                        }
                        onChange={(e) =>
                          inputChange("threshold", 0, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append ">
                      <div className="input-group-text">Days</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData ? algostakingData.durationApy[0] : 0
                        }
                        onChange={(e) =>
                          inputChange("apy", 0, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append">
                      <div className="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Duration till and APY Update 2 */}
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Duration till and APY</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <div className="row mb-1">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData
                            ? algostakingData.durationThreshold[1]
                            : 0
                        }
                        onChange={(e) =>
                          inputChange("threshold", 1, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append ">
                      <div className="input-group-text">Days</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData ? algostakingData.durationApy[1] : 0
                        }
                        onChange={(e) =>
                          inputChange("apy", 1, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append">
                      <div className="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Duration till and APY Update 3 */}
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Duration till and APY</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <div className="row mb-1">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData
                            ? algostakingData.durationThreshold[2]
                            : 0
                        }
                        onChange={(e) =>
                          inputChange("threshold", 2, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append ">
                      <div className="input-group-text">Days</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData ? algostakingData.durationApy[2] : 0
                        }
                        onChange={(e) =>
                          inputChange("apy", 2, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append">
                      <div className="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Duration till and APY Update 4*/}
          <div className="row">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Duration till and APY</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <div className="row mb-1">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData
                            ? algostakingData.durationThreshold[3]
                            : 0
                        }
                        onChange={(e) =>
                          inputChange("threshold", 3, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append ">
                      <div className="input-group-text">Days</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData ? algostakingData.durationApy[3] : 0
                        }
                        onChange={(e) =>
                          inputChange("apy", 3, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append">
                      <div className="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Duration till and APY Update 5*/}
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Duration till and APY</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <div className="row mb-1">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData
                            ? algostakingData.durationThreshold[4]
                            : 0
                        }
                        onChange={(e) =>
                          inputChange("threshold", 4, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append ">
                      <div className="input-group-text">Days</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData ? algostakingData.durationApy[4] : 0
                        }
                        onChange={(e) =>
                          inputChange("apy", 4, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append">
                      <div className="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Duration till and APY Update 6*/}
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Duration till and APY</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <div className="row mb-1">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData
                            ? algostakingData.durationThreshold[5]
                            : 0
                        }
                        onChange={(e) =>
                          inputChange("threshold", 5, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append ">
                      <div className="input-group-text">Days</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData ? algostakingData.durationApy[5] : 0
                        }
                        onChange={(e) =>
                          inputChange("apy", 5, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append">
                      <div className="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Duration till and APY Update 6*/}
          <div className="row mb-2">
            <div className="col-sm"></div>
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>After Last</b>
                  </label>
                </div>
                <div className="input-group mb-2">
                  <div className="row">
                    <div className="col adminThresholds">
                      <input
                        type="number"
                        min="0"
                        className="form-control form-control-lg"
                        placeholder={
                          algostakingData ? algostakingData.durationApy[6] : 0
                        }
                        onChange={(e) =>
                          inputChange("apy", 6, e.currentTarget.value)
                        }
                      />
                    </div>
                    <div className="input-group-append ">
                      <div className="input-group-text">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm"></div>
          </div>

          <div className="text-center mb-3">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-50 bg-primary-color text-white rounded-pill"
              onClick={() => updateAlgoSettings(pause, "threshold")}
            >
              Update thresholds
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AlgoLegacyGeneralSettingsCard;
