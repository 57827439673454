import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./pages/App";
import { AuthProvider } from "context/authContext";
import { EthProvider } from "context/ethProviderContext";
import { AlgoProvider } from "context/algoProviderContext";
import { ToastContainer } from "react-toastify";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <ToastContainer position="top-center" />
    <EthProvider>
      <AlgoProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </AlgoProvider>
    </EthProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
