export const initUnstakeState = {
  isUnstaking: false,
  isConfirmed: false,
  unstakeTxn: "",
  type: "",
};

export function unstakeReducer(state, action) {
  switch (action.type) {
    case "UNSTAKING":
      return { ...state, isUnstaking: action.payload, type: "unstaking" };
    case "CONFIRM":
      return {
        ...state,
        isUnstaking: true,
        isConfirmed: true,
        unstakeTxn: action.payload,
        type: "confirm",
      };
    case "FINISH":
      return { ...initUnstakeState, type: "finish" };
    case "ERROR":
      return { ...initUnstakeState, type: "error" };
    default:
  }
}
