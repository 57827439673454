import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
// components
import UnstakeModal from "components/main/records/UnstakeModal";
import StakeModal from "components/main/stake/StakeModal";
import StakingTitleBar from "components/layout/StakingTitleBar";
import StakingHistoryAlgo from "./StakingHistoryAlgo";
import AlgoConnect from "./AlgoConnect";
import StakingInfo from "components/StakingInfo";
// import { styled  withStyles } from "@material-ui/core";
// import Slider from "@material-ui/core/Slider";
import Loading from "components/Loading";
import FireConfetti from "components/FireConfetti";
// reducers
import { initStakeState, stakeReducer } from "reducers/stakeReducer";
import { initUnstakeState, unstakeReducer } from "reducers/unstakeReducer";
// utils
import { useStakingDataReach, useAccountData, useReach } from "hooks/useAlgo";
import { callAPI } from "services/contract.service";
import { AuthContext } from "../../context/authContext";
import { ctcparse } from "utils/reachHelpers";
import { toast } from "react-toastify";
import moment from "moment";
// reach
import { checkSessionExists } from "@jackcom/reachduck";
// images && constants
import DBDToken from "assets/DBDicon.svg";
import {
  disconnectAlgoWallet,
  reconnectAlgoWallet,
} from "services/auth.service";
import { loadStatus, stakingToken } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import StakingWarningModal from "./StakingWarningModal";

const ALGO_CURRENCY_DECIMAL = 1000000;

function StakeCardAlgoLegacy() {
  const { authState, signinAlgo, signoutAlgo } = useContext(AuthContext);

  const [stakeState, dispatch] = useReducer(stakeReducer, initStakeState);
  const [unstakeState, dispatchUnstake] = useReducer(
    unstakeReducer,
    initUnstakeState
  );

  const [isShownConnect, setIsShownConnect] = useState(false);
  const [updateStakingData, setUpdateStakingData] = useState(0);
  const [increasingDuration, setIncreasingDuration] = useState(false);
  const [warningMsg, setWarningMsg] = useState(false);
  const [unstakeUnavailable, setUnstakeUnavailable] = useState(false);
  const [loading, setLoading] = useState(loadStatus[0]);
  const [shownWarningModal, setShownWarningModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  // Acount Details
  const ctc = useAccountData(authState.accountAlgo);
  const [bal, setBal] = useState(0);
  const [acceptedToken, setAcceptedToken] = useState(2);
  const [isConnecting, setIsConnecting] = useState(false);
  // Staking Data
  const stakingData = useStakingDataReach(
    authState.accountAlgo,
    ctc,
    updateStakingData
  );

  const reach = useReach();

  const [totalStaked, setTotalStaked] = useState(0);
  const [maxStakingPool, setMaxStakingPool] = useState(0);
  const [globLimit, setGlobLimit] = useState(0);
  const [amount, setAmount] = useState(0);
  const [stakingDuration, setStakingDuration] = useState(60);
  const [isAdmin, setIsAdmin] = useState(false);
  // Slider limit
  const [sliderMin, setSliderMin] = useState([]);
  const [sliderMid, setSliderMid] = useState([]);
  const [sliderMax, setSliderMax] = useState([]);

  const [confettiShown, setConfettiShown] = useState({
    stakeShown: false,
    unstakeShown: false,
  });
  const confettiRef = useRef();

  const displayWarningForStaking = () => {
    const endTimeDiff = moment
      .unix(stakingData.userStakeEnd)
      .diff(moment(), "days");
    if (endTimeDiff <= 0) {
      // If user can stake now
      // if unstake is available
      setIncreasingDuration(true);
      const warningMsg = unstakeUnavailable
        ? `Since the system is under maintenance, you can either to try to unstake your available DBD tokens later or stake again. 
        However, if you continue with your current stake, you will not be able to unstake and collect your rewards 
        until the new stake end date.`
        : `You have DBD tokens available for unstaking. If you continue with your current stake, you will 
        not be able to unstake and collect your rewards until the new stake end date.`;
      setWarningMsg(warningMsg);
    } else if (endTimeDiff <= stakingDuration) {
      // if there duration selection matters
      setIncreasingDuration(true);
      setWarningMsg(
        `Your current DBD will be available for unstaking in under ${endTimeDiff} days. If you increase your staked amount, you will not be able to unstake and collect your rewards until the new stake end date.`
      );
    } else {
      const stakeEnddate = moment
        .unix(stakingData.userStakeEnd)
        .format("DD-MMM-YYYY");
      setIncreasingDuration(true);
      setWarningMsg(
        `You have DBD available for unstaking in ${endTimeDiff} days. If you stake this new amount, you will be able to collect all rewards on ${stakeEnddate}.`
      );
    }
  };

  useEffect(() => {
    const { exists } = checkSessionExists();
    if (!authState.accountAlgo) {
      if (exists) {
        reconnectAlgoWallet(signinAlgo, signoutAlgo);
      }
      dispatch({ type: "RESET" });
    }
  }, [authState.accountAlgo, signinAlgo, signoutAlgo]);

  useEffect(() => {
    if (stakingData && loading !== loadStatus[loadStatus.length - 1]) {
      setLoading(loadStatus[loadStatus.length - 1]);
    }
  }, [stakingData, stakingDuration, loading]);

  useEffect(() => {
    async function isConnectingUpdate() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsConnecting(false);
      } catch (err) {
        console.error(err);
      }
    }
    function calcSliderValues() {
      const tmpThresholdArray = stakingData.durationThreshold;
      const tmpAPYArray = stakingData.durationApy;
      for (let i = 0; i < tmpThresholdArray.length; i++) {
        if (Number(tmpThresholdArray[i]) === 0) {
          tmpThresholdArray.splice(i, 1);
          i = -1;
        }
      }
      for (let i = 0; i < tmpAPYArray.length; i++) {
        if (Number(tmpAPYArray[i]) < 2) {
          tmpAPYArray.splice(i, 1);
          i = -1;
        }
      }

      // MARK: only one duration
      setStakingDuration(Number(tmpThresholdArray[0]));
      setSliderMin([Number(tmpThresholdArray[0]), Number(tmpAPYArray[0])]);
      setSliderMax([
        Number(tmpThresholdArray[tmpThresholdArray.length - 2]),
        Number(tmpAPYArray[tmpAPYArray.length - 1]),
      ]);
      if (tmpThresholdArray.length % 2 === 0) {
        if (tmpAPYArray.length % 2 === 0) {
          setSliderMid([
            tmpThresholdArray[tmpThresholdArray.length / 2 - 1],
            tmpAPYArray[tmpAPYArray.length / 2 - 1],
          ]);
        } else {
          const tmpIndex = Math.ceil(tmpAPYArray.length / 2);
          setSliderMid([
            tmpThresholdArray[tmpThresholdArray.length / 2 - 1],
            tmpAPYArray[tmpIndex - 1],
          ]);
        }
      } else {
        const tmpIndex = Math.ceil(tmpThresholdArray.length / 2);
        setSliderMid([
          tmpThresholdArray[tmpIndex - 1],
          tmpAPYArray[tmpIndex - 1],
        ]);
      }
    }

    if (stakingData && stakingData.totalStaked) {
      setTotalStaked(stakingData.totalStaked);
      setMaxStakingPool(stakingData.maxStakingPool);
      isConnectingUpdate();
      calcSliderValues();
    }
  }, [stakingData]);

  useEffect(() => {
    async function updateAccountDetails() {
      // Balance
      const tokenBal =
        Number(
          await reach.balanceOf(authState.accountAlgo, ctcparse(stakingToken))
        ) / ALGO_CURRENCY_DECIMAL;
      setBal(tokenBal);

      if (
        authState.accountAlgo.networkAccount.addr ===
        reach.formatAddress(stakingData.owner)
      ) {
        setIsAdmin(true);
      }
      let acceptedTokenYet = await authState.accountAlgo.tokenAccepted(
        ctcparse(stakingToken)
      );
      if (acceptedTokenYet) {
        dispatch({ type: "APPROVED" });
      }
      setAcceptedToken(acceptedTokenYet);
    }
    async function stakingDataChange() {
      const limit =
        stakingData.maxStakePerStake <
        stakingData.maxStakingPool - stakingData.totalStaked
          ? stakingData.maxStakePerStake
          : stakingData.maxStakingPool - stakingData.totalStaked;
      let max = Number(limit).toFixed(4);
      setGlobLimit(Number(max));

      // Checking if unstaking is possible
      if (
        Number(stakingData.userExpectedRewards) >
        Number(stakingData.remainingRewards)
      ) {
        setUnstakeUnavailable(true);
      } else if (
        Number(stakingData.remainingRewards) + Number(stakingData.totalStaked) <
        Number(stakingData.userExpectedRewards) + Number(stakingData.staked)
      ) {
        setUnstakeUnavailable(true);
      } else {
        setUnstakeUnavailable(false);
      }
    }

    if (authState.accountAlgo && authState.accountAlgo !== 0 && stakingData) {
      updateAccountDetails();
    }
    if (stakingData) {
      stakingDataChange();
    }
  }, [stakingData, updateStakingData, authState.accountAlgo, reach]);

  // show the confetti
  useEffect(() => {
    if (stakeState.type === "finish" && !confettiShown.stakeShown) {
      confettiRef.current.fire();
      setConfettiShown({ ...confettiShown, stakeShown: true });
    }
  }, [stakeState, confettiShown]);

  useEffect(() => {
    if (unstakeState.type === "finish" && !confettiShown.unstakeShown) {
      confettiRef.current.fire();
      setConfettiShown({ ...confettiShown, unstakeShown: true });
    }
  }, [unstakeState, confettiShown]);

  useEffect(() => {
    async function stake() {
      try {
        dispatch({ type: "STAKING", payload: true });
        setConfettiShown({ ...confettiShown, stakeShown: false });
        await callAPI(
          "stake",
          authState.accountAlgo,
          ctc,
          reach,
          reach.parseCurrency(amount),
          stakingDuration
        );

        setUpdateStakingData(updateStakingData + 1);
        dispatch({ type: "CONFIRM", payload: "" });
        document.getElementById("stakeAmountInput").value = 0;
      } catch (err) {
        console.error(err);;
        toast.error("Staking has failed. Please try again");
        dispatch({ type: "ERROR" });
      } finally {
        setConfirmed(false);
      }
    }
    if (
      stakingData &&
      stakingData.staked > 0 &&
      increasingDuration &&
      confirmed
    ) {
      stake();
    }
  }, [confirmed, increasingDuration, stakingData]);

  const validateStake = async () => {
    try {
      // check the connection exsitence
      const { exists } = checkSessionExists();
      if (!exists) {
        disconnectAlgoWallet(signoutAlgo);
        return;
      }
      // Checks before sending to contract
      if (amount < Number(stakingData.minStaking)) {
        toast.error(
          `Please select an amount of ${parseFloat(
            Number(stakingData.minStaking).toFixed(4)
          ).toLocaleString(undefined, { maximumFractionDigits: 4 })} or higher`
        );
        return;
      }
      if (amount > Number(stakingData.maxStakePerStake)) {
        toast.error(
          `Please select an amount of ${parseFloat(
            Number(stakingData.maxStakePerStake).toFixed(4)
          ).toLocaleString(undefined, { maximumFractionDigits: 4 })} or lower`
        );
        return;
      }
      if (bal < amount) {
        toast.error(`You are trying to stake more than your balance`);
        return;
      }
      if (stakingData.stakingPaused) {
        toast.error(`Staking is currently paused`);
        return;
      }
      if (stakingDuration < sliderMin[0] || stakingDuration > sliderMax[0]) {
        toast.error(
          `Please select a duration between ${sliderMin[0]} and ${sliderMax[0]}`
        );
        return;
      }

      if (increasingDuration && stakingData.staked > 0) {
        setShownWarningModal(true);
      } else {
        dispatch({ type: "STAKING", payload: true });
        setConfettiShown({ ...confettiShown, stakeShown: false });
        await callAPI(
          "stake",
          authState.accountAlgo,
          ctc,
          reach,
          reach.parseCurrency(amount),
          stakingDuration
        );

        setUpdateStakingData(updateStakingData + 1);
        dispatch({ type: "CONFIRM", payload: "" });
        document.getElementById("stakeAmountInput").value = 0;
      }
    } catch (err) {
      console.error(err);;
      toast.error("Staking has failed. Please try again");
      dispatch({ type: "ERROR" });
    }
  };

  const approveToken = async () => {
    try {
      // check the connection exsitence
      const { exists } = checkSessionExists();
      if (!exists) {
        disconnectAlgoWallet(signoutAlgo);
        return;
      }
      dispatch({ type: "APPROVING" });
      await authState.accountAlgo.tokenAccept(ctcparse(stakingToken));
      let acceptedTokenYet = await authState.accountAlgo.tokenAccepted(
        ctcparse(stakingToken)
      );
      if (acceptedTokenYet) {
        dispatch({ type: "APPROVED" });
      }
      setAcceptedToken(acceptedTokenYet);
    } catch (err) {
      console.error(err);;
      toast.error("You don't have enough algo to accept this token");
      dispatch({ type: "ERROR" });
    }
  };

  const inputChangeAmount = (value) => {
    if (stakingData.userStakeEnd > 0 && value > 0) {
      displayWarningForStaking();
    } else {
      setIncreasingDuration(false);
    }
    setAmount(value);
  };

  const applyMax = () => {
    const limit =
      stakingData.maxStakePerStake <
      stakingData.maxStakingPool - stakingData.totalStaked
        ? stakingData.maxStakePerStake
        : stakingData.maxStakingPool - stakingData.totalStaked;
    let max = bal > limit ? limit : bal;

    if (max !== 0) {
      max = Number(max.toString()).toFixed(4);
      document.getElementById("stakeAmountInput").value = max;
      setAmount(max);
      displayWarningForStaking();
    }
  };

  // const marks = [
  //   {
  //     value: sliderMin[0],
  //     label: `${sliderMin[0]} Days (${sliderMin[1]}% APY)`,
  //   },
  //   {
  //     value: sliderMid[0],
  //     label: `${sliderMid[0]} Days (${sliderMid[1]}% APY)`,
  //   },
  //   {
  //     value: sliderMax[0],
  //     label: `${sliderMax[0]} Days (${sliderMax[1]}% APY)`,
  //   },
  // ];

  const handleConnect = async () => {
    setIsShownConnect(true);
  };

  const unstake = async () => {
    try {
      // check the connection exsitence
      const { exists } = checkSessionExists();
      if (!exists) {
        disconnectAlgoWallet(signoutAlgo);
        return;
      }
      if (unstakeUnavailable) {
        toast.error(`There isn't enough rewards available to unstake`);
        return;
      }

      dispatchUnstake({ type: "UNSTAKING", payload: true });
      setConfettiShown({ ...confettiShown, unstakeShown: false });
      await callAPI("unstake", authState.accountAlgo, ctc, reach);
      setUpdateStakingData(updateStakingData + 1);
      dispatchUnstake({ type: "CONFIRM", payload: "" });
    } catch (err) {
      console.error(err);;
      toast.error("Unstaking has failed. Please try again");
      dispatchUnstake({ type: "ERROR" });
    }
  };

  // const updateStakingDuration = (v) => {
  //   setStakingDuration(v);
  //   if (amount > 0) {
  //     displayWarningForStaking();
  //   }
  // };

  return (
    <div className="mx-2 mx-sm-auto">
      <StakingTitleBar
        isAdmin={isAdmin}
        totalStaked={totalStaked}
        maxStakingPool={maxStakingPool}
      />
      <div
        id="stakeCard"
        className="card mb-4 mx-auto"
        style={{ maxWidth: "550px" }}
      >
        <div className="card-body row justify-content-center">
          <div className="col-11">
            <div className="row pb-4">
              <span id="walletBalance" className="col">
                <img
                  src={DBDToken}
                  height="20"
                  alt="DBD"
                  className="mb-1 pr-3"
                />
                Wallet Balance:&nbsp;
                {authState.accountAlgo !== 0 && stakingData !== undefined
                  ? parseFloat(bal.toFixed(4)).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                    })
                  : 0}
              </span>
            </div>

            {acceptedToken && authState.accountAlgo && (
              <div>
                <div className="row mb-1">
                  <div className="input-group col align-self-end">
                    <div className="input-group-prepend">
                      <img
                        src={DBDToken}
                        alt="DBD"
                        id="dbdLogo"
                        className="mr-1"
                      />
                    </div>
                    <input
                      className="form-control form-control-sm stakeInput"
                      id="stakeAmountInput"
                      type="number"
                      step="0.0001"
                      min="0"
                      placeholder="0"
                      onChange={(e) => inputChangeAmount(e.currentTarget.value)}
                    />
                  </div>
                  <div className="pt-4 text-center"></div>
                </div>

                {authState.accountAlgo !== 0 && stakingData !== undefined && (
                  <div className="text-right">
                    <div className="linkText" onClick={() => applyMax()}>
                      Click to Enter Max DBD
                    </div>
                  </div>
                )}
              </div>
            )}

            {Number(globLimit) < Number(amount) && (
              <p className="text-danger text-right mb-3">
                Maximum DBD Stake limit reached for this transaction
              </p>
            )}

            {authState.accountAlgo && acceptedToken === false && !isConnecting && (
              <div className="mb-2 mt-4">
                <div className="row">
                  <button
                    className="btn btn-block btn-secondary"
                    onClick={() => approveToken()}
                  >
                    Approve DBD
                  </button>
                </div>
              </div>
            )}

            {isConnecting && (
              <div className="mb-2 mt-4">
                <div className="row">
                  <button
                    disabled={true}
                    className="btn btn-block btn-secondary"
                    id="approvalBtn"
                    onClick={() => approveToken()}
                  >
                    connecting account ...
                    <div
                      className="spinner-border spinner-border-sm approveSpinner"
                      role="status"
                    ></div>
                  </button>
                </div>
              </div>
            )}

            {!authState.accountAlgo && (
              <div className="mb-2 mt-4">
                <div className="row">
                  <button
                    className="btn btn-block btn-secondary"
                    onClick={() => handleConnect()}
                  >
                    Connect Wallet
                  </button>
                </div>
              </div>
            )}

            {authState.accountAlgo && acceptedToken === 2 && !isConnecting && (
              <div className="mb-2 mt-4">
                <div className="row">
                  <button
                    disabled={true}
                    className="btn btn-block btn-secondary"
                    id="approvalBtn"
                    onClick={() => approveToken()}
                  >
                    loading account ...
                    <div
                      className="spinner-border approveSpinner"
                      role="status"
                    ></div>
                  </button>
                </div>
              </div>
            )}

            {authState.accountAlgo &&
            acceptedToken === true &&
            !isConnecting ? (
              <div className="my-2">
                <div className="mb-5 mb-sm-3 font-weight-bold secondary-color">
                  {/* <CstmSlider
                    defaultValue={90}
                    aria-label="Default"
                    valueLabelDisplay="on"
                    onChange={(e, v) => updateStakingDuration(v)}
                    min={sliderMin[0]}
                    max={sliderMax[0]}
                    marks={marks}
                  /> */}
                  <FontAwesomeIcon
                    icon={faCalendarWeek}
                    className="secondary-color"
                  />
                  &nbsp;&nbsp;Locked for {sliderMin[0]} Days at {sliderMin[1]}%
                  APY
                </div>
                <div className="row mt-2">
                  <button
                    disabled={Boolean(stakingData.stakingPaused)}
                    className="btn btn-block btn-secondary mt-2"
                    onClick={() => validateStake()}
                  >
                    Stake DBD
                  </button>
                </div>

                {stakingData.stakingPaused && (
                  <span id="PauseStaking" className="col">
                    Staking has been paused on this site. <br />You can still unstake.
                  </span>
                )}
              </div>
            ) : (
              <div className="mb-4 mt-4"></div>
            )}
          </div>
        </div>
        <StakeModal stakeState={stakeState} dispatch={dispatch} from="algo" />
        <UnstakeModal
          unstakeState={unstakeState}
          dispatch={dispatchUnstake}
          from="algo"
        />
      </div>

      <StakingWarningModal
        message={warningMsg}
        shown={shownWarningModal}
        setShown={setShownWarningModal}
        setConfirmed={setConfirmed}
      />
      {increasingDuration && stakingData.staked > 0 && <></>}

      <StakingInfo
        min={stakingData ? stakingData.minStaking : 0}
        limit={globLimit}
      />

      <div
        // className="mb-4 mx-auto"
        className="mx-auto alert alert-warning alert-dismissible fade show"
        role="alert"
        style={{
          maxWidth: "600px",
          fontSize: "13px",
        }}
      >
        *Please Note: Adding additional {process.env.REACT_APP_TOKENSYMBOL} to
        your staking amount will add to your staking duration. APY rewards are
        calculated automatically by the Algorand PPOS Consensus Mechanism.
      </div>

      {stakingData && stakingData.staked > 0 && (
        <>
          <StakingHistoryAlgo
            updated={updateStakingData}
            stakingData={stakingData}
            unstakingFunction={unstake}
          />
        </>
      )}

      <FireConfetti ref={confettiRef} />

      <Loading loadingStatus={loading} />

      <AlgoConnect isShown={isShownConnect} setIsShown={setIsShownConnect} />
    </div>
  );
}

export default StakeCardAlgoLegacy;
