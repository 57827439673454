import React, { useReducer, createContext } from "react";

const initialState = {
  web3Modal: null,
  walletConnectProvider: null,
  provider: null,
  web3: null,
};

export const EthProviderContext = createContext(initialState);

const ethProviderReducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return { ...state, walletConnectProvider: action.payload };
    case "CONNECT":
      return { ...state, provider: action.payload };
    case "DISCONNECT":
      return { ...state, provider: null, web3: null };
    default:
      return state;
  }
};

export function EthProvider({ children }) {
  const [ethProviderState, dispatch] = useReducer(
    ethProviderReducer,
    initialState
  );

  const initWalletConnectProvider = (value) => {
    dispatch({ type: "INIT", payload: value });
  };

  const connectProvider = (value) => {
    dispatch({ type: "CONNECT", payload: value });
  };

  const disconnectProvider = () => {
    dispatch({ type: "DISCONNECT" });
  };

  const value = {
    ethProviderState,
    initWalletConnectProvider,
    connectProvider,
    disconnectProvider,
  };

  return (
    <EthProviderContext.Provider value={value}>
      {children}
    </EthProviderContext.Provider>
  );
}
