import React, { useState, useEffect, useContext, useRef } from "react";

import Pools from "components/main/Pools";

import { AuthContext } from "context/authContext";

import { getContractListInitial, getContractListBlockchainPerElement } from "utils/insurefiHelpers";
import { PickExpectedStakeToken } from "utils/miscHelpers";
import { AlgoProviderContext } from "context/algoProviderContext";
import { ReconnectWallet } from "services/auth.service";
import { getAppliactionInfoById } from "services/contract.service";
import { getApplicationAddress } from "algosdk";

function AlgorandPage() {
  const { authState, signinAlgo } = useContext(AuthContext);
  const { algoProviderState, connectAlgoProvider } =
  useContext(AlgoProviderContext);

  const [poolData, setPoolData] = useState([]);
  const [totalStaked, setTotalStaked] = useState(0);

  const [initialPoolDataLoaded, setInitialPoolDataLoaded] = useState(false);

  const getStaked = async (pool, address) => {
    let contractData = await getAppliactionInfoById(
      Number(pool.appId),
      getApplicationAddress(Number(pool.appId)),
      address
    );
    const globalInfoString = JSON.stringify(contractData);
    const algoObj = JSON.parse(globalInfoString);

    return algoObj.staked;
  };

  const updatedStakedValues = async (poolsDetails, address) => {
    if (address) {
      let updatedPoolData = await Promise.all(
        poolsDetails.map(async (pool) => {
          if (pool.staked === -1)
            return { ...pool, staked: await getStaked(pool, address) };
          return { ...pool };
        })
      );
      return updatedPoolData;
    }
  };

  // Get Pool Data inital call
  useEffect(() => {
    async function getContracts() {
      let contractsData = await getContractListInitial(authState.accountAlgo);

      setPoolData(contractsData);

      setInitialPoolDataLoaded(true);
    }

    getContracts();
  }, []);

  // UseEffect that triggers itself until all values are covered
  const lastUpdatedIndexRef = useRef(0);
  useEffect(() => {
    async function getContracts() {
      // Get the index that needs updating
      let tmpTotalStaked = totalStaked;
      let i = lastUpdatedIndexRef.current;
      if (i >= poolData.length) return;
      let oldPoolData = poolData;

      // Update current index data
      oldPoolData[i] = await getContractListBlockchainPerElement(oldPoolData[i], authState.accountAlgo);

      let theChosenOne = Number(await PickExpectedStakeToken(oldPoolData[i].stakeTokens, oldPoolData[i].appId))
      if (theChosenOne === Number(process.env.REACT_APP_DBDV2)) tmpTotalStaked += oldPoolData[i].totalStaked;
      setTotalStaked(tmpTotalStaked);

      // Check if we have a users address // Check last element if its staked == -1 and i is > 0
      if (authState.accountAlgo && i > 0 && oldPoolData[i - 1].staked === -1) {
        
        // Loop through all elements getting correct staked value only
        oldPoolData = await updatedStakedValues(oldPoolData, authState.accountAlgo);
      }

      setPoolData([...oldPoolData]);
      lastUpdatedIndexRef.current++;
    }

    if (initialPoolDataLoaded) getContracts();
  }, [initialPoolDataLoaded, poolData]);

  // Reconnect Pera
  useEffect(() => {
    async function reconnectPera() {
      if (
        algoProviderState &&
        algoProviderState.provider &&
        algoProviderState &&
        !algoProviderState.accountAlgo
      ) {
        let address = await ReconnectWallet(
          signinAlgo,
          algoProviderState.provider
        );

        await updatedStakedValues(poolData, address);
      }
    }

    reconnectPera();
  }, [algoProviderState]);

  return (
    <>
      <div className="text-center mt-3">
        <Pools totalStaked={totalStaked} poolsData={poolData} />
      </div>
    </>
  );
}

export default AlgorandPage;