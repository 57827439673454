import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "context/authContext";

function Pools({ totalStaked, poolsData }) {
  const { authState } = useContext(AuthContext);

  const [pools, setPools] = useState(poolsData);
  const [isAdmin, setIsAdmin] = useState(false);

  // Note: Initial value passed is null as component loads on page load, not when data is recieved
  useEffect(() => {
    setPools(poolsData);
  }, [poolsData]);

  useEffect(() => {
    if (authState && authState.accountAlgo === process.env.REACT_APP_STAKINGADMIN_ADDRESS) setIsAdmin(true);
  }, [authState]);

  function getPoolInfo(pool) {
    if (!pool.apy) return "";
    
    if (pool.poolName.toLowerCase().includes('nft')) {
        const rewardText = Number(pool.rewardASA) === Number(process.env.REACT_APP_DBDV2) ? 'DBD' : '';
        return `${pool.rewardRatio / 100 / 1000000} ${rewardText}`;
    } else {
        return `${pool.apy}% APY`;
    }
  }

  return (
    <div className="container">
      <div className="pool-container mx-auto text-left my-5">
        <div className="row mb-4">
        {isAdmin && (
          <div className="mb-3 text-center">
            <Link
              to="/admin"
              state={{ network: '/algorand' }}
              className="primary-color"
              children="Head to Admin Portal"
            />
          </div>
        )}
          <div className="col-12 text-center">
            <span className="font-weight-bold d-inline-block mb-2">
              Total Staked
            </span>
            <h2 className="Amt-Primary">
              {totalStaked
                ? parseFloat(Number(totalStaked).toFixed(2)).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )
                : 0}
              &nbsp;
              {process.env.REACT_APP_TOKENSYMBOL}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* Pool details */}
            <div className="row pool-details">
              <div className="col-12 text-left">
                <h5 className="mb-3">Pools</h5>
              </div>
              <div className="col-12 table-responsiveness">
                <table className="pool-table">
                  <thead>
                    <tr className="heading">
                      <th className="border-side-left text-left">Pool</th>
                      <th>Status</th>
                      <th>Duration</th>
                      <th>Reward</th>
                      <th className="text-right">Capacity</th>
                      <th className="border-side-right text-right">Actions</th>
                    </tr>
                  </thead>
                  <thead className="spacing">
                    <tr>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>

                  <tbody>
                    {pools.map((pool, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "even-row" : ""}
                      >
                        <td className="title">
                          <strong>{pool.poolName}</strong>
                        </td>
                        <td>
                          {pool.active ? (
                            <span className="badge badge-pill badge-success px-2">
                              <i className="fas fa-circle fa-xs mr-1"></i>Active
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-warning px-2">
                              <i className="fas fa-pause fa-xs mr-1"></i>Paused
                            </span>
                          )}
                        </td>
                        <td>{pool.duration}</td>
                        <td>{getPoolInfo(pool)}
                        </td>
                        <td className="text-right">{pool.totalLimit}</td>
                        <td className="text-right">
                          {pool.staked > 0 ? (
                            <Link
                              to={"/algorand/pool/" + pool.appId}
                              className="btn btn-sm btn-secondary btn-fill ml-2"
                            >
                              <FontAwesomeIcon
                                icon={faArrowUpFromBracket}
                                className="icon-primary  pr-2"
                              />
                              Unstake
                            </Link>
                          ) : (
                            <Link
                              to={"/algorand/pool/" + pool.appId}
                              className="btn btn-sm btn-secondary ml-2"
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="icon-primary pr-2"
                              />
                              Stake
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pools;
