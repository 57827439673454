import React from "react";

import LandingPageButton from "components/layout/misc/LandingPageButton";

// images
import PolygonLogo from "assets/matic.png";
import AlgorandLogo from "assets/algorand_icon_circle-dark.svg";

function LandingPage() {
  return (
    <div
      className="d-flex justify-content-center align-items-center mt-3"
      style={{ height: "600px" }}
    >
      <div>
        <div className="text-center mx-3 mb-md-3 mt-md-0">
          <h2 className="font-weight-bold">DBD Staking</h2>
          <p className="text-secondary sub-heading large">Choose a network below</p>
        </div>

        <div className="d-flex justify-content-center flex-column flex-md-row">

          <LandingPageButton text='Algorand' logo={AlgorandLogo} legacyLink={true} toLink="/algorand"/>

          <LandingPageButton text='Polygon' logo={PolygonLogo} toLink="/polygon"/>

        </div>



      </div>

    </div>
  );
}

export default LandingPage;
