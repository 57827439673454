import React, { useEffect } from "react";
// Icon Images
import CEXDEXtrading from "assets/Icons/CEXDEXtrading.png";
import ClaimsSettlement from "assets/Icons/ClaimsSettlement.png";
import DAOPlatformGovernance from "assets/Icons/DAOPlatformGovernance.png";
import DAOStaking from "assets/Icons/DAOStaking.png";
import EarnDBDforregisteringAssets from "assets/Icons/EarnDBDforregisteringAssets.png";
import InvestInUnderwritingPools from "assets/Icons/InvestInUnderwritingPools.png";
import MintDayByDayNFTswithbonuspremiumincome from "assets/Icons/MintDayByDayNFTswithbonuspremiumincome.png";
import NFTPolicyOwnership from "assets/Icons/NFTPolicyOwnership.png";
import StakeandEarn from "assets/Icons/StakeandEarn.png";
//gif
import gif from "assets/Staking-DBD.gif";
// import { faUnderline } from '@fortawesome/free-solid-svg-icons';

function UseCase() {

  useEffect(() => {
    if (window.innerWidth < 992) // If screen is mobile
    {
      document.getElementById("gifcontainerTop").style.display = "block"
      document.getElementById("gifcontainerBottom").style.display = "none"
    } else {
      document.getElementById("gifcontainerTop").style.display = "none"
      document.getElementById("gifcontainerBottom").style.display = "block"
    }
  });


  return (
    <div className="container-fluid useCaseSection justify-content-center py-3 px-5">
      <div className="row align-items-center">
      <div className="col-lg-6 col-12" id="gifcontainerTop">
          <div className="row justify-content-center">
            <img
              id="gif"
              src={gif}
              alt="InvestInUnderwritingPools"
              className="d-inline-block align-top"
            />
          </div>
        </div>
        <div className="icons col-lg-6 col-12">
          <div className="row justify-content-center iconRow">
            <div className="icon  col-md-4 col-6">
              <img
                src={DAOPlatformGovernance}
                alt="InvestInUnderwritingPools"
                className="iconImage"
              ></img>
              <p className="align-center iconTitle">
                DAO Platform
                <br />
                Governance
              </p>
            </div>
            <div className="icon  col-md-4 col-6">
              <img
                src={DAOStaking}
                alt="InvestInUnderwritingPools"
                className="iconImage"
              ></img>
              <p className=" align-center iconTitle">DAO Staking</p>
            </div>
            <div className="icon  col-md-4 col-6">
              <img
                src={NFTPolicyOwnership}
                alt="InvestInUnderwritingPools"
                className="iconImage"
              ></img>
              <p className="align-center iconTitle">
                NFT Policy
                <br />
                Ownership
              </p>
            </div>
            <div className="icon  col-md-4 col-6">
              <img
                src={EarnDBDforregisteringAssets}
                alt="InvestInUnderwritingPools"
                className="iconImage"
              ></img>
              <p className="align-center iconTitle">
                Earn DBD for
                <br />
                Registering Assets
              </p>
            </div>
            <div className="icon col-md-4 col-6">
              <img
                src={ClaimsSettlement}
                alt="InvestInUnderwritingPools"
                className="iconImage"
              ></img>
              <p className="align-center iconTitle">Claims Settlement</p>
            </div>
            <div className="icon col-md-4 col-6">
              <img
                src={InvestInUnderwritingPools}
                alt="InvestInUnderwritingPools"
                className="iconImage"
              ></img>
              <p className="align-center iconTitle">
                Invest in
                <br />
                Underwriting Pools
              </p>
            </div>
            <div className="icon col-md-4 col-6">
              <img
                src={CEXDEXtrading}
                alt="InvestInUnderwritingPools"
                className="iconImage"
              ></img>
              <p className="align-center iconTitle">CEX/DEX trading</p>
            </div>
            <div className="icon col-md-4 col-6">
              <img
                src={StakeandEarn}
                alt="InvestInUnderwritingPools"
                className="iconImage"
              ></img>
              <p className="align-center iconTitle">Stake to Earn</p>
            </div>
            <div className="icon col-md-4 col-6">
              <img
                src={MintDayByDayNFTswithbonuspremiumincome}
                alt="InvestInUnderwritingPools"
                className=" align-center iconImage"
              ></img>
              <p className="align-center iconTitle">
                Mint Day By Day
                <br />
                NFTs with Bonus Premium Income
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12" id="gifcontainerBottom">
          <div className="row justify-content-center">
            <img
              id="gif"
              src={gif}
              alt="InvestInUnderwritingPools"
              className="d-inline-block align-top"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UseCase;
