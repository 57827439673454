import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// context
import { AuthContext } from "context/authContext";
import { AlgoProviderContext } from "context/algoProviderContext";
// hooks
import { useAccountProvider } from "hooks/useAlgo";
// utils
import {
  callPyTealContract,
  getAppliactionInfoById,
} from "services/contract.service";
import { toast } from "react-toastify";
import { calculateRewardRatio } from "utils/math";
// images
import DBDToken from "assets/DBDTokenLogo.png";
import algosdk from "algosdk";
import {
  generateTransferTransaction,
  signAndSendSingleTransaction,
  getBalance,
} from "utils/algoSDKHelpers";
import { UpdateContract, GetPoolDetails } from "utils/insurefiHelpers";

function AlgoGeneralSettingsCard(props) {
  const { contractInfo } = props;

  const { algoProviderState } = useContext(AlgoProviderContext);
  const { authState } = useContext(AuthContext);
  const providerDetails = useAccountProvider(algoProviderState);

  const [upDateStakingData, setUpDateStakingData] = useState(0);

  // Algo
  const [algostakingData, setAlgostakingData] = useState(null);

  // updating values
  const [rewardAmount, setRewardAmount] = useState(0);
  // updating staking values
  const [minStake, setMinStake] = useState(0);
  const [maxStake, setMaxStake] = useState(0);
  const [maxStakingPool, setMaxStakingPool] = useState(0);
  // updating staking thresholds days
  const [duration, setDuration] = useState(0);
  // updating staking thresholds %
  const [apy, setAPY] = useState(0);
  const [directRewardRatio, setDirectRewardRatio] = useState(0);

  const [displayName, setDisplayName] = useState("");

  const [optSelectedToken, setOptSelectedToken] = useState(0);

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const pause = false;
  const navigate = useNavigate();

  useEffect(() => {
    async function getAppInfo() {
      const contractData = await getAppliactionInfoById(
        contractInfo.AppId,
        contractInfo.AppAddress,
        null
      );
      setAlgostakingData(contractData);

      if (authState.accountAlgo === process.env.REACT_APP_STAKINGADMIN_ADDRESS) return;
      
      const tokenBal = await getBalance(
        authState.accountAlgo,
        contractData.adminASA
      );

      if (tokenBal <= 0) {
        toast.error("Failed to validate your permissions");
        navigate("/algorand");
      }
    }

    getAppInfo();
  }, [upDateStakingData]);

  useEffect(() => {
    async function updateValues() {
      setMinStake(algostakingData.minStaking);
      setMaxStake(algostakingData.maxStakePerStake);
      setMaxStakingPool(algostakingData.maxStakingPool);

      setAPY(algostakingData.durationApy[0]);
      setDirectRewardRatio(algostakingData.rewardRatio);
      setDuration(algostakingData.durationThreshold[0]);

      const contractData = await GetPoolDetails(contractInfo.AppId);
      setDisplayName(contractData.displayName); // Need to get the pool name for this to work
    }

    if (algostakingData) {
      updateValues();
    }
  }, [algostakingData]);

  const updatePause = async (pausing) => {
    const adminASA = algostakingData.adminASA;

    await callPyTealContract(
      "setPaused",
      pausing ? 1 : 0,
      adminASA,
      authState.accountAlgo,
      providerDetails.provider,
      providerDetails.type,
      contractInfo.AppId
    );

    await UpdateContract(contractInfo.AppId, {
      paused: pausing,
      displayName: null,
      image: null,
      contentType: null,
    });

    setUpDateStakingData(upDateStakingData + 1);
    toast.success("Pause updated");
  };

  const updateDisplayName = async () => {
    await UpdateContract(contractInfo.AppId, {
      paused: null,
      displayName: displayName,
      image: null,
      contentType: null,
    });

    toast.success("Pool name updated");
  };

  const updateImage = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const base64Image = await new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });

    await UpdateContract(contractInfo.AppId, {
      paused: null,
      displayName: null,
      image: base64Image,
      contentType: file.type,
    });

    toast.success("Image updated");
  };

  const updateAlgoSettings = async () => {
    try {
      let durationInDays = duration * 86400;

      await callPyTealContract(
        "updateSettings",
        algostakingData.adminASA,
        algostakingData.adminASA,
        algosdk.algosToMicroalgos(minStake),
        algosdk.algosToMicroalgos(maxStake),
        algosdk.algosToMicroalgos(maxStakingPool),
        directRewardRatio == algostakingData.rewardRatio ? calculateRewardRatio(durationInDays, apy) : displayName.toLowerCase().includes("nft") ? directRewardRatio * 100 * 1000000 : directRewardRatio * 100,
        durationInDays,
        algostakingData.stakeCreatorAddress,
        authState.accountAlgo,
        providerDetails.provider,
        providerDetails.type,
        contractInfo.AppId
      );

      setUpDateStakingData(upDateStakingData + 1);
      toast.success("Settings updated");
    } catch (err) {
      console.error(err);
      toast.error("Failed to update algo settings");
    }
  };

  const removeFromRewardPool = async () => {
    try {
      const adminASA = algostakingData.adminASA;

      await callPyTealContract(
        "migrateToken",
        rewardAmount,
        Number(process.env.REACT_APP_DBDV2),
        adminASA,
        authState.accountAlgo,
        providerDetails.provider,
        providerDetails.type,
        contractInfo.AppId
      );
      setUpDateStakingData(upDateStakingData + 1);
      toast.success("Remove tokens from reward pool successfully.");
    } catch (err) {
      toast.error("Failed to remove the rewards.");
    }
  };

  const addToRewardPool = async () => {
    try {
      // Generate transaction
      let transferTxn = await generateTransferTransaction(
        authState.accountAlgo,
        contractInfo.AppAddress,
        algosdk.algosToMicroalgos(rewardAmount),
        undefined,
        Number(contractInfo.RewardASA),
        true
      );

      // Send transaction
      await signAndSendSingleTransaction(
        transferTxn,
        authState.accountAlgo,
        providerDetails.provider,
        providerDetails.type
      );

      setUpDateStakingData(upDateStakingData + 1);
      toast.success("Add tokens to reward pool successfully.");
    } catch (err) {
      toast.error("Failed to add the rewards.");
      console.error(err);
    }
  };

  const opt = async (type) => {
    if (type === "in") {
      // Generate transaction
      let transferTxn = await generateTransferTransaction(
        authState.accountAlgo,
        contractInfo.AppAddress,
        0.1,
        undefined,
        0,
        true
      );

      // Send transaction
      await signAndSendSingleTransaction(
        transferTxn,
        authState.accountAlgo,
        providerDetails.provider,
        providerDetails.type
      );

      await callPyTealContract(
        "opt_into_asset",
        optSelectedToken,
        algostakingData.adminASA,
        authState.accountAlgo,
        providerDetails.provider,
        providerDetails.type,
        contractInfo.AppId
      );
      toast.success(`${optSelectedToken} has been opted into contract`);
    } else if (type === "out") {
      await callPyTealContract(
        "opt_out_asset",
        optSelectedToken,
        algostakingData.adminASA,
        authState.accountAlgo,
        providerDetails.provider,
        providerDetails.type,
        contractInfo.AppId
      );
      toast.success(`${optSelectedToken} has been opted out of contract`);
    }
    setUpDateStakingData(upDateStakingData + 1);
  };

  const deleteContract = async () => {
    await updatePause(true);

    await fetch(
      process.env.REACT_APP_BASEURL + `Stake/delete/${contractInfo.AppId}`,
      {
        method: "Delete",
        headers: {
          "Content-Type": "application/json",
        },
        body: null,
      }
    ).then((response) => {
      if (!response.ok) {
        toast.error("Error calling backend");
        console.error(response);
        throw new Error("Error calling backend");
      }
    });

    toast.success("Contract Deleted");
  };

  return (
    <div className="card mb-4 mx-auto card-width">
      <div className="text-center">
        <h2 className="genSettingsHeading">Algo General Settings:</h2>
      </div>
      {!authState.isAdminAlgo ? (
        <div className="text-center"></div>
      ) : (
        <div>
          <h2 className="genSettingsHeading">Algo General Settings:</h2>
          <div className="my-3 text-center h5 font-weight-bold">
            Staking Status
          </div>
          <div className="mb-5 d-flex justify-content-center">
            {algostakingData && (
              <div>
                {algostakingData.stakingPaused ? (
                  <button
                    disabled={!authState.isAdminAlgo}
                    type="button"
                    className="btn btn-lg  bg-primary-color text-white rounded-pill"
                    onClick={() => updatePause(false)}
                  >
                    Continue staking
                  </button>
                ) : (
                  <button
                    disabled={!authState.isAdminAlgo}
                    type="button"
                    className="btn btn-lg  bg-primary-color text-white rounded-pill"
                    onClick={() => updatePause(true)}
                  >
                    Pause staking
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="mb-5 d-flex justify-content-center">
            <input type="file" onChange={updateImage} />
          </div>

          {/* Reward Pool Balance Update */}
          <div className="mx-4">
            <div>
              <label className="float-left">
                <b>Reward Tokens</b>
              </label>
              <span className="float-right text-muted">
                Reward Balance:&nbsp;
                {algostakingData ? algostakingData.remainingRewards : 0}
              </span>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                className="form-control form-control-lg"
                placeholder={
                  algostakingData ? algostakingData.remainingRewards : 0
                }
                onChange={(e) => setRewardAmount(e.currentTarget.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <img src={DBDToken} height="32" alt="DBD" />
                  &nbsp;&nbsp;&nbsp;DBD
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-25 mr-4 bg-primary-color text-white rounded-pill"
              onClick={() => addToRewardPool()}
            >
              Add
            </button>
            <button
              disabled={!authState.isAdminAlgo}
              style={{ whiteSpace: "nowrap" }}
              className="btn btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => removeFromRewardPool()}
            >
              Remove
            </button>
          </div>

          {/* Stake Amount Update */}
          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Min Stake per Stake</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.minStaking : 0}
                  </span>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.minStaking : 0
                    }
                    onChange={(e) => setMinStake(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Max Stake Per Stake</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.maxStakePerStake : 0}
                  </span>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    min="0"
                    step={1}
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.maxStakePerStake : 0
                    }
                    onChange={(e) => setMaxStake(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Max Staking Pool</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.maxStakingPool : 0}
                  </span>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    step={1}
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.maxStakingPool : 0
                    }
                    onChange={(e) => setMaxStakingPool(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Duration &nbsp;</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.durationThreshold[0] : 0}
                  </span>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.durationThreshold[0] : 0
                    }
                    onChange={(e) => setDuration(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>APY &nbsp;</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.durationApy[0] : 0}
                  </span>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.durationApy[0] : 0
                    }
                    onChange={(e) => setAPY(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
            
            <div className="col-sm">
              <div className="mx-4">
                <div>
                  <label className="float-left">
                    <b>Or Reward Ratio &nbsp;</b>
                  </label>
                  <span className="float-left text-muted">
                    Currently:&nbsp;
                    {algostakingData ? algostakingData.rewardRatio / 100 : 0}
                  </span>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-control-lg"
                    placeholder={
                      algostakingData ? algostakingData.rewardRatio / 100 : 0
                    }
                    onChange={(e) => setDirectRewardRatio(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mb-5">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => updateAlgoSettings(pause, "amount")}
            >
              Update values
            </button>
          </div>

          <div>
            <div>
              <label className="float-left">
                <b>Pool Name</b>
              </label>
            </div>
            <div className="input-group mb-2">
              <input
                type="text"
                min="0"
                className="form-control form-control-lg"
                onChange={(e) => setDisplayName(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-25 mr-4 bg-primary-color text-white rounded-pill"
              onClick={() => updateDisplayName()}
            >
              Update
            </button>
          </div>

          {/* Opt in or Out */}
          <div>
            <div>
              <label className="float-left">
                <b>Opt Contract in or out of token</b>
              </label>
            </div>
            <div className="input-group mb-2">
              <input
                type="number"
                min="0"
                className="form-control form-control-lg"
                onChange={(e) => setOptSelectedToken(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <button
              disabled={!authState.isAdminAlgo}
              className="btn btn-lg w-25 mr-4 bg-primary-color text-white rounded-pill"
              onClick={() => opt("in")}
            >
              Opt-In
            </button>
            <button
              disabled={!authState.isAdminAlgo}
              style={{ whiteSpace: "nowrap" }}
              className="btn btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => opt("out")}
            >
              Opt-Out
            </button>
          </div>

          <div className="d-flex justify-content-center mb-5">
            <button
              hidden={deleteConfirmed}
              style={{ whiteSpace: "nowrap" }}
              className="btn btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => setDeleteConfirmed(true)}
            >
              Delete Contract
            </button>
            <button
              hidden={!deleteConfirmed}
              style={{ whiteSpace: "nowrap" }}
              className="btn btn-lg w-25 bg-primary-color text-white rounded-pill"
              onClick={() => deleteContract()}
            >
              Confirm Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AlgoGeneralSettingsCard;
