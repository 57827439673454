import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faFacebookF,
  faXTwitter,
  faTelegramPlane,
  faDiscord,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";


import optDBD from "../../assets/algorand-algo-icon.svg";
import optPolygon from "../../assets/footer/MetaMask_Fox.svg.png";
import mediumIcon from "../../assets/community/medium.svg";
import twitterIcon from "../../assets/community/twitter.svg";
import discordIcon from "../../assets/community/discord.svg";
import linkedinIcon from "../../assets/community/linkedln.svg";
import telegramIcon from "../../assets/community/telegram.svg";
import facebookIcon from "../../assets/community/facebook.svg";
import { AuthContext } from "context/authContext";
import { EthProviderContext } from "context/ethProviderContext";
import { AlgoProviderContext } from "context/algoProviderContext";
import { toast } from "react-toastify";
import { chainParams, PERA_WALLET } from "utils/constants";
import { addERC20Token } from "services/contract.service";
import { acceptToken } from "utils/algoSDKHelpers";
import { tokenAccepted } from "utils/algoSDKHelpers";
import { stakingToken } from "utils/constants";
import { ctcparse } from "utils/reachHelpers";

const socialMediaInfo = [
  {
    icon: mediumIcon,
    link: "https://medium.com/@daybydayio",
    text: " Medium"
  },
  {
    icon: twitterIcon,
    link: "https://twitter.com/daybydayio",
    text: " X (Twitter)"
  },{
    icon: discordIcon,
    link: "https://discord.com/invite/3Z5MZrdA89",
    text: " Discord"
  },{
    icon: linkedinIcon,
    link: "https://www.linkedin.com/company/daybydayio",
    text: " LinkedIn"
  },{
    icon: telegramIcon,
    link: "https://t.me/DBDTokens",
    text: " Telegram"
  },{
    icon: facebookIcon,
    link: "https://www.facebook.com/daybydayio",
    text: " Facebook"
  },
];

function Footer() {
  const { ethProviderState } = useContext(EthProviderContext);
  const { authState } = useContext(AuthContext);
  const { algoProviderState } = useContext(AlgoProviderContext);

  const addTokentoMetaMask = async (address, symbol, imageURL) => {
    let provider;
    if (ethProviderState.walletConnectProvider?.accounts?.length > 0) {
      provider = ethProviderState.walletConnectProvider;
    } else {
      provider = ethProviderState.provider;
    }
    if (provider == null) {
      return toast.error("Please connect your polygon wallet");
    }
    await addERC20Token(ethProviderState.provider, address, symbol, imageURL);
  };

  const approveToken = async () => {
    try {
      if (authState?.accountAlgo?.networkAccount?.addr) {
        await authState.accountAlgo.tokenAccept(ctcparse(stakingToken));
        let acceptedTokenYet = await authState.accountAlgo.tokenAccepted(
          ctcparse(stakingToken)
        );
        if (acceptedTokenYet) {
          return toast.success("Successfully opted-in to the token");
        }
      } else {
        if (!algoProviderState.provider?.isConnected) {
          return toast.error("Please connect your algorand wallet");
        }
        let tokenAlreadyAccepted = await tokenAccepted(
          authState.accountAlgo,
          Number(process.env.REACT_APP_DBDV2)
        );
        if (tokenAlreadyAccepted) {
          return toast.info("Already opted-in to the token");
        }
        var res = await acceptToken(
          authState.accountAlgo,
          Number(process.env.REACT_APP_DBDV2),
          algoProviderState.provider,
          PERA_WALLET
        );
        if (res) {
          return toast.success("Successfully opted-in to the token");
        }
        return toast.error("User Cancelled Transaction");
      }
    } catch (err) {
      console.error("err", err);
      if (
        err.toString().includes("cancelled") ||
        err.toString().includes("sign and send")
      ) {
        toast.error("User Cancelled Transaction");
      } else {
        toast.error("You don't have enough algo to accept this token");
      }
    }
  };

  const addPolygonNet = async () => {
    try {
      let provider;
      if (ethProviderState.walletConnectProvider?.accounts?.length > 0) {
        provider = ethProviderState.walletConnectProvider;
      } else {
        provider = ethProviderState.provider;
      }
      if (provider == null) {
        return toast.error("Please connect your polygon wallet");
      }
      const chainId = await provider.request({
        method: "eth_chainId",
      });
      if (chainId === process.env.REACT_APP_CHAINID) {
        toast.info("You have already added the polygon network");
        return;
      }
      await provider.request({
        method: "wallet_addEthereumChain",
        params: chainParams,
      });
    } catch (error) {
      toast.error("Failed to switch the chain");
    }
  };

  return (
    <footer className="footer pt-4">
      <div className="container pt-4">
        <div className="row justify-content-center footer-row-1 py-4">
          <div className="col-xl-2 col-sm-5 col-6 pb-xl-0 pb-xl-4 pb-5 text-xl-left ">
            <h6>Products</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  href="https://docs.daybyday.io/day-by-day-overview/live-platform/the-day-by-day-asset-register-app"
                  rel="noopener noreferrer"
                  
                >
                  Asset Register App
                </a>
              </li>
              {/* <li>
                <a href='https://app.daybyday.io/' rel='noopener noreferrer' target='_blank'>
                  Mainnet
                </a>
              </li> */}
              <li>
                <a
                  href="https://stake.daybyday.io/"
                  rel="noopener noreferrer"
                >
                  staking
                </a>
              </li>
              <li>
                <a
                  href="https://token.daybyday.io/"
                  rel="noopener noreferrer"
                >
                  Token Dashboard
                </a>
              </li>
              <li>
                <a
                  href="https://app.daybyday.io/marketplace"
                  rel="noopener noreferrer"
                >
                  Protection Contract NFTs
                </a>
              </li>
              <li>
                <a
                  href="https://app.daybyday.io/underwrite"
                  rel="noopener noreferrer"
                >
                  Underwriting DeFi Pool
                </a>
              </li>
            </ul>
          </div>

          <div className="col-xl-2 col-sm-5 col-6 pb-xl-0 pb-xl-4 pb-5 text-xl-left ">
            <h6>Support</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  href="https://daybyday.gitbook.io/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Docs
                </a>
              </li>
              <li>
                <a
                  href="https://docs.daybyday.io/day-by-day-overview/support/faqs"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  FAQs
                </a>
              </li>
              {/* <li><a href="https://daybyday.io/contact-us/" rel="noopener noreferrer" target="_blank">Contact Support</a></li> */}
            </ul>
          </div>

          <div className="col-xl-2 col-sm-5 col-6 pb-xl-0 pb-xl-4 pb-5 text-xl-left ">
            <h6>Legel</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  href="https://app.daybyday.io/terms-conditions"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  href="https://app.daybyday.io/privacy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
              {/* <li><a href="https://daybyday.io/contact-us/" rel="noopener noreferrer" target="_blank">Contact Support</a></li> */}
            </ul>
          </div>

          <div className="col-xl-2 col-sm-5 col-9 pb-xl-0 pb-xl-4 pb-5 text-xl-left ">
            <h6>Blockchain network</h6>
            <ul className="addToMetamask">
              <li>
                <button className="btn btn-link" onClick={addPolygonNet}>
                  <img src={optPolygon} alt="Polygon"  height="20" width="21" />
                  Polygon Network 
                </button>
              </li>
              <li>
                <button
                  className="btn btn-link"
                  onClick={() =>
                    addTokentoMetaMask(
                      process.env.REACT_APP_TOKENADDRESS,
                      process.env.REACT_APP_TOKENSYMBOL,
                      "https://i.imgur.com/zMvb3Wd.png"
                    )
                  }
                >
                  <img src={optPolygon} alt="Polygon"  height="20" width="21" />
                  Add DBD Token 
                </button>
              </li>
              <li>
                <button className="btn btn-link" onClick={approveToken}>
                  <img src={optDBD} alt="DBD"  height="20" width="21" />
                  Opt-in to DBD 
                </button>
              </li>
            </ul>
          </div>
          <div className="col-xl-2 col-sm-5 col-9 pb-xl-0 pb-xl-4 pb-5 text-xl-left ">
            <h6>Community</h6>
            <ul className="social-media-links">
              {socialMediaInfo.map((info, index) => (
                <li key={index}>
                  <a
                    href={info.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    <img src={info.icon} alt={info.text} ></img>
                    {info.text}
                    {/* <FontAwesomeIcon icon={info.icon} size="1x" /> */}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row footer-row-2 py-xl-5 py-4 mt-md-4 mt-2">
          <div className="col-12 d-flex justify-content-center flex-wrap">
            <p>Day By Day © 2023</p>
            <p>
              <a
                href="https://daybyday.io/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </p>
            <p>
              <a
                href="https://daybyday.io/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
