import React, { useReducer, createContext } from "react";

const initStakingDataState = {
  owner: null,
  isPaused: false,
  rewardPoolBalance: 0,
  currentPoolBalanc: 0,
  maxPoolBalance: 0,
  maxPerStakeAmount: 0,
  earlyUnstakeApy: 0,
  extraPeriodApy: 0,
  durationTerms: [],
};

export const StakingDataContext = createContext(initStakingDataState);

const stakingDataReducer = (state, action) => {
  switch (action.type) {
    case "GET":
      return { ...action.payload };
    case "UPDATE":
      return { ...state, ...action.payload };
    case "RESET":
      return initStakingDataState;
    default:
  }
};

export function StakingDataProvider({ children }) {
  const [stakingDataState, dispatch] = useReducer(
    stakingDataReducer,
    initStakingDataState
  );

  const getData = (value) => {
    dispatch({ type: "GET", payload: value });
  };

  const update = (value) => {
    dispatch({ type: "UPDATE", payload: value });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  const value = {
    stakingDataState,
    getData,
    update,
    reset,
  };

  return (
    <StakingDataContext.Provider value={value}>
      {children}
    </StakingDataContext.Provider>
  );
}
