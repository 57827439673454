import algosdk, { getApplicationAddress } from "algosdk";

const baseServer = process.env.REACT_APP_PURESTAKE_ADDRESS;
const port = "";
const token = "";
const algodClient = new algosdk.Algodv2(token, baseServer, port);

export async function PickExpectedStakeToken(tokens, appId = null) {
  // If DBD is one of the tokens, return that
  const theChosenOne = tokens.find(
    (asset) => Number(asset.index) === Number(process.env.REACT_APP_DBDV2)
  );
  if (theChosenOne) return theChosenOne.index;

  // Get all tokens the staking pool has opted into
  if (appId) {
    let appAddress = getApplicationAddress(appId);
    const appAccountInfo = await algodClient
      .accountInformation(appAddress)
      .do();
    if (appAccountInfo["assets"].length > 0) {
      let orderedList = appAccountInfo["assets"].sort(
        (a, b) => a.amount - b.amount
      );

      orderedList = orderedList.filter(el => tokens.findIndex(asset => asset.index === el["asset-id"]) >= 0);
      
      if (orderedList.length === 0 ) return null;
      return orderedList[0]["asset-id"];
    }
  }

  return tokens[0].index; // Fall back to always pick first one
}

export async function GetStakedToken(appId) {}
