export function pretty(r) {
  if (!r) {
    return r;
  } else if (typeof r === "string") {
    return r;
  } else if (r._isBigNumber) {
    return r.toString();
  } else if (r.networkAccount) {
    if (r.networkAccount.addr) {
      return r.networkAccount.addr.slice(0, 8);
    } else if (r.networkAccount.address) {
      return r.networkAccount.address.slice(0, 8);
    } else {
      return "<some acc>";
    }
  } else if (Array.isArray(r) && r[0] === "Some") {
    return pretty(r[1]);
  } else if (Array.isArray(r)) {
    return r.map((x) => pretty(x));
  } else if (Object.keys(r).length > 0) {
    const o = {};
    for (const k in r) {
      o[k] = pretty(r[k]);
    }
    return o;
  } else if (r.toString) {
    return r.toString();
  } else {
    return r;
  }
}

export async function refreshInfo(account, ctc, reachObject) {
  const runView = async (vname, ...args) => {
    try {
      const res = await ctc.views[vname](...args);
      if (res[0] !== "Some") {
        // console.warn(vname, res);
        return;
      }
      return pretty(res);
    } catch (err) {
      throw err;
    }
  };
  const runViews = async (vs) => {
    const data = {};
    const promises = [];
    for (const [vname, ...args] of vs) {
      const p = (async () => {
        const res = await runView(vname, ...args);
        data[vname] = res;
      })();
      promises.push(p);
      // For some reason we *do* need to perform these queries serially,
      // or else they all come back None. =[
      await p;
    }
    await Promise.all(promises);
    return data;
  };
  const now = pretty(await reachObject.getNetworkTime());
  const data = {
    ...(await runViews([
      ["opts"],
      ["totalStaked"],
      ["remainingRewards"],
      ["end"],
      ["staked", account],
      ["rewardsAvailableAt", account, now],
      ["minStaking"],
      ["maxStakePerStake"],
      ["maxStakingPool"],
      ["userEndDuration", account],
      ["userExpectedRewards", account],
      ["stakingPaused"],
      ["durationThreshold"],
      ["durationApy"],
      ["durationMultiplier"],
      ["owner"],
      ["userStakeEnd", account],
    ])),
    now,
  };
  return data;
}

export const ctcparse = (s) => {
  try {
    return JSON.parse(s);
  } catch (e) {
    return s;
  }
};
